import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  IconButton,
} from "@material-ui/core";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ToothButtons from "./ToothButtons";
import "./onexam.css"; // Assuming you create a CSS file for styling Onexam component
import {
  addDoc,
  collection,
  doc,
  getDoc,
  onSnapshot,
  query,
  serverTimestamp,
  updateDoc,
  documentId,
  where,
} from "firebase/firestore";
import { db } from "../../../../../firebaseConfig/firebase";
import {
  DeleteForeverOutlined,
  DeleteForeverRounded,
  DeleteOutline,
  DeleteRounded,
  Description,
} from "@mui/icons-material";

function Onexam({ onNext, data, id, props, setTid, tId }) {
  const [openDialog, setOpenDialog] = useState(false);
  const [docId, setDocId] = useState(null);
  const [examData, setOnExamData] = useState([]);
  const [selectedAction, setSelectedAction] = useState("");
  const [selectedPoint, setSelectedPoint] = useState("");
  const [textOnExam, setTextOnExam] = useState([]);
  const [selectedToothValue, setSelectedToothValue] = useState([]); // New state for selected tooth value
  const [saveAsGroup, setSaveAsGroup] = useState(false);
  const handleOpenDialog = (action) => {
    setSelectedAction(action);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleToothButtonClick = (selectedTeeth) => {
    setSelectedToothValue(selectedTeeth); // Update selected tooth value
  };

  const addBtn = async () => {
    if (tId.length === 0) {


      const onExamArray = saveAsGroup ?   [
        { toothNumber: selectedToothValue, onExamination: textOnExam },
      ]: selectedToothValue.map((selectedToothValues) => ({
        toothNumber: selectedToothValues,
        onExamination: textOnExam,
      })) 

    

      const treatmentData = {
        patientId: id,
        chiefComplaint: data,

        description: onExamArray,
        medicine:[],
        created: serverTimestamp(),
      };

      try {
        const docRef = await addDoc(
          collection(db, "treatment"),
          treatmentData
        );
        console.log("Document written with ID: ", docRef.id);
        setDocId(docRef.id);
        setTid(docRef.id);
        setTextOnExam("");
        setSelectedToothValue("");
      } catch (error) {
        console.error("Error adding document: ", error);
      }

      console.log("==============callback pressed======================");
    } 
    else {
      const pushOnExam = doc(db, "treatment", tId);

      const onExamArray = saveAsGroup ?   [
        { toothNumber: selectedToothValue, onExamination: textOnExam },
      ]: selectedToothValue.map((selectedToothValues) => ({
        toothNumber: selectedToothValues,
        onExamination: textOnExam,
      })) 


      try {
        const docSnapshot = await getDoc(pushOnExam);
        if (docSnapshot.exists()) {
          const existingData = docSnapshot.data();
          const existingArray = existingData.description || [];
          const updatedArray = [...existingArray, ...onExamArray];

          await updateDoc(pushOnExam, {
            description: updatedArray,
          });

          console.log("New array data pushed successfully!");

          setTextOnExam("");
          setSelectedToothValue("");
        } else {
          console.log("Document does not exist.");
        }
      } catch (error) {
        console.error("Error pushing new array data: ", error);
      }

      console.log("---Else pressed---");
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const pushOnExam = doc(db, "treatment", tId);
        const unsubscribe = onSnapshot(pushOnExam, (doc) => {
          if (doc.exists()) {
            const data = doc.data();
            console.log("Document data:", data);
            setOnExamData([data]);
          } else {
            console.log("No such document!");
          }
        });
        return unsubscribe;
      } catch (error) {
        console.error("Error fetching document:", error);
      }
    };
    fetchData();
  }, [tId]);

  const handleDelete = async (indexToDelete) => {
    try {
      const pushOnExam = doc(db, "treatment", tId);
      const docSnapshot = await getDoc(pushOnExam);
      if (docSnapshot.exists()) {
        const existingData = docSnapshot.data();
        const existingArray = existingData.description || [];
        const updatedArray = existingArray.filter(
          (_, index) => index !== indexToDelete
        );

        await updateDoc(pushOnExam, {
          description: updatedArray,
        });

        console.log("Item deleted successfully!");
      } else {
        console.log("Document does not exist.");
      }
    } catch (error) {
      console.error("Error deleting item: ", error);
    }
  };

  return (
    <div className="onexam-container">
      <div style={{ margin: "5px", fontSize: "16px", fontWeight: "bold" }}>
        On Examination
        <div style={{ fontWeight: "lighter", color: "grey", fontSize: "14px" }}>
          {data}
        </div>
      </div>

      <div
        style={{
          backgroundColor: "#F1F3F4",
          padding: "10px",
          borderRadius: "10px",
        }}
      >
        <div
          style={{
            border: "1px solid #D4DBD3",
            width: "100%",
            backgroundColor: "white",
            borderRadius: "5px",
          }}
        >
          <Button
            style={{ margin: "2px" }}
            variant="contained"
            color="primary"
            onClick={() => handleOpenDialog("select Tooth")}
            className="action-button"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="2em"
              height="2em"
              viewBox="0 0 24 24"
              {...props}
            >
              <path
                fill="none"
                stroke="white"
                stroke-width="2"
                d="M7 2C4 2 2 5 2 8c0 2.11 1 5 2 6s2 8 4 8c4.54 0 2-7 4-7s-.54 7 4 7c2 0 3-7 4-8s2-3.89 2-6c0-3-2-6-5-6s-3 1-5 1s-2-1-5-1"
              ></path>
            </svg>
          </Button>
          {selectedToothValue.length === 0
            ? selectedToothValue
            : selectedToothValue.join(",")}
        </div>
        <TextField
          style={{ marginTop: "10px", backgroundColor: "white" }}
          id="outlined-basic"
          label="O/E"
          variant="outlined"
          fullWidth
          value={textOnExam}
          onChange={(e) => setTextOnExam(e.target.value)}
        />
        <div>

        <input
          type="checkbox"
          checked={saveAsGroup}
          onChange={() => setSaveAsGroup(!saveAsGroup)}
        />
        <label>Save as Group</label>
      </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <Button
            style={{ width: "30%" }}
            variant="contained"
            color="primary"
            className="action-button"
            onClick={addBtn}
          >
            Add
          </Button>
        </div>
      </div>
      {examData.length === 0 ? (
        <div style={{ fontSize: "12px",margin:'25px' }}>No data Available</div>
      ) : (
        <div
          style={{
            marginTop: "25px",
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            padding: "5px",
            borderRadius: "10px",
            height: "350px",
            overflowY: "scroll",
          }}
        >
          {" "}
          <div style={{ fontWeight: "bold", margin: "10px", color: "#566266" }}>
            Added
          </div>
          {examData.map((item, index) => (
            <div key={index} style={{ width: "100%" }}>
              <div>
                {item.description.map((exam, idx) => (
                  <div
                    key={idx}
                    style={{
                      display: "flex",
                      borderBottom: "1px solid #D8D8D8",
                      padding: "5px",
                      marginTop: "10px",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "14px",
                        backgroundColor: "#F1F1F2",
                        padding: "5px",
                        borderRadius: "3px",
                      }}
                    >

{Array.isArray(exam.toothNumber) ? exam.toothNumber.join(",") : exam.toothNumber}
                      
                    </div>
                    <div
                      style={{
                        marginLeft: "20px",
                        width: "100%",
                        fontSize: "14px",
                      }}
                    >
                      {" "}
                      {exam.onExamination}
                    </div>{" "}
                    {/* Adjust marginLeft as needed */}
                    <div onClick={() => handleDelete(idx)}>
                      <DeleteOutline color="secondary" />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Select tooth</DialogTitle>
        <DialogContent>
          <ToothButtons handleToothButtonClick={handleToothButtonClick} />
        </DialogContent>
        <Button onClick={handleCloseDialog}>Save</Button>
      </Dialog>
    </div>
  );
}

export default Onexam;
