import React, { useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import Stack from "@mui/material/Stack";

import {
  addDoc,
  collection,
  query,
  onSnapshot,
  doc,
  serverTimestamp,
  updateDoc,
  where,
  documentId,
} from "firebase/firestore";
import { db } from "../../firebaseConfig/firebase";
import { Navigate } from "react-router-dom";
import { DateTimePicker } from "@mui/x-date-pickers";

function AddNewEvent(props) {
  const [open, setOpen] = useState(false);

  const [eventName, setEventName] = useState("");
  const [daten, setDate] = useState("");
  const [eventDetails, setEventDetails] = useState("");
  const [time, setTime] = useState("");
  const [treatmentName, setTreatmentName] = useState();
  const [paid, setPaid] = useState();
  const [rctPercentage, setRctPercentage] = useState();

  const saveButtonRef = useRef(null);

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      saveButtonRef.current.click();
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason && reason == "backdropClick") return;

    setEventName();

    setEventDetails();
    setPaid();
    setDate();
    setTime();
    setOpen(false);
  };
  const [treatments, setTreatments] = useState([]);
  useEffect(() => {
    const treatmentData = collection(db, "treatment");

    const tD = query(treatmentData, where(documentId(), "==", props.tId));

    const patientData = onSnapshot(tD, (snap) => {
      let newArray = [];

      snap.forEach((doc) => {
        newArray.push({
          ...doc.data(),

          tretmentId: doc.id,

          treatmentPaid: doc.get("paid"),

          created: doc.get("created"),
        });
      });

      console.log(newArray);

      setTreatments(newArray);
    });

    return () => {
      patientData();
    };
  }, [open]);

  const addNewEvent = async () => {
    const eventData = {
      eventName: eventName,
      eventDetails: eventDetails,

      treatmentId: props.tId,
      ptId: props.ptId,
      ptName: props.ptName,
      ptPhNumber: props.ptPhNumber,
      tName: props.tName,

      created: serverTimestamp(),
    };
    const treatmentPage = doc(db, "treatment", props.tId);

        eventName == "Access Opening"
      ? setRctPercentage(10)
      : eventName == "BMP"
      ? setRctPercentage(20)
      : eventName == "Irrigation"
      ? setRctPercentage(40)
      : eventName == "Obturation"
      ? setRctPercentage(60)
      : eventName == "Post Ob"
      ? setRctPercentage(70)
      : eventName == "Capping"
      ? setRctPercentage(100): setRctPercentage (0)
      

      
      await updateDoc(treatmentPage, {
        rctCompletion: rctPercentage ,
      });
      await addDoc(collection(db, "eventsDetails"), eventData);

    setEventName();

    setEventDetails();
    setPaid();
    setDate();
    setTime();

    handleClose();
  };

  const handleChange = (event) => {
    // setTreatmentName(event.target.value);
    setEventName(event.target.value);
  };

  return (
    <div>
      <Button
        onClick={handleClickOpen}
        style={{
          lineHeight: "30px",
          width: "26px",
          fontSize: "36px",
          fontWeight: "bolder",
          borderRadius: "50%",
          border: "6px solid  rgb(5, 2, 43)",
          textAlign: " center",
          padding: "8px",
          backgroundColor: "rgb(5, 2, 43)",
          color: "white",
        }}
      >
        +
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            borderRadius: "15px",
          },
        }}
      >
        <DialogTitle>Start a Procedure</DialogTitle>
        <DialogContent>
          {props.tName == "RCT" ? (
            <select
              value={eventName}
              label="Treatment"
              onChange={handleChange}
              style={{
                height: "40px",
                color: "rgb(5, 2, 43)",
                fontSize: "18px",
                border: "0px",
                marginTop: "15px",
                padding: "10px",
              }}
              onKeyDown={handleKeyDown}
            >
              <option value="start">Select treatment</option>

              <option value="Access Opening">Access Opening</option>
              <option value="BMP">BMP</option>
              <option value="Irrigation">Irrigation</option>
              <option value="Obturation">Obturation</option>
              <option value={"Post Ob"}>Post Ob </option>
              <option value={"Capping"}>Capping</option>
            </select>
          ) : (
            <TextField
              autoFocus
              margin="dense"
              id="eventname"
              label="Procedure name"
              fullWidth
              variant="standard"
              onChange={(event) => {
                const { value } = event.target;
                setEventName(value);
              }}
              required
              onKeyDown={handleKeyDown}
            />
          )}
          <TextField
            margin="dense"
            id="Procedure In details"
            label="Procedure In details"
            fullWidth
            variant="standard"
            onChange={(event) => {
              const { value } = event.target;
              setEventDetails(value);
            }}
            required
            onKeyDown={handleKeyDown}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            style={{
              color: "red",

              padding: "2px",
            }}
          >
            Cancel
          </Button>
          <Button
            ref={saveButtonRef}
            onClick={addNewEvent}
            style={{
              fontSize: "16px",
              // fontWeight: "bold",
              // border: "1px solid ",
              padding: "3px",
              borderRadius: "5px",
              backgroundColor: "rgb(8,10,48,0.9)",
              color: "white",
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AddNewEvent;
