import React, { useState } from "react";
import "./toothButtons.css";

const ToothButtons = ({ handleToothButtonClick }) => {
  const [selectedTeeth, setSelectedTeeth] = useState([]);

  const handleClick = (value) => {
    let updatedSelectedTeeth = [...selectedTeeth]; // Create a copy of the selected teeth array

    // Check if the tooth is already selected
    const index = updatedSelectedTeeth.indexOf(value);
    if (index !== -1) {
      // If the tooth is already selected, remove it
      updatedSelectedTeeth.splice(index, 1);
    } else {
      // If the tooth is not selected, add it
      updatedSelectedTeeth.push(value);
    }

    setSelectedTeeth(updatedSelectedTeeth);

    // Pass the updated selected teeth data to the parent component
    handleToothButtonClick(updatedSelectedTeeth);
  };

  return (
    <div className="tooth-chart-container">
      <div className="tooth-chart">
          <div className="tooth-section upper-right">
            {["UR","UL", "LR","LL", "Upper", "Lower", "Full Jaw"].map((value, index) => (
              <button
                key={index}
                className={selectedTeeth.includes(value) ? "tooth-btn selected" : "tooth-btn"}
                onClick={() => handleClick(value)}
              >
                {value}
              </button>
            ))}
          </div>
        <div className="tooth-row">
          <div className="tooth-section upper-right">
            {[11, 12, 13, 14, 15, 16, 17, 18,"UR",51,52,53,54,55].map((value, index) => (
              <button
                key={index}
                className={selectedTeeth.includes(value) ? "tooth-btn selected" : "tooth-btn"}
                onClick={() => handleClick(value)}
              >
                {value}
              </button>
            ))}
          </div>
          <div className="tooth-section upper-left">
            {[21, 22, 23, 24, 25, 26, 27, 28,"UL",61,62,63,64,65].map((value, index) => (
              <button
                key={index}
                className={selectedTeeth.includes(value) ? "tooth-btn selected" : "tooth-btn"}
                onClick={() => handleClick(value)}
              >
                {value}
              </button>
            ))}
          </div>
        </div>
        <div className="tooth-row">
          <div className="tooth-section lower-right">
            {[41, 42, 43, 44, 45, 46, 47, 48,"LR",81,82,83,84,85].map((value, index) => (
              <button
                key={index}
                className={selectedTeeth.includes(value) ? "tooth-btn selected" : "tooth-btn"}
                onClick={() => handleClick(value)}
              >
                {value}
              </button>
            ))}
          </div>
          <div className="tooth-section lower-left">
            {[31, 32, 33, 34, 35, 36, 37, 38,"LL",71,72,73,74,75].map((value, index) => (
              <button
                key={index}
                className={selectedTeeth.includes(value) ? "tooth-btn selected" : "tooth-btn"}
                onClick={() => handleClick(value)}
              >
                {value}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ToothButtons;
