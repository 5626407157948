import { doc, getDoc, onSnapshot, updateDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../../../../firebaseConfig/firebase";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Snackbar,
  TextField,
} from "@material-ui/core";
import {
  CircleNotificationsOutlined,
  CurrencyRupee,
  CurrencyRupeeOutlined,
  Edit,
  PriceChange,
} from "@mui/icons-material";

function Treatmentadvice({ tId }) {
  const [examData, setOnExamData] = useState([]);
  const [procedureName, setProcedureName] = useState("");
  const [procedureDetails, setProcedureDetails] = useState("");
  const [price, setPrice] = useState("");
  
  const [workname, setWorkName] = useState("");
  const [workDetails, setWorkDetails] = useState("");
  const [status, setStatus] = useState("");
  const [toothselectedindex, setToothselectedindex] = useState("");
  const [toothNumber, setToothNumber] = useState("");
  const [showWarning, setShowWarning] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const pushOnExam = doc(db, "treatment", tId);
        const unsubscribe = onSnapshot(pushOnExam, (doc) => {
          if (doc.exists()) {
            const data = doc.data();
            console.log("Document data:", data);
            setOnExamData([data]);
          } else {
            console.log("No such document!");
          }
        });
        return unsubscribe;
      } catch (error) {
        console.error("Error fetching document:", error);
      }
    };
    fetchData();
  }, [tId]);

  const handlestatusChange = (event) => {
    setStatus(event.target.value);
  };

  const btnSelected = (index, toothNumber) => {
    setToothselectedindex(index);
    setToothNumber(toothNumber);
  };

  const treatmentAdviceBtn = async (indextoUpdate) => {
    try {
      // Check if status is not empty
      if (!status) {
        setShowWarning(true);
        console.error("Status is required");
        return;
      }

      const documentRef = doc(db, "treatment", tId);
      const docSnapshot = await getDoc(documentRef);
      if (docSnapshot.exists()) {
        // Step 2: Modify the array to add the new field at the specified index
        const descriptionArray = docSnapshot.data().description || []; // Fetch the existing description array or initialize empty array
        const index = indextoUpdate; // Index to update
        const now = new Date();
        const date = now.toISOString().split('T')[0]; // Get the date in YYYY-MM-DD format
        const time = now.toTimeString().split(' ')[0]; // Get the time in HH:MM:SS format
  
        // Initialize the new work item with time and date
        let newWorkItem = {
          workName: workname,
          workDetails: workDetails,
          date:date,
          time:time
        };
  
        // Check if workName is not empty and add date and time fields
      


        if (index >= 0 && index < descriptionArray.length) {
          // Check if the object at the specified index exists
          if (!descriptionArray[index].hasOwnProperty("treatmentAdvice")) {
            // If treatmentAdvice field doesn't exist, add it
            descriptionArray[index].treatmentAdvice = procedureName;
            descriptionArray[index].treatmentDetails = procedureDetails;
            descriptionArray[index].price = price;
            descriptionArray[index].workArray = [newWorkItem]; 
         
            descriptionArray[index].status = status;
          } else {
            // If treatmentAdvice field exists, update it
            descriptionArray[index].treatmentAdvice = procedureName;
            descriptionArray[index].treatmentDetails = procedureDetails;
            descriptionArray[index].price = price;
            descriptionArray[index].workArray = [newWorkItem]; 
            descriptionArray[index].status = status;
          }
        } else {
          throw new Error("Index out of bounds");
        }

        // Step 3: Update the document with the modified array
        await updateDoc(documentRef, { description: descriptionArray });

        console.log("Document successfully updated!");
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error updating document: ", error);
    }

    // Clear the form fields only if the data was successfully saved
    setProcedureName("");
    setProcedureDetails("");
    setPrice("");
    setStatus("");
    setToothselectedindex("");
    setToothNumber("");
  };
  const handleWarningClose = () => {
    setShowWarning(false);
  };

  let textColor;

  // Determine text color based on task status
  if (status === "On going") {
    textColor = "blue";
  } else if (status === "Completed") {
    textColor = "green";
  } else {
    textColor = "black"; // Default color
  }

  return (
    <div>
      <Snackbar
        open={showWarning}
        autoHideDuration={6000}
        onClose={handleWarningClose}
        message="Please select a status before saving!"
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      />
      <div style={{ margin: "5px", fontSize: "16px", fontWeight: "bold" }}>
        Treatment Advice
      </div>
      <div>
        <div
          style={{
            marginTop: "10px",
            marginLeft: "10px",
            color: "grey",
            fontWeight: "bold",
            fontSize:'12px'
          }}


      
        >
          Choose teeth
        </div>
        <div>
          {" "}
          {examData.map((item, index) => (
            <div key={index} style={{ width: "90%" }}>
              <div
                style={{ display: "flex", width: "100%", overflowX: "scroll" }}
              >
                {item.description.map((exam, idx) => (
                  <div
                    key={idx}
                    style={{
                      display: "flex",
                      borderBottom: "1px solid #D8D8D8",
                      padding: "5px",
                      marginTop: "10px",
                      justifyContent: "space-between",

                    }}
                    onClick={() => {
                      btnSelected(idx, exam.toothNumber);
                    }}
                  >
                    <div
                      style={{
                        fontSize: "14px",
                        backgroundColor: "#F1F1F2",
                        padding: "5px",
                        borderRadius: "3px",
                      }}
                    >
                     {Array.isArray(exam.toothNumber) ? exam.toothNumber.join(",") : exam.toothNumber}
                    </div>
                    <div
                      style={{
                        marginLeft: "8px",
                        width: "100%",
                        fontSize: "14px",
                      }}
                    >
                      {" "}
                      {exam.onExamination}
                    </div>{" "}
                    {/* Adjust marginLeft as needed */}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div
        style={{
          backgroundColor: "#F1F3F4",
          padding: "10px",
          borderRadius: "10px",
          marginTop: "10px",
        }}
      >
        <div
          style={{
            border: "1px solid #D4DBD3",
            width: "95%",
            backgroundColor: "white",
            padding: "5px",
            marginTop: "5px",
            borderRadius: "5px",
          }}
        >
          {Array.isArray(toothNumber) ? toothNumber.join(",") :toothNumber}
        </div>
        <TextField
          style={{ marginTop: "10px", backgroundColor: "white", width: "99%" }}
          id="outlined-basic"
          label="Procedure name"
          variant="outlined"
          value={procedureName}
          onChange={(e) => setProcedureName(e.target.value)}
        />
        <TextField
          style={{ marginTop: "10px", backgroundColor: "white", width: "99%" }}
          id="outlined-basic"
          label="Procedure In Details"
          variant="outlined"
          value={procedureDetails}
          onChange={(e) => setProcedureDetails(e.target.value)}
        />

        <TextField
          style={{
            width: "99%",
            marginTop: "10px",
            backgroundColor: "white",
            fontSize: "12px",
            // height:'30px'
          }}
          id="outlined-basic"
          label=" Add Price"
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
          variant="outlined"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
        />

        <div>
          <FormControl required>
            <FormLabel
              id="demo-controlled-radio-buttons-group"
              style={{ marginBottom: "10px", marginTop: "10px" }}
            >
              Status
            </FormLabel>
            <RadioGroup 
              style={{ marginLeft: "10px", }}
              row
              name="row-radio-buttons-group"
              aria-labelledby="demo-controlled-radio-buttons-group"
              value={status}
              onChange={handlestatusChange}
            >
              <FormControlLabel style={{fontSize:'12px'}}
                value="On going"
                control={<Radio />}
                label="On going"
              />
              <FormControlLabel style={{fontSize:'12px'}}
                value="Proposed"
                control={<Radio />}
                label="Proposed"
              />
              <FormControlLabel style={{fontSize:'12px'}}
                value="Completed"
                control={<Radio />}
                label="Completed"
              />
            </RadioGroup>
          </FormControl>
        </div>

        <TextField
            autoFocus
            margin="dense"
            id="Work"
            label="Work"
            fullWidth
            variant="standard"
            onChange={(event) => {
              const { value } = event.target;
              setWorkName(value);
            }}
          
            
          />
          <TextField
            margin="dense"
            id="WorkD"
            label="Work in details"
            fullWidth
            variant="standard"
            onChange={(event) => {
              const { value } = event.target;
              setWorkDetails(value);
            }}
           
           
          />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          <Button
            style={{ width: "30%" }}
            variant="contained"
            color="primary"
            className="action-button"
            onClick={() => treatmentAdviceBtn(toothselectedindex)}
          >
            Add
          </Button>
        </div>
      </div>

      <div>
        <div>
          {examData.map((item, index) => (
            <div key={index} style={{ width: "99%" }}>
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "12px",
                  color: "grey",
                  margin: "15px",
                }}
              >
                Added
              </div>{" "}
              <div style={{ height:'25vh', overflowY:'scroll'}}>
                {item.description.map((exam, idx) =>
                  exam.price ? (
                    <div
                      style={{
                        marginTop: "5px",
                        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                        padding: "5px",
                        borderRadius: "10px",
                      }}
                    >
                      <div
                        key={idx}
                        style={{
                          display: "flex",
                          borderBottom: "1px solid #D8D8D8",
                          padding: "5px",
                          marginTop: "10px",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "12px",
                            fontWeight:'bold',
                            backgroundColor: "#F1F1F2",
                            padding: "5px",
                            borderRadius: "3px",
                          }}
                        >
                          {exam.status == "On going" ? (
                            <div style={{ color: "blue" }}>On Going</div>
                          ) : exam.status == "Proposed" ? (
                            <div style={{ color: "orange" }}> Proposed </div>
                          ) : (
                            <div style={{ color: "green" }}>Completed </div>
                          )}
                        </div>{" "}
                        <div
                          onClick={() => {
                            btnSelected(idx, exam.toothNumber);
                          }}
                        >
                          <Edit />{" "}
                        </div>
                      </div>

                      <div>
                        <div
                          key={idx}
                          style={{
                            display: "flex",
                            // borderBottom: "1px solid #D8D8D8",
                            padding: "5px",
                            marginTop: "10px",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              fontSize: "14px",
                              backgroundColor: "#F1F1F2",
                              padding: "5px",
                              borderRadius: "3px",
                            }}
                          >
                            {Array.isArray(exam.toothNumber) ? exam.toothNumber.join(",") : exam.toothNumber}
                          </div>
                          <div
                            style={{
                              marginLeft: "20px",
                              width: "100%",
                              fontSize: "14px",
                            }}
                          >
                            <div
                              style={{ fontWeight: "bold", fontSize: "12px" }}
                            >
                              {exam.treatmentAdvice}
                            </div>
                            <div style={{ fontSize: "12px", color: "grey" }}>
                            
                              {exam.treatmentDetails}
                            </div>
                            <div style={{ fontSize: "14px", color: "grey" }}>
                            ₹
                              {exam.price}
                            </div>
                          </div>
                        </div>{" "}
                      </div>
                    </div>
                  ) : (
                    <div> </div>
                  )
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Treatmentadvice;
