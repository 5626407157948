import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { FormControl, TextField } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { Button, makeStyles } from "@material-ui/core";
import { Box } from "@mui/system";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";

import Select from "@mui/material/Select";
import { Padding } from "@mui/icons-material";
import {
  addDoc,
  collection,
  doc,
  onSnapshot,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../../../firebaseConfig/firebase";
import axios from "axios";

const token = process.env.REACT_APP_API_KEYTOKEN;
const mytoken = process.env.REACT_APP_API_KEYMYTOKEN;
const phidtoken = process.env.REACT_APP_API_KEYphidtoken;

function Reschedule() {
  let navigate = useNavigate();
  const state = useLocation();
  const useStyles = makeStyles({
    field: {
      margin: 20,
      marginBottom: 20,

      width: "90%",
    },
  });

  const classes = useStyles();
  const [showAlert, setShowAlert] = useState(false);
  const [name, setName] = useState('');
  const [name2, setName2] = useState(true);
  const [phNumber, setphNumber] = useState(state.state.phoneNumber);
  const [time, setTime] = useState("");
  const [duration, setDuration] = useState(state.state.duration);
  const [reason, setReason] = useState(state.state.reason);
  const [ptId, setptId] = useState();
  const [docid, setdocid] = useState();
  const [date, setDate] = useState(new Date(Date.now()));
  let datew = JSON.stringify(date);
  datew = datew.slice(1, 11);

  let originalDate = datew;
  let splitDate = originalDate.split("-");
  let convertedDate = splitDate[2] + "-" + splitDate[1] + "-" + splitDate[0];

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    
    const militaryTime = time
    const hours = Math.floor(militaryTime / 100);
    const minutes = militaryTime % 100;
    const timeString = `${hours % 12 || 12}:${minutes.toString().padStart(2, '0')} ${hours < 12 ? 'AM' : 'PM'}`;
    
    console.log(name, convertedDate, time,timeString, ptId, reason);


   





    const updateAppointment = doc(db, "AppointmentDetails", state.state.docid);
    await updateDoc(updateAppointment, {
     
      date: datew,
      reason: reason,
      time: time,
      duration:duration,
      timeString: timeString,
      updatedCreated :serverTimestamp()
    });
  




    


    
   axios({
      method: "POST",
      url:
        "https://graph.facebook.com/v15.0/" +
        phidtoken +
        "/messages?access_token=" +
        token,
      data: {
        messaging_product: "whatsapp",
        recipient_type: "individual",
        to: 91 + phNumber,
        type: "template",
        template: {
          name: "revised_appointment_details",
          language: {
            code: "en_US",
          },
          components: [
            {
              type: "body",
              parameters: [
                {
                  type: "text",
                  text: state.state.name ,
                },
                {
                  type: "text",
                  text: convertedDate,
                },
                {
                  type: "text",
                  text: timeString,
                },
              ],
            },
            

     
   




          ],
        },
      },

      headers: {
        "Content-Type": "application/json",
      },
    })

    .then((res)=>{
        console.log(res);
      }).catch((err)=>{
        
        console.log(err);
      })


    setShowAlert(true)

    setName("");
    setTime("");
    setDate("");
    setReason("");
    setDuration("")
    setName2(true)
    
    setTimeout(() => {
      setShowAlert(false)
      navigate(-1)
    }, 1500)
  
  };

  const handleChangeTime = (event) => {
    setTime(event.target.value);
  };
  const handleChangeDuration = (event) => {
    setDuration(event.target.value);
  };
  const [data, setData] = useState([]);




  

  useEffect(() => {
    const patientProfile = collection(db, "patientProfile");
    
  
      const abc = name.toLowerCase()
      
   
    const qd = query(patientProfile, where("namearray", "array-contains", abc));
    //mounts


    const unsub = onSnapshot(qd, (snap) => {
      let newArray = [];

      snap.forEach((doc) => {
        newArray.push({
          ...doc.data(),
          docid: doc.id,
          ptId: doc.get("ptId"),
          ptname: doc.get("name"),
          phoneNumber: doc.get("phNumber"),
          DOB: doc.get("DOB"),
          age: doc.get("age"),
          gender: doc.get("gender"),
          created: doc.get("created"),
          TotalAmount: doc.get("TotalAmount"),
          paid: doc.get("paid"),
        });
      });

      console.log(newArray);

      setData(newArray);
     
    });

    return () => {
      unsub();
    };
  }, [name]);

  return (
    <div>
    
      <div
        style={{
          height: "50px",
          backgroundColor: "rgb(10,13,37)",
          width: "100%",
          borderBottomRightRadius: "20px",
          borderBottomLeftRadius: "20px",

          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          color: "white",
        }}
      >
        <div onClick={() => navigate(-1)} style={{ marginLeft: "20px" }}>
          <ArrowBackIosIcon></ArrowBackIosIcon>
        </div>
        <div style={{ marginLeft: "40px" }}>Reschedule Appointment</div>
        <div style={{ fontWeight: "bold" }}>{convertedDate}</div>
      </div>

      { 
showAlert == true ? 

 <Stack sx={{ width: '100%',position:'absolute',zIndex:'999' }} spacing={2}>
     
      <Alert severity="success">
        <AlertTitle>Success</AlertTitle>
       Reschedule Appointment added -<strong>Successfully</strong>
      </Alert>
    </Stack> :<div></div>

      }

      <div className="midsec" style={{ margin: "10px" }}>
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>

          <TextField
            id="standard-basic"
    
            label="Patient Name"
            className={classes.field}
            variant="standard"
            style={{ marginTop: "5px" }}
            fullWidth
            defaultValue={state.state.name }
            InputProps={{
            readOnly: true,
          }}

            // autoFocus
          />

         

          <TextField
            onChange={(e) => setReason(e.target.value)}
          
            type="text"
            className={classes.field}
            label="Reason"
            id="standard-basic"
            variant="standard"
            style={{ marginTop: "5px" }}
            fullWidth
            defaultValue={state.state.reason}
            required

            // autoFocus
          />

          <div style={{ marginBottom: "10px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                // border: "1px solid grey",
                borderRadius: "15px",
                color: "grey",
                backgroundColor: "#d4dafa",
                height: "40px ",
                marginTop: "10px",

                alignItems: "center",
              }}
            >
              <div style={{ color: "#666565", marginLeft: "30px" }}>
                Appointment Date:
              </div>
              <TextField
                style={{
                  // color: "red",
                  backgroundColor: "white",
                  border: "2px solid white",
                  borderRadius: "15px",

                  width: "100px",
                  // marginTop:'20px',
                  marginRight: "30px",
                  // paddingTop:'-40px'
                }}
                // id="standard-basic"
                label=""
                type="date"
                defaultValue={Date.now()}
                required
                onChange={(event) => setDate(event.target.value)}
                // sx={{ width: "100%" }}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                // border: "1px solid grey",
                borderRadius: "15px",
                color: "grey",
                height: "40px ",
                backgroundColor: "#d4dafa",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <div style={{ color: "#666565", marginLeft: "30px" }}>
                Appointment Time:
              </div>

              <select
              
                name="time"
                id="pet-select"
                style={{
                  height: "30px",
                  borderRadius: "10px",
                  // marginTop: "20px",
                  border: "1px solid white",
                  // marginLeft: "15px",
                  backgroundColor: "white",
                  marginRight: "30px",
                }}
                value={time}
                onChange={handleChangeTime}
                required  
              >
                <option value="">Choose a Time</option>
                <option value="1000">10:00 AM </option>
                <option value="1030">10:30 AM RCT</option>
                <option value="1100">11:00 AM </option>
               
             
                <option value="1130">11:30 AM </option>
                <option value="1200">12:00 PM </option>
                <option value="1230">12:30 PM  RCT </option>
                <option value="1300">01:00 PM  RCT </option>
            
     
              
              
          
              
                <option value="1600">04:00 PM  RCT </option>
                <option value="1630">04:30 PM   </option>
                <option value="1700">05:00 PM   </option>
             
           
                <option value="1730">05:30 PM </option>
                <option value="1800">06:00 PM RCT  </option>
                <option value="1830">06:30 PM</option>
                <option value="1900">07:00 PM</option>
           
                <option value="1930">07:30 PM</option>
             
              </select>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                // border: "1px solid grey",
                borderRadius: "15px",
                color: "grey",
                height: "40px ",
                backgroundColor: "#d4dafa",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <div style={{ color: "#666565", marginLeft: "30px" }}>
                Duration:
              </div>

              <select
                name="duration"
                id="pet-select"
                style={{
                  height: "30px",
                  borderRadius: "10px",
                  // marginTop: "20px",
                  border: "1px solid white",
                  // marginLeft: "15px",
                  backgroundColor: "white",
                  marginRight: "30px",
                }}
                value={duration}
                onChange={handleChangeDuration}
              >
                <option value="">Duration</option>
                <option value="15 Mins">15 Mins </option>
                <option value="30 Mins">30 Mins</option>
                <option value="45 Mins">45 Mins </option>
                <option value="1 Hr">1 Hr</option>
                <option value="1.5Hrs">1.5 Hrs </option>
                <option value="2 Hrs">2 Hrs </option>
                <option value="2.5 Hrs">2.5 Hrs  </option>
                <option value="3 Hrs">3 Hrs </option>
               
             
               
             
              </select>
            </div>
          </div>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              className={classes.field}
              type="submit"
              color="primary"
              variant="contained"
              style={{
                backgroundColor: "rgb(10,13,37)",

                borderRadius: "10px",
              }}
              fullWidth
            >
              Submit
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}




export default Reschedule