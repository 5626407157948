import { TextField } from "@material-ui/core";
import React, { useState } from "react";
import "./cheifc.css";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useNavigate } from "react-router-dom";
function Cheifc( { onNext ,setData }) {
  const [chiefComplaint, setChiefComplaint] = useState("");
  const [buttonClicked, setButtonClicked] = useState(false);

  


  const handleComplaintClick = (complaint) => {
    if (chiefComplaint.includes(complaint)) {
      setChiefComplaint((prevComplaint) =>
        prevComplaint.replace(new RegExp(complaint, "g"), "")
      );
      setButtonClicked((prevClicked) => ({
        ...prevClicked,
        [complaint]: false,
      }));
    } else {
      setChiefComplaint((prevComplaint) => prevComplaint + complaint);
      setButtonClicked((prevClicked) => ({
        ...prevClicked,
        [complaint]: true,
      }));
    }
  };


  const handleClickNext = () => {
    const data = chiefComplaint;
    setData (data)

    console.log('====================================');
    console.log(data);
    console.log('====================================');

    onNext();

  }


  return (
    <div style={{ overflowY:'scroll', height:'90vh'}}>
      <div  >
        <div style={{ margin: "5px", fontSize: "16px", fontWeight: "bold" }}>
          Chief Complaint
        </div>

        <div >
        <div style={{ marginTop: "15px" }}>
          <TextField
            id="outlined-basic"
            label="C/C"
            variant="outlined"
            fullWidth
            value={chiefComplaint}
            onChange={(e) => setChiefComplaint(e.target.value)}
          />
        </div>
        <div>
          <button
            className={`buttonclass ${
              buttonClicked[" Patient Complaints of "] && "clicked"
            }`}
            onClick={() => handleComplaintClick(" Patient Complaints of ")}
          >
            Patient Complaints of
          </button>

          <div style={{ fontWeight: "bold", color: "gray", marginTop: "10px" }}>
            Quick type
          </div>
          <div>
          <button
    className={`buttonclass ${buttonClicked[" Pain "] && "clicked"}`}
    onClick={() => handleComplaintClick(" Pain ")}
  >
    Pain
  </button>
  <button
    className={`buttonclass ${buttonClicked[" Bleeding gums "] && "clicked"}`}
    onClick={() => handleComplaintClick(" Bleeding gums ")}
  >
    Bleeding gums
  </button>
  <button
    className={`buttonclass ${buttonClicked[" Consultation "] && "clicked"}`}
    onClick={() => handleComplaintClick(" Consultation ")}
  >
    Consultation
  </button>
  <button
    className={`buttonclass ${buttonClicked[" Decayed Teeth "] && "clicked"}`}
    onClick={() => handleComplaintClick(" Decayed Teeth ")}
  >
    Decayed Teeth
  </button>
  <button
    className={`buttonclass ${buttonClicked[" Dental caries "] && "clicked"}`}
    onClick={() => handleComplaintClick(" Dental caries ")}
  >
    Dental caries
  </button>
  <button
    className={`buttonclass ${buttonClicked[" Difficulty in Chewing "] && "clicked"}`}
    onClick={() => handleComplaintClick(" Difficulty in Chewing ")}
  >
    Difficulty in Chewing
  </button>
  <button
    className={`buttonclass ${buttonClicked[" Dirty teeth "] && "clicked"}`}
    onClick={() => handleComplaintClick(" Dirty teeth ")}
  >
    Dirty teeth
  </button>
  <button
    className={`buttonclass ${buttonClicked[" Dirty tooth "] && "clicked"}`}
    onClick={() => handleComplaintClick(" Dirty tooth ")}
  >
    Dirty tooth
  </button>
  <button
    className={`buttonclass ${buttonClicked[" Food lodgement "] && "clicked"}`}
    onClick={() => handleComplaintClick(" Food lodgement ")}
  >
    Food lodgement
  </button>
  <button
    className={`buttonclass ${buttonClicked[" Fracture "] && "clicked"}`}
    onClick={() => handleComplaintClick(" Fractured tooth")}
  >
    Fractured tooth
  </button>
  <button
    className={`buttonclass ${buttonClicked[" Misaligned Teeth "] && "clicked"}`}
    onClick={() => handleComplaintClick(" Misaligned Teeth ")}
  >
    Misaligned Teeth
  </button>
  <button
    className={`buttonclass ${buttonClicked[" Missing tooth "] && "clicked"}`}
    onClick={() => handleComplaintClick(" Missing tooth ")}
  >
    Missing tooth
  </button>
  <button
    className={`buttonclass ${buttonClicked[" Mobility "] && "clicked"}`}
    onClick={() => handleComplaintClick(" Mobility ")}
  >
    Mobility
  </button>

  <button
    className={`buttonclass ${buttonClicked[" Root Stump "] && "clicked"}`}
    onClick={() => handleComplaintClick(" Root Stump ")}
  >
    Root Stump
  </button>
  <button
    className={`buttonclass ${buttonClicked[" Sensitivity "] && "clicked"}`}
    onClick={() => handleComplaintClick(" Sensitivity ")}
  >
    Sensitivity
  </button>
  <button
    className={`buttonclass ${buttonClicked[" Yellowish Teeth "] && "clicked"}`}
    onClick={() => handleComplaintClick(" Yellowish Teeth ")}
  >
    Yellowish Teeth
  </button>
</div>

        </div>
        <div>
          <div style={{ fontWeight: "bold", color: "gray", marginTop: "10px" }}>
            ----
          </div>
          <div>
            <button
              className={`buttonclass ${buttonClicked["in his "] && "clicked"}`}
              onClick={() => handleComplaintClick("in his ")}
            >
              in his
            </button>
            <button
              className={`buttonclass ${buttonClicked["in her "] && "clicked"}`}
              onClick={() => handleComplaintClick("in her ")}
            >
              in her
            </button>
          </div>
          <div style={{ fontWeight: "bold", color: "gray", marginTop: "10px" }}>
            Site
          </div>
          <div>
            <button
              className={`buttonclass ${buttonClicked["Right "] && "clicked"}`}
              onClick={() => handleComplaintClick("Right ")}
            >
              Right
            </button>
            <button
              className={`buttonclass ${buttonClicked["Left "] && "clicked"}`}
              onClick={() => handleComplaintClick("Left ")}
            >
              Left
            </button>
          </div>
          <div>
            <div>
              <button
                className={`buttonclass ${
                  buttonClicked["Upper "] && "clicked"
                }`}
                onClick={() => handleComplaintClick("Upper ")}
              >
                Upper
              </button>
              <button
                className={`buttonclass ${
                  buttonClicked["Lower "] && "clicked"
                }`}
                onClick={() => handleComplaintClick("Lower ")}
              >
                Lower
              </button>
            </div>
          </div>
          <button
            className={`buttonclass ${
              buttonClicked["back tooth region . "] && "clicked"
            }`}
            onClick={() => handleComplaintClick("back tooth region . ")}
          >
            back tooth region .
          </button>
          <button
            className={`buttonclass ${
              buttonClicked["front tooth region . "] && "clicked"
            }`}
            onClick={() => handleComplaintClick("front tooth region . ")}
          >
            front tooth region .
          </button>

          {/* <button className="buttonclass">Yellowish tooth</button> */}
        </div>
      </div></div>

<div style={{width:'100%'}}>

      <button
          style={{
            width: "90%",
            borderRadius: "20px",
            padding: "10px ",
            fontSize: "14px",
            fontWeight: "bold",
            backgroundColor: "#3a7a91",
            margin: "10px",
            color: "white",
            border: "none",
            display:'flex',
            alignItems:'center',
            justifyContent:'center'
          }}
          onClick={handleClickNext}
        >
        
              Save
        <div>

           
        </div>
       
        </button>
</div>
    </div>
  );
}

export default Cheifc;