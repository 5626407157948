import React, { useEffect, useState } from "react";
import { WhatsApp, AccountCircle, PersonPin } from "@mui/icons-material";
import axios from "axios";
import { collection, limit, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "../../firebaseConfig/firebase";
import { Navigate, useNavigate } from "react-router-dom";

const token = process.env.TOKEN;
const mytoken = process.env.MYTOKEN;
const phidtoken = process.env.phidtoken;

function Clinicpage() {
  let navigate = useNavigate();
  const [data, setData] = useState([]);


useEffect(()=>{

  const chatProfile = collection(db, "wa_profile")

  const queryProfile = query(chatProfile, orderBy("newDate","desc"),limit(10) )


  const unsub = onSnapshot (queryProfile, (snap) => {
    let newArray = [];

    snap.forEach((doc) => {
      newArray.push({
        ...doc.data(),

        docid: doc.id,

        created: doc.get("created"),
        msg_body: doc.get("msg_body"),
        name: doc.get("name"),
        newDate: doc.get("newDate"),
        wa_no: doc.get("wa_no"),
       
      });
    });

    console.log(newArray);

    setData(newArray);
  });

  return () => {
    unsub();
  };



},[])




  return (
    <div>
      <div
        style={{
          height: "30px",
          // backgroundColor: "green",
          color: "rgba(37, 97, 54 ,1 )",
          borderBottom: "1px solid rgba(37, 97, 54 ,0.2 )",
          padding: "5px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {" "}
        <WhatsApp />
        PatraChats
      </div>
      <div style={{ margin: "2px",height:'85vh',overflowY:'scroll'  }}>

      

      {data.map((item) => (

     
      <div
        style={{
          backgroundColor: "white",
          fontSize: "20px",
          display: "flex",
          alignItems: "center",
         overflowX:'hidden',
          color: "grey",
        }}


        onClick={() => {
                            navigate("/chatPage", {
                              state: {
                               
                              

                               created:item.created,
                               msg_body:item.msg_body ,
        name:item.name,
        newDate: item.newDate,
        wa_no:item.wa_no ,
                              },
                            });
                          }}

      >
        <PersonPin sx={{ fontSize: 50 }} />
        <div
          style={{
            display:'flex',
            width: "100%",
            height: "55px",
            // borderTop:'1px solid rgba(37, 97, 54 ,0.2 )',
            borderBottom: "1px solid rgba(37, 97, 54 ,0.2 )",
            justifyContent:'space-between'
          }}
        >
          <div
            style={{
              // border:'1px solid black',
              margin: "10px",
              display: "flex",
              justifyContent: "space-between",
              width:'200px',
              overflow:'hidden'
              
            }}
          >
            <div
              style={{ color: "black", fontSize: "14px", fontWeight: "bold", }}
            >
              {item.name}
              <div
                style={{
                  fontWeight: "normal",
                  fontSize: "12px",
                  color: "grey",
                  height: "20px",
                  overflow: "hidden",
                  marginLeft:'10px',
                  marginRight:'5px'
                }}
              >

               {item.msg_body}
              
              </div>
            </div>
          </div>
            <div style={{ marginTop:'-12px' }}>
            <div  style={{  }}>

            <a
                        href={`tel: ${item.wa_no}`}
                        style={{
                          color: "rgb(32, 84, 131)",
                          textDecoration: "none",
                        }}
                      >
                        {" "}
                        <p
                          style={{
                            fontSize: "14px",

                            fontWeight: "bold",
                            marginRight: "10px",
                          }}
                        >
                        {item.wa_no}
                        </p>
                      </a>




            
            </div>
            <div  style={{ fontSize: "10px", color: "grey" }}>
             {item.newDate.toDate().toLocaleString()}
            </div>
        </div>
             
             </div>
      </div>
      
      ))}</div>
    </div>
  );
}

export default Clinicpage;
