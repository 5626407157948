import { useState } from "react";
import { Button, Typography } from "@mui/material"; // Import Typography component from Material-UI
 // Import Firebase
import "firebase/firestore"; 
import "./cheifc.css";
import { db } from "../../../../../firebaseConfig/firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
function Medication({tId}) {
  const [selectedMedicines, setSelectedMedicines] = useState([]);
  const [buttonClicked, setButtonClicked] = useState(false);
  const medicineArray = [
    {
      MedicineName: "Tab. Clavum 625mg",
      Frequency: "1-0-1",
      timing: "After Meal",
      Duration: "5 days",
      Qty: "10",
    }, {
      MedicineName: "Tab. AmoxyClav 625mg",
      Frequency: "1-0-1",
      timing: "After Meal",
      Duration: "5 days",
      Qty: "10",
    },
    {
      MedicineName: "Tab. AmoxyClav 375mg",
      Frequency: "1-0-1",
      timing: "After Meal",
      Duration: "5 days",
      Qty: "10",
    },
    {
      MedicineName: "Cap. AmoxyCillin 500mg",
      Frequency: "1-0-1",
      timing: "After Meal",
      Duration: "5 days",
      Qty: "10",
    },
    {
      MedicineName: "Tab. Zerodol Sp",
      Frequency: "1-0-1",
      timing: "After Meal",
      Duration: "5 days",
      Qty: "10",
    },   {
      MedicineName: "Tab. Zerodol P",
      Frequency: "1-0-1",
      timing: "After Meal",
      Duration: "5 days",
      Qty: "10",
    },
    {
      MedicineName: "Tab. Keterol Dt",
      Frequency: "SOS",
      timing: "Severe Pain",
      Duration: "5 days",
      Qty: "10",
    },
    {
      MedicineName: "Tab. Pantop DSR",
      Frequency: "1-0-0",
      timing: "Before Meal",
      Duration: "5 days",
      Qty: "5",
    },
 
    {
      MedicineName: "Tab. Taxim-0 200mg",
      Frequency: "1-0-1",
      timing: "After Meal",
      Duration: "5 days",
      Qty: "10",
    },
    {
      MedicineName: "Tab. Flagyl 400mg",
      Frequency: "1-1-1",
      timing: "After Meal",
      Duration: "5 days",
      Qty: "15",
    },
    {
      MedicineName: "Tab. Chymoral Forte",
      Frequency: "1-0-1",
      timing: "After Meal",
      Duration: "5 days",
      Qty: "10",
    },
    {
      MedicineName: "Tab. Dolo 650",
      Frequency: "1-0-1",
      timing: "After Meal",
      Duration: "5 days",
      Qty: "10",
    },
    {
      MedicineName: "Tab. Dolo 500",
      Frequency: "1-0-1",
      timing: "After Meal",
      Duration: "5 days",
      Qty: "10",
    },
    {
      MedicineName: "Cap. Nurokind forte",
      Frequency: "1-0-1",
      timing: "After Meal",
      Duration: "5 days",
      Qty: "10",
    },
    {
      MedicineName: "Syp. Moxikind cv",
      Frequency: "1-0-1",
      timing: "After Meal",
      Duration: "5 days",
      Qty: "5ml",
    },
    {
      MedicineName: "Syp. Flexon",
      Frequency: "1-0-1",
      timing: "After Meal",
      Duration: "5 days",
      Qty: "5ml",
    },
    {
      MedicineName: "Inj. Dinapar Aq",
      Frequency: " ",
      timing: " ",
      Duration: "2 days",
      Qty: "2",
    },
    {
      MedicineName: "Inj Dexona",
      Frequency: " ",
      timing: " ",
      Duration: " ",
      Qty: "2",
    },
    {
      MedicineName: "Povidine Mouthwash",
      Frequency: " two times in a Day",
      timing: " After meal",
      Duration: " 7 days",
      Qty: "1",
    },
    {
      MedicineName: "Ventaj Mouthwash",
      Frequency: " two times in a Day",
      timing: " After meal",
      Duration: " 7 days",
      Qty: "1",
    },
    {
      MedicineName: "Sensoform ToothPaste",
      Frequency: " two times in a Day",
      timing: " After meal ",
      Duration: " 30 days",
      Qty: "1",
    },
    {
      MedicineName: "Cap lycostar",
      Frequency: " Once in a Day",
      timing: " After meal ",
      Duration: " 30 days",
      Qty: "30",
    },
    {
      MedicineName: "Cap Antoxid HC",
      Frequency: " Once in a Day",
      timing: " After meal ",
      Duration: " 30 days",
      Qty: "30",
    },
    {
      MedicineName: "Oint Kenacort",
      Frequency: " two - three times in a Day",
      timing: "  ",
      Duration: " 15 days",
      Qty: "1",
    },
    {
      MedicineName: "Oint Mucopain",
      Frequency: " two - three times in a Day",
      timing: "  ",
      Duration: " 15 days",
      Qty: "1",
    },
    {
      MedicineName: "Onit Hexigel",
      Frequency: " two - three times in a Day",
      timing: "  ",
      Duration: " 15 days",
      Qty: "1",
    },
    {
      MedicineName: "Onit Metrogyl DG Forte 0.1%",
      Frequency: " two - three times in a Day",
      timing: "  ",
      Duration: " 15 days",
      Qty: "1",
    },
 ////....
   
  ];


  // Function to handle saving selected medicines to Firestore DB
  const handleSaveToDB =async () => {

    const pushOnExam = doc(db, "treatment", tId);
    try {
      const docSnapshot = await getDoc(pushOnExam);
      if (docSnapshot.exists()) {
        
       
        const updatedArray = selectedMedicines;

        await updateDoc(pushOnExam, {
          medicine: updatedArray,
        });

        console.log("New array data pushed successfully!");

      } else {
        console.log("Document does not exist.");
      }
    } catch (error) {
      console.error("Error pushing new array data: ", error);
    }
    




 console.log('====================================');
 console.log(selectedMedicines);
 console.log('====================================');

 setSelectedMedicines([])

  };


 const handleComplaintClick = (medicine) => {
    const index = selectedMedicines.findIndex(
      (selectedMedicine) => selectedMedicine.MedicineName === medicine.MedicineName
    );

    if (index !== -1) {
      // Medicine is already selected, so remove it
      setSelectedMedicines((prevMedicines) =>
        prevMedicines.filter(
          (selectedMedicine) => selectedMedicine.MedicineName !== medicine.MedicineName
        )
      );
    } else {
      // Medicine is not selected, so add it
      setSelectedMedicines((prevMedicines) => [...prevMedicines, medicine]);
    }

    // Toggle button clicked state
    setButtonClicked((prevClicked) => ({
      ...prevClicked,
      [medicine.MedicineName]: !prevClicked[medicine.MedicineName],
    }));
  };

  return (
    <div>
    <div style={{ margin: "5px", fontSize: "16px", fontWeight: "bold" }}>
      Medicine Page
    </div>
    {/* Map through the medicineArray to create buttons */}
    <div style={{height:'240px', overflowY:'scroll'}}>

  
    {medicineArray.map((medicine, index) => (
      <button
     
        key={index}
        variant="contained"
        
        onClick={() => handleComplaintClick(medicine)}
        style={{
          borderRadius: "5px",
    padding: "10px",
    fontSize:" 12px",
    margin: "8px",
    border: "none",
 

            margin: "5px",
            backgroundColor: selectedMedicines.some(
              (selectedMedicine) => selectedMedicine.MedicineName === medicine.MedicineName
            )
              ? "lightblue" // Change the background color when selected
              : "#F1F3F4", // Default background color
          }}
      >
        {medicine.MedicineName}
      </button>
    ))}  </div>
    {/* Display selected medicines */}
    <Typography variant="h6" style={{ margin: "10px 0" }}>
      Selected Medicines:
    </Typography>
    <div style={{ height:'200px', overflowY:'scroll'}}>
      {selectedMedicines.map((medicine, index) => (
        <div>
        <div style={{ fontWeight:'bold',marginTop:'5px'}} key={index} variant="body1">
          {medicine.MedicineName}
        </div>
        <div>
        {medicine.Frequency},
        {medicine.timing},
        {medicine.Duration},
       Quantity {medicine.Qty}
      



        </div>
        </div>
      ))}
    </div>
    {/* Button to add selected medicines to DB */}
    <Button
      variant="contained"
      color="primary"
      onClick={handleSaveToDB}
      style={{ margin: "5px" }}
    >
      Add Medicine to DB
    </Button>
  </div>
  );
}

export default Medication;
