import React, { useState } from "react";
import "./bottombar.css";
import { NavLink } from "react-router-dom";
import {
  HomeOutlined,
  AirlineSeatReclineNormal,
  PersonSearch,
  CalendarMonth,
  WhatsApp
} from "@mui/icons-material";

function Bottombar() {
  const [activePath, setActivePath] = useState(window.location.pathname);

  const handleNavClick = (path) => {
    setActivePath(path);
  };

  const navLinkStyle = (path) => ({
    color: activePath === path ? "white" : "grey",
    textDecoration: "inherit",
  });

  return (
    <div className="bottombar">
      <NavLink to="/" style={navLinkStyle("/")} onClick={() => handleNavClick("/")}>
        <div className="icon_Name">
          <HomeOutlined />
          <p>Home</p>
        </div>
      </NavLink>
      <NavLink to="/opd" style={navLinkStyle("/opd")} onClick={() => handleNavClick("/opd")}>
        <div className="icon_Name">
          <AirlineSeatReclineNormal />
          <p>OPD</p>
        </div>
      </NavLink>
      <NavLink to="/patients" style={navLinkStyle("/patients")} onClick={() => handleNavClick("/patients")}>
        <div className="icon_Name">
          <PersonSearch />
          <p>Patients</p>
        </div>
      </NavLink>
      <NavLink to="/calender" style={navLinkStyle("/calender")} onClick={() => handleNavClick("/calender")}>
        <div className="icon_Name">
          <CalendarMonth />
          <p>Appointment</p>
        </div>
      </NavLink>
      <NavLink to="/clinic" style={navLinkStyle("/clinic")} onClick={() => handleNavClick("/clinic")}>
        <div className="icon_Name">
          <WhatsApp />
          <p>Whatsapp</p>
        </div>
      </NavLink>
    </div>
  );
}

export default Bottombar;
