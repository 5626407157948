import React, { useRef, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Stack from "@mui/material/Stack";
import axios from "axios";

import {
  addDoc,
  collection,
  query,
  onSnapshot,
  doc,
  serverTimestamp,
  getDoc,
  updateDoc,
} from "firebase/firestore";

import { Navigate } from "react-router-dom";
import { db } from "../../../firebaseConfig/firebase";

function Addvisit(props) {
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState("");
  const [status, setStatus] = useState("");

  const [workname, setWorkName] = useState("");
  const [workDetails, setWorkDetails] = useState("");

  const saveButtonRef = useRef(null);

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      saveButtonRef.current.click();
    }
  };

  const handlestatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason && reason == "backdropClick") return;

    setOpen(false);
  };

  const workAddBtn = async (indextoUpdate) => {
    try {
      const documentRef = doc(db, "treatment", props.tId);
      const docSnapshot = await getDoc(documentRef);
      if (docSnapshot.exists()) {
        // Fetch the existing description array or initialize an empty array
        const descriptionArray = docSnapshot.data().description || [];
        const index = indextoUpdate; // Index to update
  
        // Initialize the new work item
        const now = new Date();
        const date = now.toISOString().split('T')[0]; // Get the date in YYYY-MM-DD format
        const time = now.toTimeString().split(' ')[0]; // Get the time in HH:MM:SS format
  
        // Initialize the new work item with time and date
        const newWorkItem = {
          workName: workname,
          workDetails: workDetails,
          date: date, // Added field
          time: time  // Added field
        };
  
        if (index >= 0 && index < descriptionArray.length) {
          // Check if the object at the specified index exists
          if (!descriptionArray[index].hasOwnProperty("workArray")) {
            // If workArray field doesn't exist, add it with the new work item
            descriptionArray[index].workArray = [newWorkItem]; 
            descriptionArray[index].status = status;
            // Changed line
          } else {
            // If workArray field exists, append the new work item to the existing array
            descriptionArray[index].workArray.push(newWorkItem); // Changed line
          }
  
          // Update the status
          descriptionArray[index].status = status;
  
        } else {
          throw new Error("Index out of bounds");
        }
  
        // Update the document with the modified array
        await updateDoc(documentRef, { description: descriptionArray });
  
        console.log("Document successfully updated!");
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  
    // Clear the form fields only if the data was successfully saved
    setStatus("");
    setWorkDetails('');
    setWorkName('')
    handleClose()
  };
  
  return (
    <div>
      <Button
        onClick={handleClickOpen}
        style={{ fontSize: "14px", color: "green", width: "100%" ,padding:'5px'}}
      >
        Add A Visit
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            borderRadius: "15px",
          },
        }}
      >
        <DialogTitle>Add Visit</DialogTitle>
        <DialogContent>
          <DialogContentText>{props.tId}</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="Work"
            label="Work"
            fullWidth
            variant="standard"
            onChange={(event) => {
              const { value } = event.target;
              setWorkName(value);
            }}
            required
            onKeyDown={handleKeyDown}
          />
          <TextField
            margin="dense"
            id="WorkD"
            label="Work in details"
            fullWidth
            variant="standard"
            onChange={(event) => {
              const { value } = event.target;
              setWorkDetails(value);
            }}
            required
            onKeyDown={handleKeyDown}
          />

          <div>
            <FormControl required>
              <FormLabel
                id="demo-controlled-radio-buttons-group"
                style={{ marginBottom: "10px", marginTop: "10px" }}
              >
                Status
              </FormLabel>
              <RadioGroup
                style={{ marginLeft: "15px" }}
                row
                name="row-radio-buttons-group"
                aria-labelledby="demo-controlled-radio-buttons-group"
                value={status}
                onChange={handlestatusChange}
              >
                <FormControlLabel
                  style={{ fontSize: "10px",margin:'5px' }}
                  value="On going"
                  control={<Radio />}
                  label="On going"
                />
                <FormControlLabel
                  style={{ fontSize: "10px",margin:'5px' }}
                  value="Proposed"
                  control={<Radio />}
                  label="Proposed"
                />
                <FormControlLabel
                  style={{ fontSize: "10px",margin:'5px' }}
                  value="PendingMoney"
                  control={<Radio />}
                  label="Work Completed money pending"
                />
                <FormControlLabel
                  style={{ fontSize: "10px" ,margin:'5px'}}
                  value="Completed"
                  control={<Radio />}
                  label="Completed"
                />
              </RadioGroup>
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} style={{ color: "red" }}>
            Cancel
          </Button>
          <Button
            ref={saveButtonRef}
            onClick={() => workAddBtn(props.index)}
            style={{
              fontSize: "16px",
              // fontWeight: "bold",
              // border: "1px solid ",
              padding: "3px",
              borderRadius: "5px",
              backgroundColor: "rgb(8,10,48,0.9)",
              color: "white",
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Addvisit;
