import { PropaneSharp } from "@mui/icons-material";
import { padding } from "@mui/system";


import {
  collection,
  onSnapshot,
  query,
  where,
  doc,
  deleteDoc,
  orderBy,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { db } from "../../../firebaseConfig/firebase";
import AddNewEvent from "../AddNewEvent";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

function Treatmentpage() {
  let navigate = useNavigate();
  const state = useLocation();
  const [procedure, setProcedure] = useState([]);
  const [paidAmount, setpaidAmount] = useState();

  useEffect(() => {
    const eventData = collection(db, "eventsDetails");
    const qd = query(
      eventData,
      where("treatmentId", "==", state.state.tId),
      orderBy("created", "asc")
    );

    const unsub = onSnapshot(qd, (snap) => {
      let newArray = [];

      snap.forEach((doc) => {
        newArray.push({
          ...doc.data(),

          tretmentId: doc.id,
          eventName: doc.get("eventName"),
          eventDetails: doc.get("eventDetails"),
          paid: doc.get("paid"),
          created: doc.get("created"),

          date: doc.get("date"),
        });
      });

      console.log(newArray);

      setProcedure(newArray);
    });

   

    return () => {
      unsub();
    };
  }, []);

  const totalPaidAmount = procedure
  .map((item) => parseInt(item.paid))
  .reduce((prev, curr) => prev + curr, 0);

console.log(totalPaidAmount);


const dueAmount = state.state.tTotal - totalPaidAmount


  return (
    <div>
      <div
        className="topSec"
        style={{
          backgroundColor: "rgb(5, 2, 43)",
          // borderRadius: "30px",
          height: "20vh",
          // margin: "10px",
          color: "white",
          padding: "15px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginLeft: "10px",
            marginRight: "10px",
          }}
        >
          <div onClick={ ()=> navigate(-1)} style={{fontSize:'12px'}}>  <ArrowBackIosIcon></ArrowBackIosIcon></div>
          <div>{state.state.ptName}</div>
          <div  style={{ alignItems:'flex'}}>
          <div style={{ fontWeight: "bolder", fontSize: "14px" }}>

            {state.state.tName}
          </div>
        <div style={{ color: "grey" , fontSize: "12px" }}>{state.state.tCreated}</div>
          </div>
          {/* <div>32/M</div> */}
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "8px",
          }}
        >
       
          <div>
            {/* <div>Paid: {totalPaidAmount}</div> */}
            {/* <div>Due: {dueAmount}</div> */}
          </div>
        </div>
      </div>{" "}
      <div
        className="bottomSec"
        style={{
          height: "80vh",
          marginTop: "-50px",
          backgroundColor: "white",
          borderRadius: "35px",
          borderTop: "6px solid rgb(231, 233, 251)",
        }}
      >
        {/* <div style={{backgroundColor:'grey'}}> */}

        <div
          style={{
            display: "flex",
            marginLeft: "60px",
            marginTop: "10px",
            fontWeight: "bold",
          }}
        >
          <div style={{ flex: "1" }}>Date</div>
          <div style={{ flex: "2" }}>Procedure</div>
        </div>
        {/* </div> */}
        <div style={{ overflow: "scroll", height: "65vh", width: "100%" }}>
          {procedure.length === 0 ? <div style={{marginTop:'50px'}}> Loading data ... </div>: procedure.map((item) => (
            <div
              style={{
                display: "flex",
                marginLeft: "30px",
                marginTop: "20px",
                marginRight: "10px",
              }}
            >
              <div style={{ flex: "1", fontSize: "14px",marginRight:'10px'}}>
                {" "}
                {item.created == null
                                  ? ""
                                  : item.created.toDate().toDateString()}
      
              </div>
       
              <div style={{ flex: "2",borderLeft:'1px dotted rgb(5, 2, 43)',paddingLeft:'10px', }}>
              <div>

                {item.eventName}
              </div>
                <div
                  style={{ color: "grey", fontSize: "14px", marginTop: "8px",marginRight:'15px' }}
                >
                  {item.eventDetails}
                </div>
              </div>
            </div>
          ))}
        </div>

        <div
          style={{
            display: "flex",
            position: "fixed",
            bottom: "0px",
            alignItem: "center",
            justifyContent: "flex-end",
         
            marginBottom: "100px",
         right: "20px",
            zIndex: "999",
            color: "red",
          }}
        >
          <div>
            <AddNewEvent tId={state.state.tId} ptId={state.state.id} ptName={state.state.ptName} ptPhNumber={state.state.ptPhNumber} tName={state.state.tName}/>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            position: "fixed",
            bottom: "0px",
            alignItem: "center",
            justifyContent: "flex-start",
         
            marginBottom: "100px",
        left:"20px",
            zIndex: "999",
            color: "red",
          }}
        >
          <div>
         
          </div>
        </div>
      </div>{" "}
    </div>
  );
}

export default Treatmentpage;
