



import { collection, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import React, { useState } from 'react'
import { db } from '../../../firebaseConfig/firebase';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function OpdProfilesub() {
  let navigate = useNavigate();
  const state = useLocation();
  const  [data, setData] = useState([])

  // const appointmentData = collection(db, "AppointmentDetails");

  useEffect(() => {
    const opdData = collection(db, "opdEntry");

   
    const tD = query(
        opdData,
      where("ptId", "==", state.state.opdId || state.state.ptid),
      orderBy("created", "desc")
    );

   

    const unsub = onSnapshot(tD, (snap) => {
      let newArray = [];

      snap.forEach((doc) => {
        newArray.push({
          ...doc.data(),

          opdId: doc.id,

          id: doc.get("ptId"),
          name: doc.get("ptName"),
          opdPay: doc.get("opdPay"),
          phoneNumber: doc.get("phNumber"),
          paymentMode: doc.get("paymentMode"),
          gender: doc.get("gender"),
          askPrice: doc.get("askPrice"),
          date: doc.get("date"),
          age: doc.get("age"),
          ptCreatedDate: doc.get("ptCreatedDate"),
          totalPaid: doc.get("totalPaid"),
          TotalAmount: doc.get("TotalAmount"),
          status: doc.get("status"),
          created: doc.get("created"),
        });
      });


      console.log(newArray);

      setData(newArray);
    });

    return () => {
      unsub();
    };
  }, []);



  return (
    <div
    style={{
      overflow: "scroll",
      height: "65vh",
      width: "100%",
      backgroundColor: "#EBEEF2",
    }}
  >
    
    
    
    


      {data.map((item) => (
        <div>
        <div
            style={{
              // width: "98%",
              position: "relative",
              // height: "80px",
              margin: "5px",
              borderLeft: "4px solid rgb(68,63,55)",
            //   backgroundColor: "white",
              borderRadius: "10px",
              padding: "13px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <div
                style={{ fontSize: "16px", fontWeight: "bold", color: "rgb(10,13,37)" }}
              >
                {" "}
                {item.date}
              </div>
           
            </div>{" "}
       
          </div>
             </div>
            ))}
    </div>
  )
}

export default OpdProfilesub