import React, { useEffect, useState } from "react";
import logo from "../../../profile/logo.png";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../../../../../firebaseConfig/firebase";
import Barcode from "react-barcode";
import {QRCodeSVG} from 'qrcode.react';

export const Printrx = React.forwardRef((props, ref) => {
  const { tId,name, age, gender, phoneNumber,examData,chiefC,investi } = props;
 
  const dataQrCode = {
    Id:tId,
    PatientName: name,
    age: age,
    gender: gender,
    Date: new Date().toLocaleDateString("en-GB"),
    ClinicDetails:"Patra Dental Clinic, Nabarangpur,9439123848 / 9078183678  ",
   
    
  };

  const jsonString = JSON.stringify(dataQrCode);
  return (
    <div ref={ref}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div
          style={{
            padding: "30px",
            display: "flex",
            flexDirection: "column",
            // alignItems: "flex-end",
            fontSize: "14px",
          }}
        >
          {" "}
          <div style={{ fontWeight: "bold" }}>Dr Soumya Ranjan Patra</div>
          <div>BDS, MIDA 2691(A) </div> <div> Consultant Dental Surgeon </div>{" "}
          <div>Implantology (MUMBAI)</div>
        </div>
        <div style={{ padding: "30px" }}>
          {" "}
          <img src={logo} height={85} />
        </div>
        <div
          style={{
            padding: "30px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            fontSize: "14px",
          }}
        >
          <div style={{ fontWeight: "bold" }}>PATRA DENTAL CLINIC</div>
          <div>Advanced Root Canal & Implant</div>
          <div>Centre</div> <div> Infront of Town Hall </div>{" "}
          <div>Main Road, Nabarangpur</div>
          <div style={{ fontWeight: "bold" }}>PH : 9439123848, 9078183678</div>
        </div>
      </div>
      <div style={{ textAlign: "center", marginTop: "-15px" }}>
        Mon - Sat : 10:00 Am - 08:00 Pm
        <div style={{ fontSize: "10px" }}>Sunday By Appointment Only</div>
     
      </div>
      <div
  style={{
    width: '100%',
    marginTop: '10px',
    borderTop: '1px solid black',
    padding: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  }}
>
  <div style={{ flex: 1, textAlign: 'left', marginLeft: '40px' }}>
    Date: {new Date().toLocaleDateString("en-GB")}
  </div>
  <div style={{ flex: 1, textAlign: 'center' }}>
    OPD CARD
  </div>
  <div style={{ flex: 1, textAlign: 'right', marginRight: '60px' }}>
    <QRCodeSVG value={jsonString} height={70} width={70} />
  </div>
</div>

      <div
        style={{
          borderTop: "1px solid black",
          borderBottom: "1px solid black",
          display: "flex",
          justifyContent: "space-between",
          padding: "20px",
          fontSize: "14px",
        }}
      >
        <div>
          <div style={{ paddingBottom: "10px", display: "flex" }}>
            <div style={{ fontWeight: "bold" }}>Patient Name </div>
            <div> : {name } {"( "}  {age} /{gender} {") "} </div>{" "}
          </div>
         
        </div>
        <div style={{ paddingRight: "20px" }}>
          {" "}
         
          <div> Phone number :{phoneNumber}</div>
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between",marginLeft:'30px' }}>
        <div style={{ padding: "20px", fontSize: "14px" }}>
          
        <div>

<div style={{ display: "flex" }}>
  {" "}
  <div>Chief Complaint :</div>
  <div style={{ marginLeft: "70px" }} marginLeft></div>- {chiefC} </div>


<div style={{ display: "flex", marginTop: "20px" }}>
  <div>On Examination :</div>
  <div style={{ marginLeft: "70px" }}>
    {examData ? examData.map((item, index) => (

     
      
      <div key={index} style={{ width: "100%" }}>
    
        {console.log(item)}
        <div>


          {item.description ?  item.description.map((exam, idx) => (
            <div key={idx} style={{ display: "flex", marginTop: "5px" }}>
              <div  style={{marginRight:'10px'}}>- {exam.onExamination} </div> # irt{" "}
              <div
              style={{marginLeft:'5px'}}
              >
                
                {Array.isArray(exam.toothNumber)
                  ? exam.toothNumber.join(",")
                  : exam.toothNumber}
              </div>
              {/* Adjust marginLeft as needed */}
            </div>
          )) :"m"}
        </div>
      </div>
    )) : 'no'}
  </div>
</div>

<div style={{ display: "flex" }}>
  {" "}
  <div>Investigation:</div>
  <div style={{ marginLeft: "90px" }} marginLeft></div>- {investi}
</div>

<div style={{ display: "flex", marginTop: "20px" }}>
  <div> Treatment Advice :</div>
  <div style={{ marginLeft: "60px" }}>
    {examData.map((item, index) => (
      <div key={index} style={{ width: "100%" }}>
        <div>
          {item.description ? item.description.map((exam, idx) => (
            <div key={idx} style={{ display: "flex", marginTop: "5px" }}>
              <div style={{marginRight:'10px'}}>- {exam.treatmentAdvice} </div> # irt{" "}
              <div
              style={{marginLeft:'5px'}}
              >
               
                {Array.isArray(exam.toothNumber)
                  ? exam.toothNumber.join(",")
                  : exam.toothNumber}
              </div>
              {/* Adjust marginLeft as needed */}
           
              {/* <div style={{fontSize:'12px', color:'grey'}}>{exam.status}</div> */}
            </div>
          )) : "no"}
        </div>
      </div>
    ))}
  </div>
</div>

</div>
       
<div style={{display:'flex'}}>


          <div> Medication :</div>
          <div style={{ marginLeft:'93px'}}>

         
          <div>Rx,</div>
          {examData.map((item, index) => (
            <div key={index} style={{ width: "100%" }}>
              <div>
                {item.medicine ? item.medicine.map((exam, idx) => (
                  <div
                    key={idx}
                    style={{
                      display: "flex",
                      borderBottom: "1px solid #D8D8D8",
                      padding: "5px",
                      marginTop: "10px",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "14px",
                        backgroundColor: "#F1F1F2",
                        padding: "5px",
                        borderRadius: "3px",
                      }}
                    > 
{exam.MedicineName}
                      
                    </div>
                    
       <div>{exam.Frequency}</div>
  <div>{exam.timing}</div>
  <div>{exam.Duration}</div>
  <div style={{marginLeft:'10px'}}> ( Qty -{exam.Qty})</div>
                    {/* Adjust marginLeft as needed */}
                   
                  </div>
                )) : "noM"}
              </div>
            </div>
          ))}

          </div>
        </div>
        </div>
        {/* <div
          style={{
            height: "40vh",
            margin: "10px",
            borderLeft: "1px solid black",
            marginRight: "10px",
            width: "200px",
            padding: "5px",
            fontSize: "12px",
          }}
        >
          <div>**Important Information**</div>
          <div>
            <div>
              To ensure the smooth operation of our clinic and provide you with
              the best dental care  kindly arrive
              10 minutes before your scheduled appointment ; please note, a
              15-minute late arrival will result in appointment cancellation and
              rescheduling. Your cooperation is appreciated. Thank you for your
              understanding and cooperation.
            </div>
            <div>
              ଆମର କ୍ଲିନିକ୍ର ସୁଗମ କାର୍ଯ୍ୟକୁ ନିଶ୍ଚିତ କରିବାକୁ ଏବଂ ଆପଣଙ୍କୁ ସର୍ବୋତ୍ତମ
              ଯତ୍ନ ଯୋଗାଇବାକୁ, ଦୟାକରି ଆପଣଙ୍କର ନିର୍ଦ୍ଧାରିତ ନିଯୁକ୍ତିର 10 ମିନିଟ୍
              ପୂର୍ବରୁ ପହଞ୍ଚନ୍ତୁ | ବିଳମ୍ବରେ ପହଞ୍ଚିବା: ଯଦି ଆପଣ 15 ମିନିଟ୍ ବିଳମ୍ବରେ
              ପହଞ୍ଚନ୍ତି, ତେବେ ଆପଣଙ୍କର appointment ଅନ୍ୟ ଏକ ତାରିଖ ଏବଂ ସମୟ ପାଇଁ ପୁନ
               ସ୍ଥିର ହେବ | ତୁମର ବୁ understanding ାମଣା ଏବଂ ସହଯୋଗ ପାଇଁ ଧନ୍ୟବାଦ
              |
            </div>
          </div>
        </div> */}
        {/* <div
          style={{
            height: "40vh",
            margin: "10px",
            borderLeft: "1px solid black",
            marginRight: "100px",
            padding: "5px",
            fontSize: "12px",
          }}
        >
          <div>BP :</div>
          <div style={{ marginTop: "20px" }}> Pregnancy :</div>

          <div>
            {" "}
            <div style={{ marginTop: "50px" }}>Systemic Diseases</div>
            Cardiac : <br />
            Pulmonary : <br />
            Thyroid : <br />
            Blood Sugar : <br />
            Other (if any :)
          </div>
          <div style={{ marginTop: "30px" }}> Drug Allergy :</div>
          <div style={{ marginTop: "30px" }}> PDH :</div>
          <div style={{ marginTop: "80px" }}> PMH :</div>
        </div> */}
      </div>

      <div
        style={{
          bottom: 0,
          position: "fixed",
          width: "100%",
          marginBottom: "10px",
          fontSize: "14px",
        }}
      >
        <div style={{ textAlign: "center", marginBottom: "10px" }}>
          {" "}
          FACILITIES AVAILABLE
        </div>{" "}
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <div>
            <div style={{ fontWeight: "bold", fontSize: "16px" }}>
              Implant Dentistry
            </div>
            <div>
              Full Mouth Implants
              <div>All on 4 /All on 6</div>
            </div>
            <div>Single tooth Implant</div>
            <div>Implant Supported Dentures</div>
          </div>
          <div>
            <div style={{ fontWeight: "bold", fontSize: "16px" }}>
              General Dentistry
            </div>
            <div>Root Canal Treatment(RCT)</div>
            <div>Extraction</div>
            <div>Third Molar Disimpaction (surgically)</div>
            <div>Dentures</div>
            <div>Teeth Cleaning & whitening</div>
            <div>Orthodontics</div>
            <div>Aligners</div>
            <div>Braces</div>
          </div>
          <div>
            <div style={{ fontWeight: "bold", fontSize: "16px" }}>
              Cosmetic Dentistry
            </div>
            <div>Smile Designing</div>
            <div>Veneers/Laminates</div>
            <div>Teeth color Fillings</div>
            <div style={{ marginTop: "20px" }}>And Many More ...</div>
          </div>
        </div>
        <div
          style={{
            height: "20px",
            fontWeight: "bold",
            textAlign: "center",
            backgroundColor: "grey",
            color: "white",
            padding: "10px",
            fontSize: "16px",
          }}
        >
          www.patradental.in
        </div>
      </div>
    </div>
  );
});
