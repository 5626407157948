import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import { Edit } from "@mui/icons-material";
import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import React, { useRef, useState } from "react";
import { db } from "../../firebaseConfig/firebase";

function UpdatePatient({ docid1,name1,age1,gender1,phoneNumber1 }) {
  //     const ptAccProfile = doc(db, "patientAccount", pDocId);
  //     await updateDoc(ptAccProfile, {
  //       price: price,
  //       paymentMode:modeofPayment,
  //       update: "updated",
  //     });
  //   }

  const [open, setOpen] = useState(false);
  const [date, setDate] = useState("");

  const [fName, setFname] = useState(name1);
  const [gender, setGender] = useState(gender1);

  const [phNumber, setPhNumber] = useState(phoneNumber1);
  const [age, setAge] = useState(age1);

  const saveButtonRef = useRef(null);

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      saveButtonRef.current.click();
    }
  };

  const genderHandleChange = (event) => {
    setGender(event.target.value);
    event.preventDefault();
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason && reason == "backdropClick") return;

    setOpen(false);
  };

  const addNewPtDB = async () => {
    const abc = fName + " " + phNumber;
    const newSplit = abc.split(" ");
    const newArray = [fName.toLowerCase()];

    for (let i = 0; i < newSplit.length; i++) {
      for (let y = 1; y < newSplit[i].length + 1; y++) {
        newArray.push(newSplit[i].substring(0, y).toLowerCase());
      }
    }

    

    const ptProfile = doc(db, "patientProfile",docid1);
    await updateDoc(ptProfile, {
      name: fName,
      namearray: newArray,
      phNumber: phNumber,

      gender: gender,
      age: age,
      
    });

    // await addDoc(collection(db, "patientProfile"), profileData);

    setFname();

    setPhNumber();
    setAge();
    setGender();

    handleClose();
  };

  return (
    <div>
      <Button
        onClick={handleClickOpen}
        style={{ fontSize: "16px", width: "100%" }}
      >
      
            <Edit style={{ fontSize: 16 }} />
            Edit
         
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            borderRadius: "15px",
          },
        }}
      >
        <DialogTitle>update Patient</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>New Patient Registration</DialogContentText> */}
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            fullWidth
            variant="standard"
            defaultValue={name1}
            onChange={(event) => {
              const { value } = event.target;
              setFname(value);
            }}
            required
            onKeyDown={handleKeyDown}
          />
          <TextField
            id="phNumber"
            label="WhatsApp Number"
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            fullWidth
            variant="standard"
            defaultValue={phoneNumber1}
            onChange={(event) => {
              const { value } = event.target;
              setPhNumber(value);
            }}
            required
            onKeyDown={handleKeyDown}
          />
          <FormControl>
            <FormLabel
              id="demo-controlled-radio-buttons-group"
              style={{ marginBottom: "10px", marginTop: "10px" }}
            >
              Gender
            </FormLabel>

            <RadioGroup
              style={{ marginLeft: "10px" }}
              row
              name="row-radio-buttons-group"
              aria-labelledby="demo-controlled-radio-buttons-group"
              value={gender}
              onChange={genderHandleChange}
            >
              <FormControlLabel value="Male" control={<Radio />} label="Male" />
              <FormControlLabel
                value="Female"
                control={<Radio />}
                label="Female"
              />
            </RadioGroup>
          </FormControl>

          <TextField
            margin="dense"
            id="age"
            label="Age"
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            fullWidth
            variant="standard"
            defaultValue={age1}
            onChange={(event) => {
              const { value } = event.target;
              setAge(value);
            }}
            required
            onKeyDown={handleKeyDown}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} style={{ color: "red" }}>
            Cancel
          </Button>
          <Button
            ref={saveButtonRef}
            onClick={addNewPtDB}
            style={{
              fontSize: "16px",
              // fontWeight: "bold",
              // border: "1px solid ",
              padding: "3px",
              borderRadius: "5px",
              backgroundColor: "rgb(8,10,48,0.9)",
              color: "white",
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default UpdatePatient;
