import React, { useEffect, useState } from "react";

import { ListItem, TextField } from "@material-ui/core";
import {
  Delete,
  DeleteOutline,
  Edit,
  InsertEmoticon,
} from "@mui/icons-material";
import IconButton from "@material-ui/core/IconButton";
import { useLocation, useNavigate } from "react-router-dom";
import {
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../firebaseConfig/firebase";
//

import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Stack from "@mui/material/Stack";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { WhatsApp, Message } from "@mui/icons-material";

import {
  LeadingActions,
  SwipeableList,
  SwipeableListItem,
  SwipeAction,
  TrailingActions,
} from "react-swipeable-list";
import "react-swipeable-list/dist/styles.css";
import axios from "axios";
import Reschedule from "./appointment/Reschedule";

const token = process.env.REACT_APP_API_KEYTOKEN;
const mytoken = process.env.REACT_APP_API_KEYMYTOKEN;
const phidtoken = process.env.REACT_APP_API_KEYphidtoken;

function Calender() {
  const [open, setOpen] = useState(false);
  const [opdidC, setOpdId] = useState("");

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseYES = async () => {
    setShowAlert(true);
    await deleteDoc(doc(db, "opdEntry", opdidC));

    setTimeout(() => setShowAlert(false), 2000);

    setOpen(false);
  };

  const [date, setDate] = useState(new Date(Date.now()));
  let datew = JSON.stringify(date);
  datew = datew.slice(1, 11);

  let navigate = useNavigate();
  const state = useLocation();

  const [inputText, setInputText] = useState("");
  const [data, setData] = useState([]);
  const [messageSent, setMessageSent] = useState(false);

  const leadingActions = () => (
    <LeadingActions>
      <SwipeAction onClick={() => ""}>
        <IconButton style={{ color: "rgb(5, 2, 43)", marginRight: "20px" }}>
          <Edit />
        </IconButton>
      </SwipeAction>
    </LeadingActions>
  );

  const trailingActions = (opdID, opdPtName) => (
    <TrailingActions>
      <SwipeAction
        style={{ color: "red" }}
        onClick={async () => {
          setOpen(true);
          setOpdId(opdID);

          //           setShowAlert(true)
          //             await deleteDoc(doc(db, "opdEntry", opdID));

          // setTimeout(()=>setShowAlert(false),2000)
        }}
      >
        <IconButton style={{ color: "red", marginRight: "20px" }}>
          <Delete />
        </IconButton>
      </SwipeAction>
    </TrailingActions>
  );

  useEffect(() => {
    const appointmentData = collection(db, "AppointmentDetails");

    //mounts

    const qd = query(
      appointmentData,
      where("date", "==", datew),
      orderBy("time", "asc")
    );

    const unsub = onSnapshot(qd, (snap) => {
      let newArray = [];

      snap.forEach((doc) => {
        newArray.push({
          ...doc.data(),

          id: doc.get("patientId"),

          ptName: doc.get("name"),
          
          docid: doc.id,
          timeString: doc.get("timeString"),

          phoneNumber: doc.get("phNumber"),
          duration: doc.get("duration"),
          reason: doc.get("reason"),
          appointmentDate: doc.get("date"),
          appointmentTime: doc.get("time"),

          created: doc.get("created"),
        });
      });

      console.log(newArray);

      setData(newArray);
    });

    return () => {
      unsub();
    };
  }, [datew]);

  const [showAlert, setShowAlert] = useState(false);

  const sendMessage = (name, phNumber, date, time) => {
    // appointmentonday
    const militaryTime = time;
    const hours = Math.floor(militaryTime / 100);
    const minutes = militaryTime % 100;
    const timeString = `${hours % 12 || 12}:${minutes
      .toString()
      .padStart(2, "0")} ${hours < 12 ? "AM" : "PM"}`;

    let originalDate = date;
    let splitDate = originalDate.split("-");
    let convertedDate = splitDate[2] + "-" + splitDate[1] + "-" + splitDate[0];

    axios({
      method: "POST",
      url:
        "https://graph.facebook.com/v15.0/" +
        phidtoken +
        "/messages?access_token=" +
        token,
      data: {
        messaging_product: "whatsapp",
        recipient_type: "individual",
        to: 91 + phNumber,
        type: "template",
        template: {
          name: "appointment_today_reminder_yes_no_2",
          language: {
            code: "en_US",
          },
          components: [
            {
              type: "body",
              parameters: [
                {
                  type: "text",
                  text: name + " !",
                },
                {
                  type: "text",
                  text: convertedDate,
                },
                {
                  type: "text",
                  text: timeString,
                },
              ],
            },

            {
              type: "button",
              sub_type: "quick_reply",
              index: "0",
              parameters: [
                {
                  type: "payload",
                  payload: "yes i will come",
                },
              ],
            },
            {
              type: "button",
              sub_type: "quick_reply",
              index: "1",
              parameters: [
                {
                  type: "payload",
                  payload: "i want to reschedule",
                },
              ],
            },
          ],
        },
      },

      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        console.log(response.data);
        setShowAlert(true);

        setTimeout(() => {
          setShowAlert(false);
        }, 2500);
      })
      .catch((error) => {
        setMessageSent(true);

        setTimeout(() => {
          setMessageSent(false);
        }, 2500);

        if (error.response && error.response.status) {
          console.error("Error status:", error.response.status);
        } else {
          console.error(error);
        }
      });

    console.log(name, phNumber, convertedDate, timeString);
  };

  return (
    <div>
      <div>
        {messageSent == false ? (
          showAlert == true ? (
            <Stack
              sx={{ width: "100%", position: "absolute", zIndex: "999" }}
              spacing={2}
            >
              <Alert severity="success">
                <AlertTitle>Message Sent </AlertTitle>
                Message Sent <strong>Successfully</strong>
              </Alert>
            </Stack>
          ) : (
            <div></div>
          )
        ) : (
          <div>
            <Stack
              sx={{ width: "100%", position: "absolute", zIndex: "999" }}
              spacing={2}
            >
              <Alert severity="error">
                <AlertTitle>ERROR</AlertTitle>
                Message not delivered
              </Alert>
            </Stack>{" "}
            :<div></div>
          </div>
        )}
        <div
          className="topSec"
          style={{
            backgroundColor: "rgb(5, 2, 43)",
            // borderRadius: "30px",
            height: "5vh",
            // margin: "10px",
            color: "white",
            padding: "15px",
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <div style={{ fontSize: "12px", color: "grey" }}>
            Date
            <div style={{ fontSize: "16px", color: "white" }}>{datew}</div>
          </div>
          <div style={{ fontWeight: "bolder", fontSize: "18px" }}>
            Appointment
          </div>

          <div>
            <TextField
              style={{
                color: "red",
                backgroundColor: "white",
                border: "1px solid white",
                borderRadius: "15px",
                width: "100px",
                margin: "-5px",
              }}
              id="date"
              label="Choose Date"
              type="date"
              fullWidth
              defaultValue={Date.now()}
              onChange={(event) => setDate(event.target.value)}
              // sx={{ width: "100%" }}
            />
          </div>
        </div>{" "}
        <div
          className="midSec"
          style={{
            height: "80vh",

            backgroundColor: "white",
            // borderRadius: "30px",
            borderTop: "6px solid rgb(231, 233, 251)",
          }}
        >
          <div
            className="component"
            style={{
              overflow: "scroll",
              height: "75vh",
              width: "100%",
              overflowX: "hidden",
            }}
          >
            {data.map((item) => (
              <div>
                <div
                  style={{
                    margin: "5px",
                    borderBottom: "1px dotted grey",
                    paddingBottom: "8px",

                    // height: "70px",
                  }}
                >
                  <div
                    style={{
                      width: "98%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{}}
                      onClick={() => {
                        navigate("/profile", {
                          state: {
                            docid: item.docid,
                            id: item.id,
                            ptid: item.id,
                            name: item.ptName,

                            phoneNumber: item.phoneNumber,
                          },
                        });
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <div
                            style={{
                              fontSize: "16px",
                              fontWeight: "bold",
                              margin: "8px",
                            }}
                          >
                            <div style={{ color: "grey" }}>
                              {" "}
                              {item.timeString}
                            </div>

                            <div style={{ fontSize: "12px" }}>
                              {" "}
                              {item.duration}{" "}
                            </div>
                          </div>
                          <div
                            style={{
                              fontSize: "16px",
                              fontWeight: "bold",
                              margin: "8px",
                            }}
                          >
                            {item.ptName}
                          </div>
                          <div style={{ fontSize: "12px", margin: "8px" }}>
                            {item.reason}
                          </div>
                          <div style={{ fontSize: "12px", margin: "8px" }}>
                            <a
                              href={`tel: ${item.phoneNumber}`}
                              style={{
                                color: "rgb(32, 84, 131)",
                                textDecoration: "none",
                              }}
                            >
                              {" "}
                              <p
                                style={{
                                  fontSize: "14px",

                                  marginRight: "10px",
                                }}
                              >
                                {item.phoneNumber}
                              </p>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div style={{ color: "grey", fontSize: "08px" }}>
                      <Button
                        color="primary"
                        variant="contained"
                        style={{
                          backgroundColor: "rgba(12, 17, 52, 0.973)",
                          borderRadius: "5px",
                          color: "white",
                          padding: "8px",
                          marginTop: "5px",
                          fontSize: "10px",
                          display: "flex",
                        }}
                        fullWidth
                        onClick={() => {
                          navigate("/updateAppointment", {
                            state: {
                              docid: item.docid,

                              name: item.ptName,
                              time: item.timeString,
                              date:item.date,
                              duration: item.duration,
                              phoneNumber: item.phoneNumber,
                              reason: item.reason,
                            },
                          });
                        }}
                      >
                        Reschedule
                      </Button>

                      <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        style={{
                          backgroundColor: "rgba(12, 17, 52, 0.973)",
                          borderRadius: "5px",
                          color: "white",
                          padding: "8px",
                          marginTop: "5px",
                          display: "flex",
                        }}
                        fullWidth
                        onClick={() => {
                          sendMessage(
                            item.ptName,
                            item.phoneNumber,
                            item.appointmentDate,
                            item.appointmentTime
                          );
                        }}
                      >
                        <WhatsApp></WhatsApp>
                        Send
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          position: "fixed",
          bottom: "0px",
          alignItem: "center",
          justifyContent: "center",

          marginBottom: "90px",
          width: "100%",
          zIndex: "999",
        }}
      >
        <div
          style={{
            color: "white",
            backgroundColor: "rgba(12, 17, 52, 0.973)",
            padding: "12px",
            borderRadius: "15px",
          }}
          onClick={() => {
            navigate("/appointment", {
              state: {
                id: "1",
              },
            });
          }}
        >
          Appointment
        </div>
      </div>

      {/* <Bottombar/> */}
    </div>
  );
}

export default Calender;
