import React, { useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import FormControl from "@mui/material/FormControl";

import {
  addDoc,
  collection,
  query,
  onSnapshot,
  doc,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebaseConfig/firebase";

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";

import Select from "@mui/material/Select";

function AddNewTretment(props) {
  const [open, setOpen] = useState(false);
  const [price, setprice] = useState("");
  const [qty, setQty] = useState();
  const [chiefComplaint, setChiefComplaint] = useState();

  const [treatmentName, setTreatmentName] = useState();
  const [newprice, setNewprice] = useState("");
  const [newtreatmentName, setnewTreatmentName] = useState();

  const saveButtonRef = useRef(null);

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      saveButtonRef.current.click();
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };




  const handleClose = (event, reason) => {

    if (reason && reason == "backdropClick") 
    return;


    setTreatmentName();
    setQty();
    setprice();
    setChiefComplaint();
    setNewprice();
    setnewTreatmentName();
    setOpen(false);
  };

  useEffect(() => {
    if (treatmentName === "RCT") {
      setprice(3000 * qty);
    } else if (treatmentName === "Extraction") {
      setprice(600 * qty);
    } else if (treatmentName === "OPD") {
      setprice(100 * qty);
    } else if (treatmentName === "3rd Molar") {
      setprice(1500 * qty);
    } else if (treatmentName === "Filling") {
      setprice(600 * qty);
    } else if (treatmentName === "Composite") {
      setprice(1000 * qty);
    } else if (treatmentName === "Composite3M") {
      setprice(1500 * qty);
    } else if (treatmentName === "Ortho") {
      setprice(22000 * qty);
    } else if (treatmentName === "Scaling") {
      setprice(1000 * qty);
    } else if (treatmentName === "Capping(M C)") {
      setprice(3500 * qty);
    } else if (treatmentName === "Capping(M C-IVO)") {
      setprice(4500 * qty);
    } else if (treatmentName === "Capping(Zirconia)") {
      setprice(8000 * qty);
    } else if (treatmentName === "CD") {
      setprice(12000 * qty);
    } else if (treatmentName === "RPD") {
      setprice(400 * qty);
    } else if (treatmentName === "Implants") {
      setprice(20000 * qty);
    } else if (treatmentName === "Surgery") {
      setprice(10000 * qty);
    } else {
      setprice(newprice * qty);
      setTreatmentName(newtreatmentName);
    }
  }, [qty]);

  const addNewTreatment = async () => {
    const treatmentData = {
      patientId: props.id,
      treatmentName: treatmentName,
      created: serverTimestamp(),
      price: price,
      qty: qty,
      chiefComplaint: chiefComplaint,
      paid: 0,
 rctCompletion:0
    };
    await addDoc(collection(db, "treatment"), treatmentData);
    const ptProfile = doc(db, "patientProfile", props.id);

    setTreatmentName();
    setQty();
    setprice();
    setChiefComplaint();
    setNewprice();
    setnewTreatmentName();
    handleClose();
  };

  const handleChange = (event) => {
    setTreatmentName(event.target.value);
  };


  return (
    <div>
      <Button
        onClick={handleClickOpen}
        style={{
          lineHeight: "30px",
          width: "26px",
          fontSize: "36px",
          fontWeight: "bolder",
          borderRadius: "50%",
          border: "6px solid  rgb(5, 2, 43)",
          textAlign: " center",
          padding: "8px",
          backgroundColor: "rgb(5, 2, 43)",
          color: "white",
        }}
      >
        +
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
       
        PaperProps={{
          style: {
            borderRadius: "15px",
          },
        }}
      >
        <DialogTitle>Start Treatment</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            id="Chief Complaint"
            label="Chief Complaint"
            fullWidth
            variant="standard"
            onChange={(event) => {
              const { value } = event.target;
              setChiefComplaint(value);
            }}
            autoFocus
            required
            onKeyDown={handleKeyDown}
          />
          <select
            value={treatmentName}
            label="Treatment"
            onChange={handleChange}
            style={{
              height: "40px",
              color: "rgb(5, 2, 43)",
              fontSize: "18px",
              // width:"100%",
              marginTop: "15px",
            }}
            onKeyDown={handleKeyDown}
          >
            <option value="start">Select treatment</option>

            <option value="OPD">OPD</option>
            <option value="RCT">RCT</option>
            <option value="Extraction">Extraction</option>
            <option value="3rd Molar">3rd Molar Extraction </option>
            <option value={"Filling"}>Filling</option>
            <option value={"Composite"}>Composite</option>
            <option value={"Composite3M"}>Composite 3M</option>
            <option value={"Ortho"}>Ortho</option>
            <option value={"Scaling"}>Scaling</option>
            <option value={"Capping(M C)"}>Capping(MetalCeramic)</option>
            <option value={"Capping(M C-IVO)"}>
              Capping(Metal Ceramic-IVO)
            </option>
            <option value={"Capping(Zirconia)"}>Capping(Zirconia)</option>
            <option value={"CD"}>CD</option>
            <option value={"RPD"}>RPD</option>
            <option value={"Implants"}>Implants</option>

            <option value={"Surgery"}>Surgery</option>
            <option value={"Other"}>Other</option>
          </select>

          <div
            style={
              treatmentName == "Other"
                ? { display: "inline" }
                : { display: "none" }
            }
          >
            <TextField
              margin="dense"
              id="Treatmentnew Name"
              label="Treatment Name"
              fullWidth
              variant="standard"
              onChange={(event) => {
                const { value } = event.target;
                if (treatmentName == "Other") {
                  setnewTreatmentName(value);
                }
              }}
            />
            <TextField
              id="new Price"
              style={{ width: "100%" }}
              label="New Price"
              inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
              variant="standard"
              onChange={(event) => {
                const { value } = event.target;
                setNewprice(value);
              }}
            />
          </div>
          <TextField
            id="Total tooth"
            style={{ width: "100%" }}
            label="How Many Tooth"
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            variant="standard"
            onChange={(event) => {
              const { value } = event.target;
              setQty(value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} style={{ color: "red" }}>
            Cancel
          </Button>
          <Button
            ref={saveButtonRef}
            onClick={addNewTreatment}
            style={{
              fontSize: "16px",
              // fontWeight: "bold",
              // border: "1px solid ",
              padding: "3px",
              borderRadius: "5px",
              backgroundColor: "rgb(8,10,48,0.9)",
              color: "white",
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AddNewTretment;
