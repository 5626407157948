import React from "react";
import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import FormControl from "@mui/material/FormControl";

import {
  addDoc,
  collection,
  query,
  onSnapshot,
  doc,
  serverTimestamp,
  updateDoc,
  where,
  orderBy,
} from "firebase/firestore";
import { db } from "../../firebaseConfig/firebase";

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";

import Select from "@mui/material/Select";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";

function OpdPay(props) {
  const [open, setOpen] = useState(false);
  const [price, setprice] = useState("");
  const [askPrice, setaskPrice] = useState("");
  const [pDocId, setPDocId] = useState("");
  const [modeofPayment, setModeofPayment] = useState('CASH');

  const [treatmentName, setTreatmentName] = useState();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    setModeofPayment()
    setOpen(false);
  };

  const addAskPrice = async()=>{
    const ptProfile = doc(db, "opdEntry", props.opdId);
    await updateDoc(ptProfile, {
      askPrice: askPrice,
     
    });


    setaskPrice();
    
  
    handleClose();
  }

  const addNewTreatment = async () => {
    const patientAccount = {
      patientId: props.id,
      opdId: props.opdId,

      created: serverTimestamp(),
      price: price,
    };

    const ptProfile = doc(db, "opdEntry", props.opdId);
    await updateDoc(ptProfile, {
      opdPay: price,
      paymentMode:modeofPayment,
      status: "approved",
    });

    if (props.status === "approved") {
      const opdData = collection(db, "patientAccount");
      const qd = query(opdData, where("opdId", "==", props.opdId));
      let newArray = [];

      const unsub = onSnapshot(qd, (snap) => {
        snap.forEach(async (doc) => {
          newArray.push({
            ...doc.data(),

            docid: doc.id,

            opdId: doc.get("opdId"),
          });
        });
        setPDocId(newArray[0].docid);
        console.log(newArray[0].docid);
      });
      const ptAccProfile = doc(db, "patientAccount", pDocId);
      await updateDoc(ptAccProfile, {
        price: price,
        paymentMode:modeofPayment,
        update: "updated",
      });
    } else {
      addDoc(collection(db, "patientAccount"), patientAccount);
    }

    setTreatmentName();

    setprice();
    setPDocId();
    setModeofPayment()
    handleClose();
  };

  const modeOfPaymentHandleChange = (event) => {
    setModeofPayment(event.target.value);
    event.preventDefault()
  };

  return (
    <div>
      {props.type === "ASK" ? (
        <div>
          {" "}
          <Button
            onClick={handleClickOpen}
            style={{
              // lineHeight: "30px",
              // width: "26px",
              fontSize: "12px",
              fontWeight: "bold",
              borderRadius: "15px",
              // border: "1px solid  rgb(5, 2, 43)",
              textAlign: " center",
              padding: "2px",
              backgroundColor: "rgba(156, 153, 75)",
              color: "white",
            }}
          >
            {<div>ASK</div>}
          </Button>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>FEES ASK</DialogTitle>
            <DialogContent>
              <Box sx={{ minWidth: 250 }}>
                <FormControl fullWidth>
                  <TextField
                    id="Total tooth"
                    style={{ width: "90%" }}
                    label="Ask Price"
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                    variant="standard"
                    onChange={(event) => {
                      const { value } = event.target;
                      setaskPrice(value);
                    }}
                  />
                </FormControl>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} style={{ color: "red" }}>
                Cancel
              </Button>
              <Button
                onClick={addAskPrice}
                style={{
                  fontSize: "16px",
                  // fontWeight: "bold",
                  // border: "1px solid ",
                  // padding: "3px",
                  // borderRadius: "5px",
                  backgroundColor: "rgb(156, 153, 75)",
                  color: "white",
                }}
              >
               { "<  ASK"}
              </Button>
            </DialogActions>
          </Dialog>{" "}
        </div>
      ) : (
        <div>
          <Button
            onClick={handleClickOpen}
            style={{
              // lineHeight: "30px",
              // width: "26px",
              fontSize: "12px",
              fontWeight: "bold",
              borderRadius: "15px",
              border: "1px solid  rgb(5, 2, 43)",
              textAlign: " center",
              padding: "2px",
              backgroundColor: "rgba(12, 17, 52, 0.888)",
              color: "white",
            }}
          >
            {props.status === "approved" ? <div>Update</div> : <div>Pay</div>}
          </Button>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>FEES PAID</DialogTitle>
            <DialogContent>
              <Box sx={{ minWidth: 250 }}>
                <FormControl fullWidth>
                  <TextField
                    id="Total tooth"
                    style={{ width: "90%" }}
                    label="Fees Paid"
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                    variant="standard"
                    onChange={(event) => {
                      const { value } = event.target;
                      setprice(value);
                    }}
                  />


<RadioGroup

style={{marginLeft:'10px'}}


        row
     
        name="row-radio-buttons-group"
    

        aria-labelledby="demo-controlled-radio-buttons-group"
     
        value={modeofPayment}
        onChange={modeOfPaymentHandleChange}
      >
        <FormControlLabel value="CASH" control={<Radio />} label="CASH" />
        <FormControlLabel value="G-PAY" control={<Radio />} label="G-PAY" />
      </RadioGroup>
                </FormControl>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} style={{ color: "red" }}>
                Cancel
              </Button>
              <Button
                onClick={addNewTreatment}
                style={{
                  fontSize: "16px",
                  // fontWeight: "bold",
                  // border: "1px solid ",
                  // padding: "3px",
                  // borderRadius: "5px",
                  backgroundColor: "rgb(8,10,48,0.9)",
                  color: "white",
                }}
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>{" "}
        </div>
      )}
    </div>
  );
}

export default OpdPay;
