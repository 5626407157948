import { TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import "./cheifc.css";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useNavigate } from "react-router-dom";
import { doc, getDoc, onSnapshot, updateDoc } from "firebase/firestore";
import { db } from "../../../../../firebaseConfig/firebase";
function Investigatoin( { onNext,tId  }) {
  const [investigationData, setInvestigationData] = useState("");
  const [buttonClicked, setButtonClicked] = useState(false);

  const [examData, setOnExamData] = useState([]);


  const handleComplaintClick = (complaint) => {
    if (investigationData.includes(complaint)) {
      setInvestigationData((prevComplaint) =>
        prevComplaint.replace(new RegExp(complaint, "g"), "")
      );
      setButtonClicked((prevClicked) => ({
        ...prevClicked,
        [complaint]: false,
      }));
    } else {
      setInvestigationData((prevComplaint) => prevComplaint + complaint);
      setButtonClicked((prevClicked) => ({
        ...prevClicked,
        [complaint]: true,
      }));
    }
  };


  const handleClickNext = async() => {
  

    const pushOnExam = doc(db, "treatment", tId);
    try {
      const docSnapshot = await getDoc(pushOnExam);
      if (docSnapshot.exists()) {
        const existingData = docSnapshot.data();
       
        const updatedArray = investigationData;

        await updateDoc(pushOnExam, {
          investigation: updatedArray,
        });

        console.log("New array data pushed successfully!");

      } else {
        console.log("Document does not exist.");
      }
    } catch (error) {
      console.error("Error pushing new array data: ", error);
    }
    

    console.log('====================================');
    console.log(investigationData);
    console.log('====================================');
   
    onNext();

  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        const pushOnExam = doc(db, "treatment", tId);
        const unsubscribe = onSnapshot(pushOnExam, (doc) => {
          if (doc.exists()) {
            const data = doc.data();
            // console.log("Document data:", data);
            setOnExamData([data]);
            console.log("Document datadd:", examData);

          } else {
            console.log("No such document!");
          }
        });
        return unsubscribe;
      } catch (error) {
        console.error("Error fetching document:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <div style={{ overflowY:'scroll', height:'90vh'}}>
      <div  >
        <div style={{ margin: "5px", fontSize: "16px", fontWeight: "bold" }}>
          Investigation
        </div>

        <div >
        <div style={{ marginTop: "15px" }}>
          <TextField
            id="outlined-basic"
            label="Investigation"
            variant="outlined"
            fullWidth
            value={investigationData}
            onChange={(e) => setInvestigationData(e.target.value)}
          />
        </div>
        <div>
          <button
            className={`buttonclass ${
              buttonClicked[" IOPAR "] && "clicked"
            }`}
            onClick={() => handleComplaintClick(" IOPAR ")}
          >
            IOPAR
          </button>

       
          <div>
          <button
    className={`buttonclass ${buttonClicked[" OPG "] && "clicked"}`}
    onClick={() => handleComplaintClick(" OPG ")}
  >
    OPG
  </button>
  <button
    className={`buttonclass ${buttonClicked[" CBCT  "] && "clicked"}`}
    onClick={() => handleComplaintClick(" CBCT  ")}
  >
    CBCT 
  </button>
  <button
    className={`buttonclass ${buttonClicked[" FBS "] && "clicked"}`}
    onClick={() => handleComplaintClick(" FBS ")}
  >
    FBS
  </button>
  <button
    className={`buttonclass ${buttonClicked[" PPBS "] && "clicked"}`}
    onClick={() => handleComplaintClick(" PPBS ")}
  >
    PPBS
  </button>
  <button
    className={`buttonclass ${buttonClicked[" HBA1c "] && "clicked"}`}
    onClick={() => handleComplaintClick(" HBA1c ")}
  >
    HBA1c
  </button>
  

<div style={{width:'100%'}}>

      <button
          style={{
            width: "90%",
            borderRadius: "20px",
            padding: "10px ",
            fontSize: "14px",
            fontWeight: "bold",
            backgroundColor: "#3a7a91",
            margin: "10px",
            color: "white",
            border: "none",
            display:'flex',
            alignItems:'center',
            justifyContent:'center'
          }}
          onClick={handleClickNext}
        >
        
              Save
        <div>

           
        </div>
       
        </button>
</div>
    </div>
    </div>

    {examData.map((item, index) => (
            <div key={index} style={{ width: "100%" }}>
              <div>
            {item.investigation}
              </div>
            </div>
          ))}
    </div>
    </div>
    </div>
  );
}

export default Investigatoin;