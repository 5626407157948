import React, { useEffect, useState } from "react";
import StartNewOpd from "./StartNewOpd";
import "./opd.css";

import { InputAdornment, TextField } from "@material-ui/core";
import {
  CalendarMonth,
  Delete,
  DeleteOutline,
  Edit,
  SignalWifiStatusbarNullSharp,
} from "@mui/icons-material";
import IconButton from "@material-ui/core/IconButton";
import { useLocation, useNavigate } from "react-router-dom";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  Timestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../../firebaseConfig/firebase";
import Bottombar from "../Bottombar";
import OpdPay from "./OpdPay";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Stack from "@mui/material/Stack";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import {
  LeadingActions,
  SwipeableList,
  SwipeableListItem,
  SwipeAction,
  TrailingActions,
} from "react-swipeable-list";
import "react-swipeable-list/dist/styles.css";
import { async } from "@firebase/util";
function Opd() {
  const [open, setOpen] = useState(false);
  const [opdidC, setOpdId] = useState("");

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseYES = async () => {
    setShowAlert(true);
    await deleteDoc(doc(db, "opdEntry", opdidC));

    setTimeout(() => setShowAlert(false), 2000);

    setOpen(false);
  };

  const [date, setDate] = useState(new Date(Date.now()));
  let datew = JSON.stringify(date);
  datew = datew.slice(1, 11);

  let navigate = useNavigate();
  const state = useLocation();

  const [inputText, setInputText] = useState("");
  const [data, setData] = useState([]);
  const [price, setprice] = useState("");
  const [newPtCount, setNewPtCount] = useState("0");
  const [oldPtCount, setOldPtCount] = useState("0");
  const [settledAmount, setettledAmount] = useState("0");

  const leadingActions = (
    id,
    opdID,
    opdPay,
    created,
    name,
    statuss,
    paymentMode
  ) => (
    <LeadingActions>
      <SwipeAction
        onClick={async () => {
          if (statuss == "approved") {
            alert("no");
          } else {
            // alert (statuss)

            const patientAccount = {
              patientId: id,
              opdId: opdID,
              paymentMode: paymentMode,
              created: created,
              price: opdPay,
            };
            const ptProfile = doc(db, "opdEntry", opdID);

            //   const ptAccProfile = doc(db, "patientAccount", newArray[0].docid);
            //   updateDoc(ptAccProfile, {

            //     price: price,
            //     update:"updated"
            // });

            addDoc(collection(db, "patientAccount"), patientAccount);

            await updateDoc(ptProfile, {
              status: "approved",
            });
          }
        }}
      >
        <IconButton style={{ color: "rgb(5, 2, 43)", marginRight: "20px" }}>
          <Edit />
        </IconButton>
      </SwipeAction>
    </LeadingActions>
  );

  const trailingActions = (opdID, opdPtName) => (
    <TrailingActions>
      <SwipeAction
        style={{ color: "red" }}
        onClick={async () => {
          setOpen(true);
          setOpdId(opdID);

          //           setShowAlert(true)
          //             await deleteDoc(doc(db, "opdEntry", opdID));

          // setTimeout(()=>setShowAlert(false),2000)
        }}
      >
        <IconButton style={{ color: "red", marginRight: "20px" }}>
          <Delete />
        </IconButton>
      </SwipeAction>
    </TrailingActions>
  );

  useEffect(() => {
    const opdData = collection(db, "opdEntry");
    const abc = inputText.toLowerCase();
    //mounts

    const qd = query(
      opdData,
      where("date", "==", datew),
      orderBy("created", "desc")
    );

    const unsub = onSnapshot(qd, (snap) => {
      let newArray = [];

      snap.forEach((doc) => {
        newArray.push({
          ...doc.data(),

          opdId: doc.id,

          id: doc.get("ptId"),
          name: doc.get("ptName"),
          opdPay: doc.get("opdPay"),
          phoneNumber: doc.get("phNumber"),
          paymentMode: doc.get("paymentMode"),
          gender: doc.get("gender"),
          askPrice: doc.get("askPrice"),
          tokenNumber: doc.get("tokenNumber"),
         
          date: doc.get("date"),
          age: doc.get("age"),
          ptCreatedDate: doc.get("ptCreatedDate"),
          totalPaid: doc.get("totalPaid"),
          TotalAmount: doc.get("TotalAmount"),
          status: doc.get("status"),
          created: doc.get("created"),
        });
      });

      console.log(newArray);

      setData(newArray);
    });

    return () => {
      unsub();
    };
  }, [datew]);

  const [showAlert, setShowAlert] = useState(false);

  const total = data
    .filter((item) => item.status === "approved")
    .map((item) => parseInt(item.opdPay) || 0)
    .reduce((prev, curr) => prev + curr, 0);

  const cashPayment = data
    .filter((item) => item.status === "approved" && item.paymentMode === "CASH")
    .map((item) => parseInt(item.opdPay) || 0)
    .reduce((prev, curr) => prev + curr, 0);

  const onlinePaymentSettled = total - cashPayment;

  const pendingAmount = data
    .filter((item) => item.status !== "approved")
    .map((item) => parseInt(item.opdPay) || 0)
    .reduce((prev, curr) => prev + curr, 0);

  const cashPaymentPending = data
    .filter((item) => item.status !== "approved" && item.paymentMode === "CASH")
    .map((item) => parseInt(item.opdPay) || 0)
    .reduce((prev, curr) => prev + curr, 0);

  const onlinePaymentPending = pendingAmount - cashPaymentPending;

  return (
    <div>
      <div>
        {showAlert == true ? (
          <Stack
            sx={{ width: "100%", position: "absolute", zIndex: "999" }}
            spacing={2}
          >
            <Alert severity="error">
              <AlertTitle>Deleted</AlertTitle>
              Patient Deleted from — <strong>OPD</strong>
            </Alert>
          </Stack>
        ) : (
          <div></div>
        )}
        <div
          className="topSec"
          style={{
            backgroundColor: "rgb(5, 2, 43)",
            // borderRadius: "30px",
            height: "13vh",
            // margin: "10px",
            color: "white",
            padding: "15px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <div style={{ fontSize: "12px", color: "grey" }}>
              Date
              <div style={{ fontSize: "14px", color: "white" }}>{datew}</div>
            </div>
            <div>
              <div
                style={{
                  fontWeight: "bolder",
                  fontSize: "18px",
                  marginLeft: "5px",
                }}
              >
                OPD
              </div>
              {/* <div style={{ fontSize: "16px", color: "green", fontWeight:'bold' }}> ₹{total}</div> */}
            </div>

            <div>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CalendarMonth />
                    </InputAdornment>
                  ),
                }}
                style={{
                  // color: "red",
                  backgroundColor: "white",
                  border: "1px solid white",
                  borderRadius: "10px",
                  width: "80px",
                  fontSize: "6px",

                  // height:'30px',
                  margin: "5px",
                  // textAlign:'center'
                }}
                id="date"
                type="date"
                fullWidth
                defaultValue={Date.now()}
                onChange={(event) => setDate(event.target.value)}

                // sx={{ width: "100%" }}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              marginTop: "15px",
            }}
          >
            <div style={{ textAlign: "center" }}>
              <div style={{ color: "white", fontSize: "12px" }}>
                Total Patients
              </div>
              <div style={{ fontSize: "20px" }}>{data.length}</div>
            </div>{" "}
            <span
              style={{ borderRight: "1px solid grey", height: "30px" }}
            ></span>
            <div style={{ textAlign: "center" }}>
              <div style={{ color: "white", fontSize: "14px" }}>
                New Patients
              </div>
              <div style={{ fontSize: "20px" }}>
                {data.filter((item) => item.ptCreatedDate === datew).length}
              </div>
            </div>{" "}
            <span
              style={{ borderRight: "1px solid grey", height: "30px" }}
            ></span>
            <div style={{ textAlign: "center" }}>
              <div style={{ color: "white", fontSize: "14px" }}>
                Old Patients
              </div>
              <div style={{ fontSize: "20px" }}>
                {data.length -
                  data.filter((item) => item.ptCreatedDate === datew).length}
              </div>
            </div>{" "}
          </div>
        </div>{" "}
        <div
          className="midSec"
          style={{
            height: "80vh",

            backgroundColor: "#ebeef2",
            // borderRadius: "30px",
            borderTop: "6px solid rgb(231, 233, 251)",
          }}
        >
          <div className="moneydisply"
            style={{
              // display: "flex",
              justifyContent: "center",
              // backgroundColor:'red'
            }}
          >
            <div
              style={{
                width: "100%",
                height: "40px",

                fontSize: "16px",
                borderRadius: "10px",
                backgroundColor: "white",
                color: "green",

                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                // marginTop:'20px',
              }}
            >
              <div style={{ color: "grey" }}> Settled :₹ {total}</div>
              <div> Cash: {cashPayment}</div>
              <div> Online: {onlinePaymentSettled}</div>
            </div>
            <div
              style={{
                width: "100%",
                height: "20x",

                fontSize: "12px",
                borderRadius: "10px",
                backgroundColor: "white",
                color: "green",

                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                // marginTop:'20px',
              }}
            >
              <div style={{ color: "orange" }}> Pending :₹ {pendingAmount}</div>
              <div style={{}}> Cash: {cashPaymentPending}</div>
              <div> Online: {onlinePaymentPending}</div>
            </div>
          </div>
          <div
            className="component"
            style={{
              overflow: "scroll",
              height: "70vh",
              width: "100%",
              marginTop: "5px",
              borderRadius: "20px",

              overflowX: "hidden",
            }}
          >
            {data.map((item) => (
              <div
                style={{
                  margin: "5px",
                  // borderBottom: "1px dotted grey",
                  paddingBottom: "8px",

                  // height: "70px",
                }}
              >
                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    Want to Delete from {}OPD
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Press Yes to Delete <br></br> Press NO to Cancel
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose} style={{ color: "red" }}>
                      NO
                    </Button>
                    <Button
                      onClick={handleCloseYES}
                      style={{
                        fontSize: "16px",
                        // fontWeight: "bold",
                        // border: "1px solid ",
                        padding: "3px",
                        borderRadius: "5px",
                        backgroundColor: "rgb(8,10,48,0.9)",
                        color: "white",
                      }}
                      autoFocus
                    >
                      YES
                    </Button>
                  </DialogActions>
                </Dialog>

                <SwipeableList>
                  <SwipeableListItem
                    leadingActions={leadingActions(
                      item.id,
                      item.opdId,
                      item.opdPay,
                      item.created,

                      item.name,
                      item.status,
                      item.paymentMode
                    )}
                    trailingActions={trailingActions(item.opdId, item.name)}
                  >
                    <div
                      style={{ width: "100%", boxShadow: "1px 2px #00d5e4d" }}
                    >
                      <div style={{ display: "flex", margin: "5px" }}>
                        <div
                          style={{
                            color: "grey",
                            fontSize: "12px",
                            // margin: "5px",
                          }}
                        >
                          {item.created.toDate().toLocaleTimeString("en-US")}
                        </div>
                      </div>

                      <div
                        style={{
                          backgroundColor: "white",
                          borderRadius: "5px",
                          display: "flex",
                          marginLeft: "25px",
                        }}
                      >
                        {item.ptCreatedDate === datew ? (
                          <div
                            style={{
                              marginLeft: "-20px",
                              height: "15px",
                              width: "15px",
                              border: "3px solid white",
                              padding: "15px",
                              borderRadius: "100%",
                              display: "flex",
                              justifyContent: "center",
                              backgroundColor: "#ebeef2",
                              // color:'green',
                              alignItems: "center",
                              fontSize: "26px",
                            }}
                          >
                            {item.tokenNumber}
                            <div
                              style={{
                                color: "red",
                                fontSize: "24px",
                                fontWeight: "bolder",
                                marginTop: "-40px",
                              }}
                            >
                            .
                            </div>
                          </div>
                        ) : (
                          <div
                            style={{
                              marginLeft: "-20px",
                              height: "15px",
                              width: "15px",
                              border: "3px solid white",
                              padding: "15px",
                              borderRadius: "100%",
                              display: "flex",
                              justifyContent: "center",
                              backgroundColor: "#ebeef2",
                              alignItems: "center",
                              fontSize: "26px",
                            }}
                          >
                            {item.tokenNumber}
                          </div>
                        )}

                        <div style={{ width: "100%" }}>
                          <div
                            style={{}}
                            onClick={() => {
                              navigate("/profile", {
                                state: {
                                  id: item.id,
                                  opdId: item.id,
                                  name: item.name,

                                  phoneNumber: item.phoneNumber,

                                  gender: item.gender,
                                  age: item.age,
                                  totalPaid: item.paid,
                                },
                              });
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>
                                <div
                                  style={{ fontSize: "16px", margin: "8px" }}
                                >
                                  {item.ptName}
                                  <div
                                    style={{ fontSize: "12px", color: "grey" }}
                                  >
                                    {item.age} /{item.gender}
                                  </div>
                                </div>
                              </div>

                              <div>
                                <div>
                                  {" "}
                                  <a
                                    href={`tel: ${item.phNumber}`}
                                    style={{
                                      color: "rgb(32, 84, 131)",
                                      textDecoration: "none",
                                    }}
                                  >
                                    {" "}
                                    <p
                                      style={{
                                        fontSize: "14px",

                                        fontWeight: "bold",
                                        marginRight: "8px",
                                      }}
                                    >
                                      {item.phNumber}
                                    </p>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginBottom: "10px",
                              marginRight: "8px",
                            }}
                          >
                            <div
                              style={{
                                marginLeft: "8px",
                                color: "grey",
                                fontSize: "14px",
                                // backgroundColor: "green",
                                width: "100%",
                                // borderRight:'1px solid grey'
                              }}
                            >
                              <div
                                style={{ display: "flex", fontSize: "12px" }}
                              >
                                ASK ₹{" "}
                                <div
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "14px",
                                  }}
                                >
                                  {item.askPrice}
                                </div>
                              </div>
                            </div>

                            <div
                              style={{
                                marginRight: "10px",
                                color: "grey",
                                fontSize: "14px",
                                display: "flex",
                                flexDirection: "column",
                                // justifyContent: "flex-end",
                                alignItems: "flex-end",
                                borderLeft: "1px solid grey",

                                width: "100%",
                              }}
                            >
                              <div
                                style={{ display: "flex", fontSize: "12px" }}
                              >
                                PAID : ₹{" "}
                                <div
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "14px",
                                  }}
                                >
                                  {item.opdPay}
                                </div>
                              </div>
                              <div style={{ fontSize: "10px" }}>
                                {item.paymentMode ? item.paymentMode : ""}
                              </div>
                              <div style={{ fontSize: "10px" }}>
                                {item.status === "approved" ? (
                                  <div style={{ color: "green" }}>
                                    Approved{" "}
                                  </div>
                                ) : (
                                  <div style={{ color: "orange" }}>Pending</div>
                                )}
                              </div>
                            </div>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-end",
                              }}
                            >
                              {item.status === "approved" ? (
                                <OpdPay
                                  id={item.id}
                                  opdId={item.opdId}
                                  status={item.status}
                                  datew={datew}
                                  type={"PAY"}
                                />
                              ) : (
                                <div>
                                  <OpdPay
                                    id={item.id}
                                    opdId={item.opdId}
                                    status={item.status}
                                    datew={datew}
                                    type={"ASK"}
                                  />
                                  <div style={{ height: "3px" }}></div>
                                  <OpdPay
                                    id={item.id}
                                    opdId={item.opdId}
                                    status={item.status}
                                    datew={datew}
                                    type={"PAY"}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwipeableListItem>
                </SwipeableList>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="startOPdBtn"
        style={{
          display: "flex",
          position: "fixed",
          bottom: "0px",
          alignItem: "center",
          justifyContent: "center",

         
          width: "100%",
          zIndex: "999",
        }}
      >
        <div
          style={{
            color: "white",
            backgroundColor: "rgba(12, 17, 52, 0.973)",
            padding: "12px",
            borderRadius: "15px",
          }}
          onClick={() => {
            navigate("/newopdstart", {
              state: {
                id: "1",
              },
            });
          }}
        >
          Start OPD
        </div>
      </div>

      {/* <Bottombar/> */}
    </div>
  );
}

export default Opd;
