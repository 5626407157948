import React, { useEffect, useRef, useState } from "react";
import { Button } from "@material-ui/core";
import { useReactToPrint } from "react-to-print";
import { Print } from "@mui/icons-material";
import { Printrx } from "./Printrx";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../../../../../firebaseConfig/firebase";
function Preview({ onNext, tId, name, age, gender, phoneNumber }) {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const [examData, setOnExamData] = useState([]);
  const [chiefC, setChiefC] = useState("");
  const [investi, setInvesti] = useState("");

  const [description, setDescription] = useState([]);

  const [medicine, setMedicine] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const pushOnExam = doc(db, "treatment", tId);
        const unsubscribe = onSnapshot(pushOnExam, (doc) => {
          if (doc.exists()) {
            const data = doc.data();
            console.log("Document data:", data);
            setOnExamData([data]);

            setChiefC(data.chiefComplaint);
            setInvesti(data.investigation);

            setDescription(data.description);
            console.log("====================================");
            console.log(data.description);
            console.log("====================================");
          } else {
            console.log("No such document!");
          }
        });
        return unsubscribe;
      } catch (error) {
        console.error("Error fetching document:", error);
      }
    };
    fetchData();
  }, [tId]);

  return (
    <div>
      <div>Preview</div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        onClick={handlePrint}
      >
        <div style={{ fontSize: "14px" }}>
          {" "}
          <Print />{" "}
        </div>
        <div style={{ marginTop: "5px", color: "grey", fontSize: "12px" }}>
          <div>
            <div>
              <div style={{ display:'none' }}>
                <Printrx
                  ref={componentRef}
                  tId={tId}
                  name={name}
                  age={age}
                  gender={gender}
                  phoneNumber={phoneNumber}
                  examData={examData}
                  chiefC={chiefC}
                  investi={investi}
                />
              </div>
              {/* <button onClick={handlePrint}>Print</button> */} PRINT
            </div>
          </div>
        </div>{" "}
      </div>


<div>

      <div style={{ display: "flex" }}>
        {" "}
        <div>Chief Complaint :</div>
        <div style={{ marginLeft: "70px" }} marginLeft></div>- {chiefC}
      </div>
     

      <div style={{ display: "flex", marginTop: "20px" }}>
        <div>On Examination :</div>
        <div style={{ marginLeft: "70px" }}>
          {examData.map((item, index) => (
            <div key={index} style={{ width: "100%" }}>
              <div>
                {item.description.map((exam, idx) => (
                  <div key={idx} style={{ display: "flex", marginTop: "5px" }}>
                    <div>- {exam.onExamination} </div> # irt{" "}
                    <div
                      style={{
                        fontSize: "14px",
                        backgroundColor: "#F1F1F2",
                        padding: "5px",
                        marginLeft: "10px",
                        borderRadius: "3px",
                      }}
                    >
                      
                      {Array.isArray(exam.toothNumber)
                        ? exam.toothNumber.join(",")
                        : exam.toothNumber}
                    </div>
                    {/* Adjust marginLeft as needed */}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div style={{ display: "flex" }}>
        {" "}
        <div>Investigation:</div>
        <div style={{ marginLeft: "90px" }} marginLeft></div>- {investi}
      </div>

      <div style={{ display: "flex", marginTop: "20px" }}>
        <div> Treatment Advice :</div>
        <div style={{ marginLeft: "60px" }}>
          {examData.map((item, index) => (
            <div key={index} style={{ width: "100%" }}>
              <div>
                {item.description.map((exam, idx) => (
                  <div key={idx} style={{ display: "flex", marginTop: "5px" }}>
                    <div>- {exam.treatmentAdvice} </div> # irt{" "}
                    <div
                      style={{
                        fontSize: "14px",
                        backgroundColor: "#F1F1F2",
                        padding: "5px",
                        marginLeft: "10px",
                        borderRadius: "3px",
                      }}
                    >
                     
                      {Array.isArray(exam.toothNumber)
                        ? exam.toothNumber.join(",")
                        : exam.toothNumber}
                    </div>
                    {/* Adjust marginLeft as needed */}
                    <div>{exam.treatmentDetails}</div>
                    <div>--₹{exam.price} --</div>
                    <div>{exam.status}</div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>


      <div style={{display:'flex'}}>


          <div> Medication :</div>
          <div style={{ marginLeft:'70px'}}>

         
          <div>Rx,</div>
          {examData.map((item, index) => (
            <div key={index} style={{ width: "100%" }}>


            {item.medicine.length === 0 ? (
        <div style={{ fontSize: "12px",margin:'25px' }}>No data Available</div>
      ) : (
              <div>
                {item.medicine.map((exam, idx) => (
                  <div
                    key={idx}
                    style={{
                      display: "flex",
                      borderBottom: "1px solid #D8D8D8",
                      padding: "5px",
                      marginTop: "10px",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "14px",
                        backgroundColor: "#F1F1F2",
                        padding: "5px",
                        borderRadius: "3px",
                      }}
                    > 
{exam.MedicineName}
                      
                    </div>
                    
       <div>{exam.Frequency}</div>
  <div>{exam.timing}</div>
  <div>{exam.Duration}</div>
  <div style={{marginLeft:'10px'}}> ( Qty -{exam.Qty})</div>
                    {/* Adjust marginLeft as needed */}
                   
                  </div>
                ))}
              </div>)}
            </div>
          ))}

          </div>
        </div>

</div>

    
     
    </div>
  );
}

export default Preview;
