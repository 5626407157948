import { StaticDatePicker } from "@mui/x-date-pickers";
import React, { useEffect, useState } from "react";
import "./chatpage.css";
import { useLocation, useNavigate } from "react-router-dom";
import { IconButton, Input, TextField, arrow } from "@mui/material";
import { ArrowBack, ArrowBackIos, Search, Send } from "@mui/icons-material";
import {
  addDoc,
  collection,
  limit,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  where,
} from "firebase/firestore";
import { db } from "../../firebaseConfig/firebase";
import axios from "axios";

const token = process.env.REACT_APP_API_KEYTOKEN;
const mytoken = process.env.REACT_APP_API_KEYMYTOKEN;
const phidtoken = process.env.REACT_APP_API_KEYphidtoken;

function ChatPage() {
  const navigate = useNavigate();
  const state = useLocation();
  const [data, setData] = useState([]);
  const [textmsg, setTextmsg] = useState("");

  const sendMsg = async () => {
    const msgData = {
      sentby: "dr",
      msg_body: textmsg,

      wa_no: state.state.wa_no,

      created: serverTimestamp(),
    };

    axios({
      method: "POST",
      url:
        "https://graph.facebook.com/v15.0/" +
        phidtoken +
        "/messages?access_token=" +
        token,
      data: {
        messaging_product: "whatsapp",
        recipient_type: "individual",
        type: "text",
        to: state.state.wa_no,
        text: {
          body: textmsg,
        },
      },

      headers: {
        "Content-Type": "application/json",
      },
    });

    await addDoc(collection(db, "waMsg"), msgData);

    setTextmsg("");
  };

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };

  


  useEffect(() => {
    scrollToBottom();
    const msgCollection = collection(db, "waMsg");

    const msgQuery = query(
      msgCollection,
      where("wa_no", "==", state.state.wa_no),
      orderBy("created", "desc"),
      limit(15)
    );

    const unsub = onSnapshot(msgQuery, (snap) => {
      let newArray = [];

      snap.forEach((doc) => {
        newArray.push({
          ...doc.data(),
          id: doc.id,
          msg_body: doc.get("msg_body"),
          sentby: doc.get("sentby"),

          created: doc.get("created"),
        });
      });

      console.log(newArray);

      setData(newArray.reverse());
    });

    return () => {
      unsub();
    };
  }, []);

  return (
    <div>
      {/* <div onClick={()=> navigate(-1)}>
back
    </div> */}

      <div
        style={{
          height: "50px",
          backgroundColor: "rgba(10,13,37,0.9)",
          color: "white",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <div onClick={() => navigate(-1)}>
          {" "}
          <ArrowBackIos />{" "}
        </div>
        <div>
          {textmsg}
          {state.state.name}
        </div>
        <div>
          <a
            href={`tel: ${state.state.wa_no}`}
            style={{
              color: "white",
              textDecoration: "none",
            }}
          >
            {" "}
            <p
              style={{
                fontSize: "14px",

                fontWeight: "bold",
                marginRight: "10px",
              }}
            >
              {state.state.wa_no}
            </p>
          </a>

          {/* {state.state.wa_no} */}
        </div>
      </div>

      <div style={{height:'75vh', overflowY:'scroll', 
      
  }}>
        {/* //start from here */}
        {data.map((item) => (
          <div style={{ display: "flex", width: "fitContent" }}>
            {item.sentby == "user" ? (
              <div
                className="userSent"
                style={{
                  // border: "1px solid black",
                  marginLeft: "15px",
                  marginTop:'10px',
                  paddingLeft: "15px",
                  paddingRight: "15px",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  // borderRadius: "10px",
             
                  
                }}
              >
                {" "}
                {item.msg_body}
              </div>
            ) : (
              <div className="drsent"  style={{
                  // border: "1px solid black",
                  marginRight: "15px",
                  marginTop:'10px',
                  paddingLeft: "8px",
                  paddingRight: "15px",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                 
             
                  
                }}> {item.msg_body}</div>
            )}
          </div>
        ))}
      </div>

      <div
        className="searchBar"
        style={{
          height: "40px",
          backgroundColor: "rgb(232, 232, 232)",
          position: "fixed",
          
        }}
      >
        <div className="searchBar" style={{ width: "100%" }}>
          <Input
            placeholder="Type Message"
            fullWidth
            multiline
            value={textmsg}
            onChange={(event) => {
              const { value } = event.target;
              setTextmsg(value);
            }}
          />
          <div style={{ marginLeft: "10px" }}>
            <Send
              fontSize="large"
              onClick={() => {
                // alert('clicked')
                sendMsg();
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChatPage;
