import { style } from "@mui/system";

import { Delete, DeleteOutline, Edit } from "@mui/icons-material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import IconButton from "@material-ui/core/IconButton";
import {
  collection,
  onSnapshot,
  query,
  where,
  doc,
  deleteDoc,
  orderBy,
} from "firebase/firestore";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { db } from "../../../firebaseConfig/firebase";
import AddNewTretment from "../AddNewTretment";
import "./patientProfile.css";
import rctImg from "../../../img/rct.jpg";
import implantImg from "../../../img/dental-implants.png";
import extractionImg from "../../../img/extraction.jpg";
import opdImg from "../../../img/opd.png";
import orthoImg from "../../../img/ortho.jpg";
import cappingImg from "../../../img/capping.jpg";
import dentureImg from "../../../img/dentures-icon.png";
import preventiveImg from "../../../img/preventive-dentistry.png";
import { WhatsApp, AccountCircle, PersonPin, Print } from "@mui/icons-material";
import {
  LeadingActions,
  SwipeableList,
  SwipeableListItem,
  SwipeAction,
  TrailingActions,
} from "react-swipeable-list";
import "react-swipeable-list/dist/styles.css";
import ProfileSubSec from "./ProfileSubSec";
import Payment from "../payment/Payment";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

import { useReactToPrint } from "react-to-print";
import { PrintComponent } from "./PrintComponent";

import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

import PropTypes from "prop-types";

import Typography from "@mui/material/Typography";
import AppointmentSubSec from "./AppointmentSubSec";
import UpdatePatient from "../UpdatePatient";
import { Button } from "@material-ui/core";
import OpdProfilesub from "./OpdProfilesub";
import StartRx from "../startOPD/start/StartRx";
import { Printrx } from "../startOPD/start/eventCreate/Printrx";
import AddNewEvent from "../AddNewEvent";
import Addvisit from "./Addvisit";

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};
const A4Page = React.forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{
      width: "210mm",
      height: "297mm",
      padding: "20px",
      boxSizing: "border-box",
    }}
  >
    {/* Your content goes here */}
    <h1>Your A4 Size React Page</h1>
  </div>
));

function Patientprofile() {
  let navigate = useNavigate();
  const state = useLocation();

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [treatmentOpen, setTreatmentOpen] = useState(true);
  const [profileOpen, setProfileOpen] = useState(false);
  const [OPDOpen, setOpdOpen] = useState(false);
  const [appointmentOpen, setAppointmentOpen] = useState(false);
  const [paymentOpen, setPaymentOpen] = useState(false);
  const [progress, setProgress] = useState(10);

  const imgselection = (imgselection) => {
    if (imgselection === "Extraction") {
      return extractionImg;
    } else if (imgselection === "Capping(M C)") {
      return cappingImg;
    } else if (imgselection === "RCT") {
      return rctImg;
    } else if (imgselection === "Capping(M C-IVO)") {
      return cappingImg;
    } else if (imgselection === "Capping(Zirconia)") {
      return cappingImg;
    } else if (imgselection === "Implants") {
      return implantImg;
    } else if (imgselection === "Surgery") {
      return rctImg;
    } else if (imgselection === "Filling") {
      return preventiveImg;
    } else if (imgselection === "3rd Molar") {
      return extractionImg;
    } else if (imgselection === "CD") {
      return dentureImg;
    } else if (imgselection === "Ortho") {
      return orthoImg;
    } else {
      return opdImg;
    }
  };

  const leadingActions = () => (
    <LeadingActions>
      <SwipeAction onClick={() => ""}>
        <IconButton style={{ color: "rgb(5, 2, 43)", marginRight: "20px" }}>
          <Edit />
        </IconButton>
      </SwipeAction>
    </LeadingActions>
  );

  const trailingActions = (tretmentId, treatmentName) => (
    <TrailingActions>
      <SwipeAction
        style={{ color: "red" }}
        onClick={async () => {
          const a = prompt(`Enter code to delete "${treatmentName}" `);
          if (a === "Yess") {
            await deleteDoc(doc(db, "treatment", tretmentId));
          } else {
            alert(`Failed to delete "${treatmentName}" `);
          }
        }}
      >
        <IconButton style={{ color: "red", marginRight: "20px" }}>
          <Delete />
        </IconButton>
      </SwipeAction>
    </TrailingActions>
  );

  const [treatments, setTreatments] = useState([]);
  const [treatmentsPass, setTreatmentsPass] = useState([]);
  const [payments, setPayments] = useState([]);

  const DateFormatter = ({ date }) => {
    const formatDate = (dateStr) => {
      const date = new Date(dateStr);
      const day = date.getDate();
      const month = date.toLocaleString("default", { month: "short" });
      const year = date.getFullYear();

      return `${day < 10 ? "0" : ""}${day}  ${month}  ${year}`;
    };

    const formattedDate = formatDate(date);

    return <div style={{ whiteSpace: "pre-line" }}>{formattedDate}</div>;
  };

  useEffect(() => {
    const treatmentData = collection(db, "treatment");

    const paymentData = collection(db, "patientAccount");

    const tD = query(
      treatmentData,
      where("patientId", "==", state.state.opdId || state.state.ptid),
      orderBy("created", "desc")
    );

    const paymentQuery = query(
      paymentData,
      where("patientId", "==", state.state.opdId || state.state.ptid),
      orderBy("created", "desc")
    );

    const patientData = onSnapshot(tD, (snap) => {
      let newArray = [];

      snap.forEach((doc) => {
        newArray.push({
          ...doc.data(),

          tretmentId: doc.id,
          patientId: doc.get("opdId"),
          treatmentName: doc.get("treatmentName"),
          treatmentPrice: doc.get("price"),
          treatmentPaid: doc.get("paid"),
          qty: doc.get("qty"),
          chiefComplaint: doc.get("chiefComplaint"),
          rctData: doc.get("rctCompletion"),

          created: doc.get("created"),
        });
      });

      console.log([newArray]);

      setTreatments(newArray);
    });

    const paymentDataQuery = onSnapshot(paymentQuery, (snap) => {
      let newArray = [];

      snap.forEach((doc) => {
        newArray.push({
          ...doc.data(),

          docId: doc.id,
          patientId: doc.get("patientId"),
          opdId: doc.get("opdId"),
          price: doc.get("price"),
          date: doc.get("paymentReceivedDate"),
          created: doc.get("created"),
        });
      });

      console.log(newArray);

      setPayments(newArray);
    });

    setTreatmentOpen(true);

    return () => {
      paymentDataQuery();
      patientData();
    };
  }, []);

  const totalPaidAmount = payments
    .map((item) => parseInt(item.price))
    .reduce((prev, curr) => prev + curr, 0);
  const totalNetAmount = treatments
    .map((item) => parseInt(item.treatmentPrice))
    .reduce((prev, curr) => prev + curr, 0);

  const dueAmount = totalNetAmount - totalPaidAmount;

  return (
    <div>
      <div
        style={{
          margin: "5px",
          padding: "10px",
          backgroundColor: "rgba(10,13,37)",
          color: "white",
          borderRadius: "10px",
        }}
      >
        <div
          className="topSec"
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: "16px",
                margin: "2px",
                display: "flex",
              }}
            >
              <div>{state.state.name}</div>

              <div
                style={{ marginLeft: "8px", marginTop: "3px" }}
                onClick={() => {
                  setTreatmentOpen(false);
                  setProfileOpen(true);
                  setPaymentOpen(false);
                  setAppointmentOpen(false);
                  setOpdOpen(false);
                }}
              >
                {profileOpen == true ? (
                  <div
                    style={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      borderBottom: "1px solid grey",
                      paddingBottom: "3px",
                    }}
                  >
                    <Edit style={{ fontSize: 16, color: "white" }} />
                  </div>
                ) : (
                  <div style={{ fontSize: "14px" }}>
                    {" "}
                    <Edit style={{ fontSize: 16, color: "white" }} />{" "}
                  </div>
                )}
              </div>
            </div>

            <p style={{ fontSize: "12px", marginLeft: "2px" }}>
              No Medical History
              {/* {state.state.opdId || state.state.ptid} */}
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <div style={{}}>
              {state.state.age} {state.state.gender}
            </div>
            {/* <div style={{ fontSize: "12px" }}>{state.state.DOB}</div> */}
            <div>
              <a
                href={`tel:+91 ${state.state.phoneNumber}`}
                style={{
                  textDecoration: "none",
                }}
              >
                {" "}
                <p
                  style={{
                    fontSize: "14px",
                    color: "white",

                    fontWeight: "bold",
                    // marginRight: "5px",
                  }}
                >
                  {state.state.phoneNumber}
                </p>
              </a>
            </div>

            {/* <p>{state.state.phoneNumber}</p> */}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            padding: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div style={{ fontSize: "14px" }}>{totalNetAmount}</div>
            <div style={{ marginTop: "5px", color: "grey", fontSize: "12px" }}>
              {" "}
              Total
            </div>{" "}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div style={{ fontSize: "14px" }}>{totalPaidAmount}</div>
            <div style={{ marginTop: "5px", color: "grey", fontSize: "12px" }}>
              {" "}
              Paid
            </div>{" "}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div style={{ fontSize: "14px", color: "red" }}>{dueAmount}</div>
            <div style={{ marginTop: "5px", color: "grey", fontSize: "12px" }}>
              {" "}
              Due
            </div>{" "}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
            onClick={handlePrint}
          >
            <div style={{ fontSize: "14px" }}>
              {" "}
              <Print />{" "}
            </div>
            <div style={{ marginTop: "5px", color: "grey", fontSize: "12px" }}>
              <div>
                <div>
                  <div style={{ display: "none" }}>
                    <PrintComponent
                      ref={componentRef}
                      name={state.state.name}
                      age={state.state.age}
                      gender={state.state.gender}
                      phoneNumber={state.state.phoneNumber}
                    />
                  </div>
                  {/* <button onClick={handlePrint}>Print</button> */} PRINT
                </div>
              </div>
            </div>{" "}
          </div>
        </div>
        <div
          className="midSec"
          style={{
            display: "flex",
            justifyContent: "space-around",
            backgroundColor: "rgb(55, 53, 73)",
            color: "white",
            borderRadius: "10px",
            padding: "10px",
            margin: "0px",
          }}
        >
          <div
            onClick={() => {
              setProfileOpen(false);
              setPaymentOpen(false);
              setAppointmentOpen(false);
              setTreatmentOpen(true);
              setOpdOpen(false);
            }}
          >
            {" "}
            {treatmentOpen == true ? (
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "14px",
                  borderBottom: "1px solid grey",
                  paddingBottom: "3px",
                }}
              >
                Treatments
              </div>
            ) : (
              <div style={{ fontSize: "14px" }}>Treatments </div>
            )}
          </div>

          <div
            onClick={() => {
              setTreatmentOpen(false);
              setProfileOpen(false);
              setOpdOpen(true);
              setPaymentOpen(false);
              setAppointmentOpen(false);
            }}
          >
            {OPDOpen == true ? (
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "14px",
                  borderBottom: "1px solid grey",
                  paddingBottom: "3px",
                }}
              >
                OPD
              </div>
            ) : (
              <div style={{ fontSize: "14px" }}>OPD </div>
            )}
          </div>
          <div
            onClick={() => {
              setTreatmentOpen(false);
              setProfileOpen(false);
              setAppointmentOpen(false);
              setPaymentOpen(true);
              setOpdOpen(false);
            }}
          >
            {paymentOpen == true ? (
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "14px",
                  borderBottom: "1px solid grey",
                  paddingBottom: "3px",
                }}
              >
                Payments
              </div>
            ) : (
              <div style={{ fontSize: "14px" }}>Payments </div>
            )}
          </div>
          <div
            onClick={() => {
              setTreatmentOpen(false);
              setProfileOpen(false);
              setPaymentOpen(false);
              setAppointmentOpen(true);
              setOpdOpen(false);
            }}
          >
            {appointmentOpen == true ? (
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "14px",
                  borderBottom: "1px solid grey",
                  paddingBottom: "3px",
                }}
              >
                Appointments
              </div>
            ) : (
              <div style={{ fontSize: "14px" }}>Appointments </div>
            )}
          </div>
        </div>
      </div>

      <div
        style={{
          overflow: "scroll",
          height: "65vh",
          width: "100%",
          backgroundColor: "#EBEEF2",
        }}
      >
        {treatmentOpen == true ? (
          <div>
            {treatments.length === 0 ? (
              <div> Loading data ... </div>
            ) : (
              treatments.map((item, index) => (
                <div className="lastSec">
                  <SwipeableList>
                    <SwipeableListItem
                      leadingActions={leadingActions(
                        item.tretmentId,
                        item.treatmentName
                      )}
                      trailingActions={trailingActions(
                        item.tretmentId,
                        item.treatmentName
                      )}
                    >
                      <div
                        style={{
                          width: "100%",
                          // position: "relative",
                          height: "auto",
                        }}
                      >
                        <div
                          className="patientBox"
                          style={{
                            display: "flex",
                            borderRadius: "10px",

                            height: "auto",
                          }}
                          onClick={() => {
                            navigate("/treatment", {
                              state: {
                                id: state.state.opdId || state.state.ptid,
                                tId: item.tretmentId,
                                tName: item.treatmentName,
                                ptName: state.state.name,
                                ptPhNumber: state.state.phoneNumber,
                                tTotal: item.treatmentPrice,
                                tCreated: item.created.toDate().toDateString(),
                              },
                            });
                          }}
                        >
                          <div
                            style={{
                              padding: "15px 5px 15px 15px",
                              borderTopLeftRadius: "10px",
                              borderBottomLeftRadius: "10px",
                              width: "50px",
                              alignItems: "center",
                              justifyContent: "center",
                              color: "white",
                              backgroundColor: "rgb(32, 84, 131)",
                            }}
                          >
                            <DateFormatter
                              date={item.created.toDate().toDateString()}
                            />
                            {/* <img
                              src={imgselection(item.treatmentName)}
                              width="100%"
                              height="80"
                            /> */}
                          </div>
                          <div style={{ width: "100%", marginLeft: "8px" }}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "5px",
                              }}
                            >
                              <div
                                style={{ fontWeight: "bold", fontSize: "16px" }}
                              >
                                {item.treatmentName ? (
                                  item.treatmentName
                                ) : (
                                  <div
                                    style={{
                                      fontSize: "14px",
                                      marginTop: "8px",
                                    }}
                                  >
                                    {" "}
                                    Chief Complaint
                                  </div>
                                )}
                              </div>

                              <div
                                style={{
                                  marginRight: "15px",
                                  display: "flex",
                                }}
                              >
                                {item.tretmentId ? (
                                  <div
                                    style={{ fontSize: "14px", color: "grey" }}
                                    // onClick={handlePrint}
                                  >
                                    {/* <PictureAsPdfIcon /> */}
                                    <div style={{ display: "none" }}>
                                      {/* <Printrx
                                        ref={componentRef}
                                        tId={item.tretmentId}
                                        name={state.state.name}
                                        age={state.state.age}
                                        gender={state.state.gender}
                                        phoneNumber={state.state.phoneNumber}
                                        examData={[treatments[index]]}
                                        chiefC={item.chiefComplaint}
                                        investi={item.investigation}
                                      /> */}
                                    </div>
                                  </div>
                                ) : (
                                  <div> no data </div>
                                )}
                                <div
                                  style={{
                                    color: "grey",
                                    fontWeight: "bold",
                                    fontSize: "14px",
                                  }}
                                >
                                  {item.treatmentPrice}
                                </div>
                              </div>
                            </div>

                            {/* <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div style={{ fontSize: "14px" }}>
                                <div
                                  style={{ fontSize: "12px", marginTop: "5px" }}
                                ></div>
                              </div>
                              <div
                                style={{
                                  fontSize: "12px",
                                  marginRight: "20px",
                                }}
                              >
                                {item.treatmentPrice / item.qty}
                              </div>
                            </div> */}

                            <div
                              className="sec3"
                              style={{
                                color: "grey",
                                fontSize: "14px",
                                marginTop: "-15px",
                              }}
                            >
                              {item.qty}
                            </div>
                            <div
                              className="sec3"
                              style={{
                                color: "#2e2e2e",
                                fontSize: "12px",
                                marginLeft: "-23px",
                                marginRight: "5px",
                                marginTop: "18px",
                              }}
                            >
                              <ul>
                                <li>{item.chiefComplaint}</li>
                                {/* <li>{item.tretmentId}</li> */}
                              </ul>
                            </div>
                            {/* <div
                              style={{
                                marginTop: "5px",
                                marginRight: "2px",
                                color: "green",
                              }}
                            >
                              <Box sx={{ width: "100%" }}>
                                <LinearProgressWithLabel value={item.rctData} />
                              </Box>
                            </div> */}
                          </div>{" "}
                        </div>

                        <div>
                          {item.treatmentName ? (
                            <div> no data </div>
                          ) : (
                            <div style={{ marginBottom: "14px" }}>
                              {item.description.map((exam, idx) => (
                                <div
                                  key={idx}
                                  className="sec1"
                                  style={{
                                    position: "relative",
                                    marginLeft: "30px",
                                    marginRight: "10px",
                                    marginTop: "5px",
                                    height: "auto",
                                    borderRadius: "0px 10px 10px 15px",

                                    borderLeft: "4px solid rgb(42,53,55)",
                                    // width: "79%",
                                    backgroundColor: "white",

                                    color: "rgba(169, 169, 169,0.655)",
                                    padding: "10px",
                                    fontSize: "12px",
                                  }}
                                >
                                  <div>
                                    <div
                                      style={{
                                        fontSize: "12px",
                                        // marginTop: "2px",
                                        fontWeight: "bold",
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <div
                                        style={{
                                          fontSize: "12px",
                                          // marginTop: "2px",
                                          fontWeight: "bold",
                                          color: "rgb(32, 84, 131)",
                                        }}
                                      >
                                        On Examination
                                      </div>

                                      {exam.status === "Proposed" ? (
                                        <div
                                          style={{
                                            fontSize: "14px",
                                            color: "orange",
                                          }}
                                        >
                                          {exam.status}
                                        </div>
                                      ) : exam.status === "Completed" ? (
                                        <div
                                          style={{
                                            fontSize: "14px",
                                            color: "green",
                                          }}
                                        >
                                          {exam.status}
                                        </div>
                                      ) : (
                                        <div
                                          style={{
                                            fontSize: "14px",
                                            color: "blue",
                                          }}
                                        >
                                          {exam.status}
                                        </div>
                                      )}
                                    </div>
                                    <div
                                      style={{
                                        color: "grey",
                                        fontSize: "12px",
                                        marginLeft: "-18px",
                                        marginTop: "-8px",
                                      }}
                                    >
                                      <ul>
                                        <li>
                                          {exam.toothNumber}{" "}
                                          {exam.onExamination}
                                        </li>
                                      </ul>
                                    </div>{" "}
                                  </div>
                                  <div>
                                    <div
                                      style={{
                                        fontSize: "12px",
                                        // marginTop: "2px",
                                        fontWeight: "bold",
                                        color: "rgb(32, 84, 131)",
                                      }}
                                    >
                                      Treatment Advice
                                    </div>
                                    <div
                                      style={{
                                        color: "grey",
                                        fontSize: "12px",
                                        marginLeft: "-18px",
                                        marginTop: "-8px",
                                      }}
                                    >
                                      <ul>
                                        <li>
                                          {exam.toothNumber}{" "}
                                          {exam.treatmentAdvice}
                                        </li>

                                        {exam.treatmentDetails}
                                      </ul>
                                    </div>{" "}
                                  </div>
                                  <div>
                                    <div
                                      style={{
                                        fontSize: "12px",
                                        // marginTop: "2px",
                                        fontWeight: "bold",
                                        margin: "-2px",
                                        color: "rgb(32, 84, 131)",
                                      }}
                                    >
                                      Price
                                    </div>
                                    <div
                                      style={{
                                        color: "grey",
                                        fontSize: "12px",
                                        marginLeft: "-18px",
                                        marginTop: "-8px",
                                      }}
                                    >
                                      <ul>
                                        <li>{exam.price}</li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        marginRight: "0px",
                                        border: "1px solid grey",
                                        padding: "1px",
                                        borderRadius: "10px",
                                        color: "grey",
                                      }}
                                    >
                                      <div>
                                        <Addvisit
                                          tId={item.tretmentId}
                                          index={idx}
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  {exam.workArray
                                    ? 
                                  <div
                                    style={{
                                      // height: "80vh",
                                      marginTop: "5px",
                                      color: "black",
                                      borderRadius: "35px",
                                      borderTop: "6px solid rgb(231, 233, 251)",
                                    }}
                                  >
                                    {/* <div style={{backgroundColor:'grey'}}> */}

                                    <div
                                      style={{
                                        display: "flex",
                                        marginLeft: "60px",
                                        marginTop: "10px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      <div style={{ flex: "1" }}>Date</div>
                                      <div style={{ flex: "2" }}>Procedure</div>
                                    </div>
                                    {/* </div> */}
                                    <div
                                      style={{
                                        overflow: "scroll",
                                        // height: "65vh",
                                        width: "100%",
                                      }}
                                    >
                                      {exam.workArray
                                        ? exam.workArray.map((itemx) => (
                                            <div
                                              style={{
                                                display: "flex",
                                                marginLeft: "30px",
                                                marginTop: "20px",
                                                marginRight: "10px",
                                              }}
                                            >
                                              <div>
                                                <div
                                                  style={{
                                                    flex: "1",
                                                    fontSize: "14px",
                                                    marginRight: "10px",
                                                  }}
                                                >
                                                  {" "}
                                                  {itemx.date == null
                                                    ? ""
                                                    : itemx.date}
                                                </div>
                                                <div style={{ color: "grey" }}>
                                                  { itemx.time}
                                                </div>
                                              </div>
                                              <div
                                                style={{
                                                  flex: "2",
                                                  fontSize: "14px",
                                                  borderLeft:
                                                    "1px dotted rgb(5, 2, 43)",
                                                  paddingLeft: "10px",
                                                }}
                                              >
                                                <div>{itemx.workName}</div>
                                                <div
                                                  style={{
                                                    color: "grey",
                                                    fontSize: "12px",
                                                    marginTop: "8px",
                                                    marginRight: "15px",
                                                  }}
                                                >
                                                  {itemx.workDetails}
                                                </div>
                                              </div>
                                            </div>
                                          ))
                                        : ""}
                                    </div>
                                  </div> :''}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </SwipeableListItem>
                  </SwipeableList>
                </div>
              ))
            )}

            <div
              style={{
                display: "flex",
                position: "fixed",
                bottom: "0px",
                alignItem: "center",
                justifyContent: "flex-end",
                // width: "90%",
                marginBottom: "100px",
                right: "10px",
                zIndex: "999",
                color: "red",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    lineHeight: "25px",
                    width: "30px",
                    height: "30px",
                    fontSize: "36px",
                    fontWeight: "bolder",
                    borderRadius: "50%",
                    border: "6px solid  rgb(5, 2, 43)",
                    textAlign: "center",
                    padding: "8px",
                    backgroundColor: "rgb(5, 2, 43)",
                    color: "white",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    navigate("/startOpd", {
                      state: {
                        id: state.state.opdId || state.state.ptid,
                        name: state.state.name,
                        age: state.state.age,
                        gender: state.state.gender,
                        phoneNumber: state.state.phoneNumber,
                      },
                    });
                  }}
                >
                  +
                </div>
              </div>
            </div>
          </div>
        ) : profileOpen == true ? (
          <div>
            <ProfileSubSec
              docid={state.state.ptid}
              name={state.state.name}
              age={state.state.age}
              gender={state.state.gender}
              phoneNumber={state.state.phoneNumber}
            />
          </div>
        ) : paymentOpen == true ? (
          <div>
            <Payment paymentData={payments} />
          </div>
        ) : OPDOpen == true ? (
          <div>
            <OpdProfilesub />
          </div>
        ) : (
          <div>
            <AppointmentSubSec />
          </div>
        )}
      </div>
    </div>
  );
}

export default Patientprofile;
