import { collection, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import React, { useState } from 'react'
import { db } from '../../../firebaseConfig/firebase';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function AppointmentSubSec() {
  let navigate = useNavigate();
  const state = useLocation();
  const  [data, setData] = useState([])

  // const appointmentData = collection(db, "AppointmentDetails");

  useEffect(() => {
    const appointmentData = collection(db, "AppointmentDetails");

    //PASS FRPM patientId or docid from PArent Comonent STOPHERE!!!
    const tD = query(
      appointmentData,
      where("patientId", "==", state.state.opdId || state.state.ptid),
      orderBy("created", "desc")
    );

   

    const unsub = onSnapshot(tD, (snap) => {
      let newArray = [];

      snap.forEach((doc) => {
        newArray.push({
          ...doc.data(),

          id: doc.get("patientId"),

          ptName: doc.get("name"),
          
          docid: doc.id,
          timeString: doc.get("timeString"),

          phoneNumber: doc.get("phNumber"),
          duration: doc.get("duration"),
          reason: doc.get("reason"),
          appointmentDate: doc.get("date"),
          appointmentTime: doc.get("time"),

          created: doc.get("created"),
        });
      });

      console.log(newArray);

      setData(newArray);
    });

    return () => {
      unsub();
    };
  }, []);



  return (
    <div
    style={{
      overflow: "scroll",
      height: "65vh",
      width: "100%",
      backgroundColor: "#EBEEF2",
    }}
  >
    
    
    
    


      {data.map((item) => (
        <div>
        <div
            style={{
              // width: "98%",
              position: "relative",
              // height: "80px",
              margin: "5px",
              borderLeft: "4px solid rgb(68,63,55)",
              backgroundColor: "white",
              borderRadius: "10px",
              padding: "13px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <div
                style={{ fontSize: "16px", fontWeight: "bold", color: "rgb(10,13,37)" }}
              >
                {" "}
                {item.reason}
              </div>
              <div style={{ fontSize: "14px" }}>{item.timeString}</div>
            </div>{" "}
            <div style={{display:'flex',flexDirection:'column',alignItems:'flex-end'}}>
              <div style={{ display: "flex" }}>
                {/* <div style={{ color: "grey" }}>Approve{a.date}</div>{" "} */}
                <div style={{ fontSize: "16px" }}>
                  {item.appointmentDate}
                </div>{" "}
              </div>
              <div style={{ color: "grey", fontSize: "14px" }}>
                
              </div>
            </div>
          </div>
             </div>
            ))}
    </div>
  )
}

export default AppointmentSubSec