import { IconButton, TextField } from "@material-ui/core";
import { Search } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';

import {
  addDoc,
  collection,
  query,
  onSnapshot,
  doc,
  where,
  orderBy,
  limit,
  serverTimestamp,
  Timestamp,
} from "firebase/firestore";
import { db } from "../../firebaseConfig/firebase";

import { useNavigate } from "react-router-dom";
import AddNewPt from "../patients/AddNewPt";

function StartNewOpd() {
  let navigate = useNavigate();
  const [inputText, setInputText] = useState("");
  const [data, setData] = useState([]);
  const [date, setDate] = useState(new Date(Date.now()));

  const startOpd = async (
    id,
    name,
    phNumber,
    gender,
    age,
    TotalAmount,
    totalPaid,
    ptCreatedDate
  ) => {
    let datew = JSON.stringify(date);
    datew = datew.slice(1, 11);


  


    const timestamp = Timestamp.fromMillis(ptCreatedDate.seconds * 1000 + ptCreatedDate.nanoseconds / 1000000);
    const dater = timestamp.toDate();
    
    // Extract year, month, and day components
    

    const year = dater.getFullYear();
const month = String(dater.getMonth() + 1).padStart(2, '0'); // Adding 1 since getMonth() returns 0-11
const day = String(dater.getDate()).padStart(2, '0');

// Create the formatted date string
const ptCreatedDateNew = `${year}-${month}-${day}`;







    const opdData = {
      gender: gender,

      phNumber: phNumber,

      ptId: id,
      ptName: name,
      
age:age,
      date: datew,
      ptCreatedDate:ptCreatedDateNew,
      created: serverTimestamp(),
    };

    setShowAlert(true)
    await addDoc(collection(db, "opdEntry"), opdData);
    // await addDoc(collection(db, "patientAccount"), opdData);

    setShowAlert(false)

    navigate("/opd")
  };
  useEffect(() => {
    const patientProfile = collection(db, "patientProfile");
    const abc = inputText.toLowerCase();
    //mounts

    const qd = query(patientProfile, where("namearray", "array-contains", abc),orderBy("created","desc"));

    const q = query(
      collection(db, "patientProfile"),
      orderBy("created", "desc"),
      limit(3)
    );
    const unsub = onSnapshot(inputText == "" ? q : qd, (snap) => {
      let newArray = [];

      snap.forEach((doc) => {
        newArray.push({
          ...doc.data(),
          id: doc.id,
          opdId:doc.get("ptId"),
          name: doc.get("name"),
          phoneNumber: doc.get("phNumber"),
          age: doc.get("age"),
          gender: doc.get("gender"),
          ptCreatedDate: doc.get("created"),
          TotalAmount: doc.get("TotalAmount"),
          paid: doc.get("paid"),
        });
      });

      console.log(newArray);

      setData(newArray);
    });

    return () => {
      unsub();
    };
  }, [inputText]);

  const today = () => {
    alert("today");
    var startOfToday = new Date();
    startOfToday.setHours(0, 0, 0, 0);
    var endOfToday = new Date();
    endOfToday.setHours(23, 59, 59, 999);
  };
  const [showAlert, setShowAlert] = useState(false);

  return (
    <div>
      <div className="searchBar">
      { 
showAlert == true ? 

 <Stack sx={{ width: '100%',position:'absolute',zIndex:'999' }} spacing={2}>
     
      <Alert severity="success">
        <AlertTitle>Success</AlertTitle>
        Patient added to  — <strong>OPD</strong>
      </Alert>
    </Stack> :<div></div>

      }



        <TextField
          fullWidth
          label="Search by Name or PhoneNumber"
          id="fullWidth"
          InputProps={{
            endAdornment: (
              <IconButton>
                <Search />
              </IconButton>
            ),
          }}
          onChange={(e) =>
            setTimeout(() => {
              setInputText(e.target.value);
            }, 1500)
          }
        />
      </div>
      <div></div>
      <div style={{ textAlign: "center" }}>Or</div>
      <div
        style={{
          color: "white",
          backgroundColor: "rgba(12, 17, 52, 0.973)",
          padding: "12px",
          borderRadius: "10px",
          textAlign: "center",
          margin: "5px",
        }}
      >
        <AddNewPt />
      </div>

      <div style={{ display: "flex", width: "100%" }}>
        <div className="rightBa" style={{ width: "100%" }}>
          <div
            style={{
              fontSize: "12px",
              display: "flex",
              color: "grey",
              justifyContent: "space-between",
            }}
          >
            <div>
              <TextField
                style={{
                  color: "red",
                  backgroundColor: "white",
                  border: "1px solid white",
                  borderRadius: "5px",
                  width: "100px",
                  marginLeft: "10px",
                }}
                id="date"
                label="Calender"
                type="date"
                fullWidth
                defaultValue={Date.now()}
                onChange={(event) => setDate(event.target.value)}
                // sx={{ width: "100%" }}
              />
            </div>
            <p>Last 3 patients</p>
          </div>

          <div
            style={{
              width: "100%",
              overflowY: "scroll",
              height: "85vh",
              overflowX: "hidden",
            }}
          >
            {data.map((item) => (
              <div
                className="patientBox"
                onClick={() =>
                  startOpd(
                    item.opdId || item.id,
                    item.name,
                    item.phoneNumber,
                    item.gender,
                    item.age,
                    item.TotalAmount,
                    item.paid,
                    item.ptCreatedDate
                  )
                }
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <p
                    style={{
                      fontSize: "16px",
                      marginLeft: "10px",
                      color: "black",
                    }}
                  >
                    {item.name}
                  </p>

                  <a
                    href={`tel: ${item.phNumber}`}
                    style={{
                      color: "rgb(32, 84, 131)",
                      textDecoration: "none",
                      marginRight: "10px",
                    }}
                  >
                    {" "}
                    <p
                      style={{
                        fontSize: "14px",

                        fontWeight: "bold",
                        marginRight: "10px",
                      }}
                    >
                      {item.phNumber}
                    </p>
                  </a>
                </div>

                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <div style={{ display: "flex" }}>
                    <p style={{ fontSize: "12px" }}>Tap to Add to OPD</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default StartNewOpd;
