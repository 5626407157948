import React, { useState } from "react";
import { db } from "../../../firebaseConfig/firebase";
import { deleteDoc, doc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { IconButton } from "@material-ui/core";
import { Delete, Edit } from "@mui/icons-material";
import UpdatePatient from "../UpdatePatient";

function ProfileSubSec({ docid, name, age, gender,phoneNumber}) {
  const [open, setOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  let navigate = useNavigate();

  const handleCloseYES = async () => {
    setShowAlert(true);
    await deleteDoc(doc(db, "patientProfile", docid));

    setTimeout(() => setShowAlert(false), 2000);

    setOpen(false);
    navigate(-1);
  };

  const handleClose = () => {
    setOpen(false);
    // navigate(-1);
  };
  const delete1 = () => {
    setOpen(true);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "10px",
        }}
      >
        <div
          onClick={delete1}
          style={{
            margin: "5px",
            // borderBottom: "1px dotted grey",
            // paddingBottom: "8px",
            fontWeight: "bold",
            color: "red",
          }}
        >
          <IconButton style={{ color: "red", fontSize: "16px" }}>
            <Delete style={{ fontSize: 18 }} />
            Delete
          </IconButton>
        </div>
        <div
          style={{
            margin: "5px",
            // borderBottom: "1px dotted grey",
            // paddingBottom: "8px",
            // fontWeight:'bold',
          }}
        >
          <IconButton style={{ color: "rgb(5, 2, 43)", fontSize: "16px" }}>
            <UpdatePatient docid1={docid} name1 ={name} age1={age} gender1 ={gender} phoneNumber1={phoneNumber}></UpdatePatient>
          </IconButton>
        </div>

        {/* <div onClick ={delete1} >Delete</div> */}

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Permanently Delete patient from database?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Press Yes to Delete <br></br> Press NO to Cancel
            </DialogContentText>
          </DialogContent>
          <DialogActions>
           
            <Button
              onClick={handleCloseYES}
              style={{
                fontSize: "16px",
                // fontWeight: "bold",
                // border: "1px solid ",
                padding: "3px",
                borderRadius: "5px",
                backgroundColor: "rgb(8,10,48,0.9)",
                color: "white",
              }}
              // autoFocus
            >
              YES
            </Button> <Button onClick={handleClose} style={{  fontSize: "16px",
                // fontWeight: "bold",
                // border: "1px solid ",
                padding: "3px",
                borderRadius: "5px",backgroundColor: "red", color:'white' }}>
              NO
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      <div
        style={{
          height: "450px",
          // border: "1px solid red",
          // overflowY: "scroll",

        }}
      >
       <div> </div>
{/* {docid}
        {name} 
        
        
        {age }
        {gender}
        {phoneNumber} */}
        
      </div>
    </div>
  );
}

export default ProfileSubSec;
