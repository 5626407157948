import {
  collection,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../firebaseConfig/firebase";
import { TextField } from "@material-ui/core";

function TreatmentEvent() {
  const [sumTotal, setSumTotal] = useState(0);
  const [sumTotalGpay, setSumTotalGpay] = useState(0);
  const [eData, setEData] = useState([]);
  const [date, setDate] = useState(new Date(Date.now()));
  const [startDate, setStartDate] = useState(new Date(Date.now()));
  const [endDate, setEndDate] = useState(new Date(Date.now()));
  let datew = JSON.stringify(date);
  datew = datew.slice(1, 11);

  const generateReport = (startDate1, endDate1) => {
    const opdEntryRef = collection(db, "opdEntry");
    const q = query(
      opdEntryRef,
      where("date", ">=", startDate1),
      where("date", "<=", endDate1),
      where("status", "==", "approved")
    );
    getDocs(q)
      .then((querySnapshot) => {
        let sum = 0;
        querySnapshot.forEach((doc) => {
          const opdpay = doc.data().opdPay;
          if (opdpay !== undefined) {
            sum += Number(opdpay);
          }
          // sum += Number(doc.data().opdPay);
        });
        setSumTotal(sum);
        console.log("Sum of opdpay within date range:", sum);

        let sumGpay = 0;

        querySnapshot.forEach((doc) => {
          const opdpayGpay =
            doc.data().paymentMode == "G-PAY" ? doc.data().opdPay : "";
          if (opdpayGpay !== undefined) {
            sumGpay += Number(opdpayGpay);
          }
          // sum += Number(doc.data().opdPay);
        });
        setSumTotalGpay(sumGpay);
        console.log("Sum of opdpay within date range:", sumGpay);
      })
      .catch((error) => {
        console.log("Error getting documents:", error);
      });

    console.log(startDate, endDate);
  };

  useEffect(() => {
    const expenseData = collection(db, "expenseDB");

    //mounts

    const tD = query(
      expenseData,

      orderBy("created", "desc"),
      limit(5)
    );

    const unsub = onSnapshot(tD, (snap) => {
      let newArray = [];

      snap.forEach((doc) => {
        newArray.push({
          ...doc.data(),
          id: doc.id,
          amount: doc.get("amount"),
          paymentMode: doc.get("paymentMode"),
          type: doc.get("type"),
          subType: doc.get("subType"),
          subSubType: doc.get("subSubType"),
          created: doc.get("created"),
        });
      });

      console.log(newArray);

      setEData(newArray);
    });
    return () => {
      unsub();
    };
  }, []);
  return (
    <div>
      <div className="todaysActivity">
        <p
          style={{
            left: "10px",
            fontSize: "14px",
            color: "black",

            margin: "1px",
          }}
        >
          Balance Sheet
        </p>
        <div>
          <div>Monthly report</div>

          <div>
            {" "}
            select Date
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <div>
                {" "}
                start Date
                <TextField
                  style={{
                    color: "red",
                    backgroundColor: "white",
                    border: "1px solid white",
                    borderRadius: "15px",
                    width: "100px",
                    margin: "-5px",
                  }}
                  id="date"
                  label="Calender"
                  type="date"
                  fullWidth
                  defaultValue={Date.now()}
                  onChange={(event) => setStartDate(event.target.value)}
                  // sx={{ width: "100%" }}
                />
              </div>
              <div>
                {" "}
                end Date
                <TextField
                  style={{
                    color: "red",
                    backgroundColor: "white",
                    border: "1px solid white",
                    borderRadius: "15px",
                    width: "100px",
                    margin: "-5px",
                  }}
                  id="date"
                  label="Calender"
                  type="date"
                  fullWidth
                  defaultValue={Date.now()}
                  onChange={(event) => setEndDate(event.target.value)}
                  // sx={{ width: "100%" }}
                />
              </div>
            </div>
            <div
              onClick={() => generateReport(startDate, endDate)}
              style={{
                padding: "5px",
                borderRadius: "5px",
                border: "1px solid black",
                width: "100px",
              }}
            >
              generate report
            </div>
          </div>
          <div>Gpay{sumTotalGpay}</div>
          <div>Cash {sumTotal - sumTotalGpay}</div>
          <div> {sumTotal}</div>
        </div>
      </div>

      <div
        style={{
          overflow: "scroll",
          height: "65vh",
          width: "100%",
          backgroundColor: "#EBEEF2",
        }}
      >
        {eData.map((item) => (
          <div>
            <div
              style={{
                // width: "98%",
                position: "relative",
                // height: "80px",
                margin: "5px",
                borderLeft: "4px solid rgb(68,63,55)",
                backgroundColor: "white",
                borderRadius: "10px",
                padding: "13px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    color: "rgb(10,13,37)",
                  }}
                >
                  {" "}
                  {item.amount}
                </div>
                <div style={{ fontSize: "14px" }}>{item.paymentMode}</div>
                <div style={{ fontSize: "14px" }}>{item.subType}</div>
                <div style={{ fontSize: "14px" }}>{item.subSubType}</div>
                <div style={{ fontSize: "14px" }}>{item.created.toDate().toLocaleTimeString("en-US")}</div>
                <div style={{ fontSize: "14px" }}>{item.created.toDate().toDateString()}</div>
              </div>{" "}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <div style={{ display: "flex" }}>
                  {/* <div style={{ color: "grey" }}>Approve{a.date}</div>{" "} */}
                  <div style={{ fontSize: "16px" }}>{item.type}</div>{" "}
                </div>
                <div style={{ color: "grey", fontSize: "14px" }}></div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default TreatmentEvent;
