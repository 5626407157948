import React, { useRef, useState } from "react";
import "./homepage.css";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Padding, Search } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import Bottombar from "../Bottombar";

import {
  Firestore,
  addDoc,
  collection,
  getCountFromServer,
  getDocs,
  limit,
  query,
  serverTimestamp,
  where,
} from "firebase/firestore";
import { auth, db } from "../../firebaseConfig/firebase";
import { useNavigate } from "react-router-dom";

function Homepage() {
  let navigate = useNavigate();

  const [count1, setcount] = useState();
  const [rctcount, setrctcount] = useState();
  const [opdcount, setopdcount] = useState();
  const [totalOpdcount, settotalOpdcount] = useState();
  const [appointmentcount, setappointmentcount] = useState();
  const [sumTotal, setSumTotal] = useState(0);
  const [open, setOpen] = useState(false);

  const [fName, setFname] = useState("");
  const [gender, setGender] = useState("female");

  const [amount, setAmount] = useState();
  const [age, setAge] = useState();

  const saveButtonRef = useRef(null);
  const [date, setDate] = useState(new Date(Date.now()));
  const [startDate, setStartDate] = useState(new Date(Date.now()));
  const [endDate, setEndDate] = useState(new Date(Date.now()));

  const [expenseType, setExpenseType] = useState("");
  const [expenseMode, setExpenseMode] = useState("");
  const [subExpenseType, setSubExpenseType] = useState("");
  const [subExpenseTypeFixed, setSubExpenseTypeFixed] = useState("");
  let datew = JSON.stringify(date);
  datew = datew.slice(1, 11);

  const unsub = async () => {
    const AllCases = collection(db, "patientProfile");
    const treatments = collection(db, "treatment");
    const opdEntry = collection(db, "opdEntry");
    const totalOpdEntry = collection(db, "opdEntry");
    const AppointmentDetails = collection(db, "AppointmentDetails");
    const rctCount = query(treatments, where("treatmentName", "==", "RCT"));
    const opdCount = query(opdEntry, where("date", "==", datew));
    const AppointmentCount = query(
      AppointmentDetails,
      where("date", "==", datew)
    );

    const snapshotAll = await getCountFromServer(AllCases);
    const count = snapshotAll.data().count;
    setcount(count);

    const snapshotRCT = await getCountFromServer(rctCount);
    const countrct = snapshotRCT.data().count;
    setrctcount(countrct);

    const snapshotOPD = await getCountFromServer(opdCount);
    const countopd = snapshotOPD.data().count;
    setopdcount(countopd);

    const snapshotTotalOPD = await getCountFromServer(totalOpdEntry);
    const counttotalopd = snapshotTotalOPD.data().count;
    settotalOpdcount(counttotalopd);

    const snapshotAppointment = await getCountFromServer(AppointmentCount);
    const countappointment = snapshotAppointment.data().count;
    setappointmentcount(countappointment);

    console.log(count);
  };
  unsub();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    setAmount();
    setExpenseMode();
    setExpenseType();
    setSubExpenseType();
    setSubExpenseTypeFixed();

    setOpen(false);
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      saveButtonRef.current.click();
    }
  };

  const genderHandleChange = (event) => {
    setGender(event.target.value);
    event.preventDefault();
  };
  const handleExpenseModeChange = (event) => {
    setExpenseMode(event.target.value);
  };
  const handleExpenseTypeChange = (event) => {
    setExpenseType(event.target.value);
  };

  const handleSubExpenseTypeChange = (event) => {
    setSubExpenseType(event.target.value);
  };

  const handleSubExpenseTypeFixedChange = (event) => {
    setSubExpenseTypeFixed(event.target.value);
  };

  const addexpeneBtn = async () => {
    const expenseData = {
      amount: amount,
      paymentMode: expenseMode,
      type: expenseType,

      subType: subExpenseType,
      subSubType: subExpenseTypeFixed,

      created: serverTimestamp(),
    };

    await addDoc(collection(db, "expenseDB"), expenseData);

    setAmount();
    setExpenseMode();
    setExpenseType();
    setSubExpenseType();
    setSubExpenseTypeFixed();

    handleClose();
  };

  return (
    <div className="homepage">
      <div className="topBar">
        <h5
          style={{ fontSize: "18px", marginTop: "10px", marginBottom: "0px" }}
        >
          {" "}
          PATRA DENTAL CLINIC
        </h5>
        <p style={{ fontSize: "12px", margin: "0px" }}>
          A Super Speciality Dental Clinic
        </p>
      </div>

      <div className="topSection" style={{ margin: "10px" }}>
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <div className="boxFront">
            <p style={{ color: "blue" }}>{count1}</p>
            <div style={{ marginTop: "16px" }}>
              Total Patients* <div style={{ fontSize: "6px" }}> Jan 23</div>
            </div>
          </div>
          <div className="boxFront">
            <p style={{ color: "green" }}>{opdcount}</p>
            {/* <p>PATIENT VISITS</p> */}
            <p>
              PATIENT VISITS
              <div style={{ fontSize: "8px" }}>today</div>
            </p>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            marginTop: "10px",
          }}
        >
          <div className="boxFront">
            <p style={{ color: "green" }}>{totalOpdcount}</p>
            <p>OPD</p>
          </div>
          <div
            className="boxFront"
            onClick={() => {
              navigate("/calender", {});
            }}
          >
            <p style={{ color: "green" }}>{appointmentcount}</p>
            <p>
              APPOINTMENT
              <div style={{ fontSize: "8px" }}>today</div>
            </p>
          </div>
        </div>
      </div>

      <Button
        onClick={handleClickOpen}
        style={{
          display: "flex",
          position: "fixed",
          bottom: "0px",
          alignItem: "center",
          justifyContent: "center",
          // border:'1px solid black',
          marginBottom: "90px",
          backgroundColor: "rgb(18, 29, 69)",
          color: "white",
          width: "100%",
          zIndex: "999",
          marginLeft: "80px",
          borderRadius: "10px",
          marginRight: "20px",
        }}
      >
        ₹ Add Expenses
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            borderRadius: "15px",
          },
        }}
      >
        <DialogTitle>Add Expenses</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            id="Amount"
            label="Amount"
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            fullWidth
            variant="standard"
            onChange={(event) => {
              const { value } = event.target;
              setAmount(value);
            }}
            required
            onKeyDown={handleKeyDown}
          />

          <FormControl>
            <FormLabel
              id="demo-controlled-radio-buttons-group"
              style={{ marginBottom: "10px", marginTop: "10px" }}
            >
              Payment Mode
            </FormLabel>
            <RadioGroup
              style={{ marginLeft: "10px" }}
              row
              name="row-radio-buttons-group"
              aria-labelledby="demo-controlled-radio-buttons-group"
              value={expenseMode}
              onChange={handleExpenseModeChange}
            >
              <FormControlLabel value="Cash" control={<Radio />} label="Cash" />
              <FormControlLabel
                value="Online"
                control={<Radio />}
                label="Online"
              />
            </RadioGroup>
          </FormControl>
          <FormControl>
            <FormLabel
              id="demo-controlled-radio-buttons-group"
              style={{ marginBottom: "10px", marginTop: "10px" }}
            >
              TYPE
            </FormLabel>
            <RadioGroup
              style={{ marginLeft: "10px" }}
              row
              name="row-radio-buttons-group"
              aria-labelledby="demo-controlled-radio-buttons-group"
              value={expenseType}
              onChange={handleExpenseTypeChange}
            >
              <FormControlLabel
                value="Clinic"
                control={<Radio />}
                label="Clinic"
              />
              <FormControlLabel
                value="Personal"
                control={<Radio />}
                label="Personal"
              />
            </RadioGroup>
          </FormControl>

          {/* Conditional rendering for sub-expense type based on selected expense type */}
          {expenseType === "Clinic" && (
            <FormControl>
              <FormLabel
                id="demo-controlled-radio-buttons-group"
                style={{ marginBottom: "10px", marginTop: "10px" }}
              >
                Subtype
              </FormLabel>
              <RadioGroup
                style={{ marginLeft: "10px" }}
                row
                name="row-radio-buttons-group"
                aria-labelledby="demo-controlled-radio-buttons-group"
                value={subExpenseType}
                onChange={handleSubExpenseTypeChange}
              >
                <FormControlLabel
                  value="Fixed"
                  control={<Radio />}
                  label="Fixed"
                />
                <FormControlLabel value="Lab" control={<Radio />} label="Lab" />
                <FormControlLabel
                  value="New Purchase"
                  control={<Radio />}
                  label="New Purchase"
                />
                <FormControlLabel
                  value="Consumable"
                  control={<Radio />}
                  label="Consumable"
                />
                <FormControlLabel
                  value="Others"
                  control={<Radio />}
                  label="Others"
                />
              </RadioGroup>
            </FormControl>
          )}

          {expenseType === "Personal" && (
            <FormControl>
              <FormLabel
                id="demo-controlled-radio-buttons-group"
                style={{ marginBottom: "10px", marginTop: "10px" }}
              >
                Subtype
              </FormLabel>
              <RadioGroup
                style={{ marginLeft: "10px" }}
                row
                name="row-radio-buttons-group"
                aria-labelledby="demo-controlled-radio-buttons-group"
                value={subExpenseType}
                onChange={handleSubExpenseTypeChange}
              >
                <FormControlLabel
                  value="Entertainment"
                  control={<Radio />}
                  label="Entertainment"
                />
                <FormControlLabel
                  value="Education"
                  control={<Radio />}
                  label="Education"
                />
                <FormControlLabel
                  value="Food"
                  control={<Radio />}
                  label="Food"
                />
                <FormControlLabel
                  value="Insurance"
                  control={<Radio />}
                  label="Insurance"
                />
                <FormControlLabel
                  value="Investment"
                  control={<Radio />}
                  label="Investment"
                />
                <FormControlLabel
                  value="Health"
                  control={<Radio />}
                  label="Health"
                />
                <FormControlLabel
                  value="Taxes"
                  control={<Radio />}
                  label="Taxes"
                />
                <FormControlLabel
                  value="Others"
                  control={<Radio />}
                  label="Others"
                />
              </RadioGroup>

              <TextField
                margin="dense"
                id="Brief"
                label="Brief"
                fullWidth
                variant="standard"
                onChange={(event) => {
                  const { value } = event.target;
                  setSubExpenseTypeFixed(event.target.value);
                }}
                required
                onKeyDown={handleKeyDown}
              />
            </FormControl>
          )}

          {subExpenseType === "Fixed" && (
            <FormControl>
              <FormLabel
                id="demo-controlled-radio-buttons-group"
                style={{ marginBottom: "10px", marginTop: "10px" }}
              >
                Sub-subtype
              </FormLabel>
              <RadioGroup
                style={{ marginLeft: "10px" }}
                row
                name="row-radio-buttons-group"
                aria-labelledby="demo-controlled-radio-buttons-group"
                value={subExpenseTypeFixed}
                onChange={handleSubExpenseTypeFixedChange}
              >
                <FormControlLabel
                  value="Rent"
                  control={<Radio />}
                  label="Rent"
                />
                <FormControlLabel
                  value="Electricity"
                  control={<Radio />}
                  label="Electricity"
                />
                <FormControlLabel
                  value="SalaryTarun"
                  control={<Radio />}
                  label="Salary (Tarun)"
                />
                <FormControlLabel
                  value="SalaryMamata"
                  control={<Radio />}
                  label="Salary (Mamata)"
                />
              </RadioGroup>
            </FormControl>
          )}

          {subExpenseType === "Lab" && (
            <FormControl>
              <FormLabel
                id="demo-controlled-radio-buttons-group"
                style={{ marginBottom: "10px", marginTop: "10px" }}
              >
                Sub-subtype
              </FormLabel>
              <RadioGroup
                style={{ marginLeft: "10px" }}
                row
                name="row-radio-buttons-group"
                aria-labelledby="demo-controlled-radio-buttons-group"
                value={subExpenseTypeFixed}
                onChange={handleSubExpenseTypeFixedChange}
              >
                <FormControlLabel
                  value="Emax Raipur"
                  control={<Radio />}
                  label="Emax Raipur"
                />
                <FormControlLabel
                  value="Dental Art"
                  control={<Radio />}
                  label="Dental Art"
                />
                <FormControlLabel
                  value="Hillstone"
                  control={<Radio />}
                  label="Hillstone"
                />
              </RadioGroup>
            </FormControl>
          )}

          {subExpenseType === "New Purchase" && (
            <TextField
              margin="dense"
              id="newPurchase"
              label="New Purchase"
              fullWidth
              variant="standard"
              onChange={(event) => {
                setSubExpenseTypeFixed(event.target.value);
              }}
              required
              onKeyDown={handleKeyDown}
            />
          )}

          {subExpenseType === "Consumable" && (
            <TextField
              margin="dense"
              id="consumable"
              label="Consumable"
              fullWidth
              variant="standard"
              onChange={(event) => {
                setSubExpenseTypeFixed(event.target.value);
              }}
              required
              onKeyDown={handleKeyDown}
            />
          )}

          {subExpenseType === "Others" && (
            <TextField
              margin="dense"
              id="Others"
              label="Others"
              fullWidth
              variant="standard"
              onChange={(event) => {
                setSubExpenseTypeFixed(event.target.value);
              }}
              required
              onKeyDown={handleKeyDown}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} style={{ color: "red" }}>
            Cancel
          </Button>
          <Button
            ref={saveButtonRef}
            onClick={addexpeneBtn}
            style={{
              fontSize: "16px",
              padding: "3px",
              borderRadius: "5px",
              backgroundColor: "rgb(8,10,48,0.9)",
              color: "white",
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Homepage;
