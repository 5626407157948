import React from "react";

function Payment({ paymentData }) {
  // console.log(newArry[0].price);

  return (
    <div
      style={{
        // overflow: "scroll",
        height: "65vh",
        width: "100%",
        backgroundColor: "#EBEEF2",
      }}
    >
      {/* {!paymentData[0].price ? <div>yes</div> : <div> nod</div>} */}
      {paymentData.map((a) =>
        !a.price ? (
          <div
            style={{
              width: "96%",
              position: "relative",
              height: "120px",
              borderLeft: "4px solid rgb(42,53,55)",
              backgroundColor: "white",
            }}
          >
            no data
          </div>
        ) : (
          <div
            style={{
              // width: "98%",
              position: "relative",
              // height: "80px",
              margin: "5px",
              borderLeft: "4px solid rgb(42,53,55)",
              backgroundColor: "white",
              borderRadius: "10px",
              padding: "13px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <div
                style={{ fontSize: "20px", fontWeight: "bold", color: "green" }}
              >
                {" "}
                ₹{a.price}
              </div>
              <div style={{ fontSize: "14px" }}>{a.paymentMode}</div>
            </div>{" "}
            <div style={{display:'flex',flexDirection:'column',alignItems:'flex-end'}}>
              <div style={{ display: "flex" }}>
                {/* <div style={{ color: "grey" }}>Approve{a.date}</div>{" "} */}
                <div style={{ fontSize: "16px" }}>
                  {a.created.toDate().toDateString()}
                </div>{" "}
              </div>
              <div style={{ color: "grey", fontSize: "14px" }}>
                {a.created.toDate().toLocaleTimeString("en-US")}
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
}

export default Payment;
