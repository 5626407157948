import {createAsyncThunk, configureStore } from "@reduxjs/toolkit";


export const addDataToFirestore= createAsyncThunk (
    'books/addBookToFirestore',
    async(book)=>{
        
    }
)
const store = configureStore({
name: 'Books',
initialState :{
    booksArray:[]
},
    reducer:{}
})
export default store;