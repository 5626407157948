import React, { useRef, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Stack from "@mui/material/Stack";
import axios from "axios"

import { addDoc, collection, query, onSnapshot, doc,serverTimestamp } from "firebase/firestore";
import { db } from "../../firebaseConfig/firebase";
import { Navigate } from "react-router-dom";


// require("dotenv").config();

const token = process.env.REACT_APP_API_KEYTOKEN;
const mytoken = process.env.REACT_APP_API_KEYMYTOKEN;
const phidtoken = process.env.REACT_APP_API_KEYphidtoken;

function AddNewPt() {
  const [open, setOpen] =useState(false);
  const [date, setDate] = useState("");
 
  const [fName, setFname] = useState("");
  const [gender, setGender] = useState('female');

  const [phNumber, setPhNumber] = useState();
  const [age, setAge] = useState();

  const saveButtonRef = useRef(null);



  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      saveButtonRef.current.click();
    }
  };


  const genderHandleChange = (event) => {
    setGender(event.target.value);
    event.preventDefault()
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {

    if (reason && reason == "backdropClick") 
    return;

    setOpen(false);
  };

  const addNewPtDB = async () => {
    const abc = fName + " "+ phNumber
    const newSplit = abc.split(' ')
    const newArray= [fName.toLowerCase()]
    
    for(let i=0; i < newSplit.length; i++){
      
        for( let y=1; y<newSplit[i].length+1; y++){
            
            newArray.push(newSplit[i].substring(0,y).toLowerCase())
      
        }
    }


    const profileData = {
      
      name: fName,
      namearray:newArray,
      phNumber: phNumber,
     
      gender:gender,
      age:age,
      paid:0,
      created:serverTimestamp()
    };



  
 

 
    
    
    
    
    await addDoc(collection(db, "patientProfile"), profileData);

    axios({
      method: "POST",
      url:
        "https://graph.facebook.com/v15.0/" +
        phidtoken +
        "/messages?access_token=" +
        token,
      data: {
        messaging_product: "whatsapp",
        recipient_type: "individual",
        to: 91 + phNumber,
        type: "template",
        template: {
          name: "welcome_with_photo2",
          language: {
            code: "en_US",
          },
          components: [
            {
              "type": "header",
              "parameters": [
                  {
                      "type": "image",
                      "image": {
                          "link": "https://lh3.googleusercontent.com/p/AF1QipMxkvPvEBJeM1oG6LUO4mmqYu5OASdr-vNSPUFU=s1360-w1360-h1020"
                      }
                  }
              ]
          },
            {
              type: "body",
              parameters: [
                {
                  type: "text",
                  text: fName +" !",
                },
              ],
            },
          ],
        },
      },
  
      headers: {
        "Content-Type": "application/json",
      },
    });
    
    // axios.post("https://us-central1-patradentalclinic.cloudfunctions.net/expressApi/welcomeMsg", {
    //   name: fName,
    //   phno:phNumber,
    // },
    // {             
    //   headers: {
      //     "Content-type": "application/json; charset=UTF-8",
      //   }
      // }
    
    // ).then((res)=>{
    //   console.log(res);
    // }).catch((err)=>{
      
    //   console.log(err);
    // })



    setFname();

    setPhNumber();
    setAge();
setGender();


    handleClose();




  };

  return (
    <div>
      <Button onClick={handleClickOpen} style={{fontSize:"14px",color:'white', width:'100%'}}>Add New Patient</Button>
      <Dialog open={open} onClose={handleClose} PaperProps={{
    style: {
      
      borderRadius:'15px',
    
    },
  }}  >
        <DialogTitle>Add Patient</DialogTitle>
        <DialogContent>
          <DialogContentText>New Patient Registration</DialogContentText>
          <TextField
        
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            fullWidth
            variant="standard"
            onChange={(event) => {
              const { value } = event.target;
              setFname(value);
            }}
            required
            onKeyDown={handleKeyDown}
          />
            <TextField
            id="phNumber"
            label="WhatsApp Number"
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            fullWidth
            variant="standard"
            onChange={(event) => {
              const { value } = event.target;
              setPhNumber(value);
            }}
            required
            onKeyDown={handleKeyDown}
          />
           <FormControl>
      <FormLabel id="demo-controlled-radio-buttons-group" style={{marginBottom:'10px',marginTop:'10px'}}>Gender</FormLabel>

      <RadioGroup

style={{marginLeft:'10px'}}


        row
     
        name="row-radio-buttons-group"
    

        aria-labelledby="demo-controlled-radio-buttons-group"
     
        value={gender}
        onChange={genderHandleChange}
      >
        <FormControlLabel value="Male" control={<Radio />} label="Male" />
        <FormControlLabel value="Female" control={<Radio />} label="Female" />
      </RadioGroup>
    </FormControl>
  

    <TextField
        
        
        margin="dense"
        id="age"
        label="Age"
        inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
        fullWidth
        variant="standard"
        onChange={(event) => {
          const { value } = event.target;
          setAge(value);
        }}
        required
        onKeyDown={handleKeyDown}
      />

       
    
         
          
        </DialogContent>
        <DialogActions>
        <Button onClick={handleClose} style={{ color: "red" }}>
            Cancel
          </Button>
          <Button
            ref={saveButtonRef}
            onClick={addNewPtDB}
            style={{ fontSize: "16px",
              // fontWeight: "bold",
              // border: "1px solid ",
              padding: "3px",
              borderRadius: "5px",
              backgroundColor: "rgb(8,10,48,0.9)",
              color: "white",}}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AddNewPt;
