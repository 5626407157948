import React from "react";
import logo from "./logo.svg";
import "./App.css";
import "./components/Bottombar";
import Bottombar from "./components/Bottombar";
import Homepage from "./components/home/Homepage";
import Patientapage from "./components/patients/Patientapage";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Labpage from "./components/lab/Labpage";
import Clinicpage from "./components/clinic/Clinicpage";
import Patientprofile from "./components/patients/profile/Patientprofile";
import AddNewPt from "./components/patients/AddNewPt";
import Calender from "./components/calender/Calender";
import Treatmentpage from "./components/patients/profile/Treatmentpage";
import AddNewTretment from "./components/patients/AddNewTretment";
import Opd from "./components/opd/Opd";
import StartNewOpd from "./components/opd/StartNewOpd";
import { getAuth, signOut } from "firebase/auth";
import { auth, provider } from "./firebaseConfig/firebase";
import LoginPage from "./LoginPage";
import { AuthContextProvider } from "./context/AuthContext";
import Protected from "./context/Protected";
import Payment from "./components/patients/payment/Payment";
import ChatPage from "./components/clinic/ChatPage";
import Appointment from "./components/calender/appointment/Appointment";
import TreatmentEvent from "./components/pages/TreatmentEvent";
import Reschedule from "./components/calender/appointment/Reschedule";
import StartRx from "./components/patients/startOPD/start/StartRx";
import Cheifc from "./components/patients/startOPD/start/eventCreate/Cheifc";
import Onexam from "./components/patients/startOPD/start/eventCreate/Onexam";
import Medication from "./components/patients/startOPD/start/eventCreate/Medication";
import Investigatoin from "./components/patients/startOPD/start/eventCreate/Investigatoin";
import Preview from "./components/patients/startOPD/start/eventCreate/Preview";

function App() {
  return (
    <AuthContextProvider>
      <BrowserRouter>
        <AppContent />
      </BrowserRouter>
    </AuthContextProvider>
  );
}

function AppContent() {
  const location = useLocation();

  // Determine if the Bottombar should be shown based on the current location
  const shouldShowBottombar = () => {
    const excludedPaths = ["/startOpd"]; // Add paths where Bottombar should not be shown
    return !excludedPaths.includes(location.pathname);
  };

  return (
    <div className="App">
      {shouldShowBottombar() && <Bottombar />}
      <div className="main-content">
        <Routes>
          <Route exact path="/login" element={<LoginPage />} />
          <Route
            exact
            path="/"
            element={
              <Protected>
                <Homepage />
              </Protected>
            }
          />
          <Route
            exact
            path="/patients"
            element={
              <Protected>
                <Patientapage />
              </Protected>
            }
          />
          <Route
            exact
            path="/lab"
            element={
              <Protected>
                <Labpage />
              </Protected>
            }
          />
          <Route
            exact
            path="/profile"
            element={
              <Protected>
                <Patientprofile />
              </Protected>
            }
          />
          <Route
            exact
            path="/calender"
            element={
              <Protected>
                <Calender />
              </Protected>
            }
          />
          <Route
            exact
            path="/clinic"
            element={
              <Protected>
                <Clinicpage />
              </Protected>
            }
          />
          <Route
            exact
            path="/clinic"
            element={
              <Protected>
                <AddNewPt />
              </Protected>
            }
          />
          <Route
            exact
            path="/newtretment"
            element={
              <Protected>
                <AddNewTretment />
              </Protected>
            }
          />
          <Route
            exact
            path="/opd"
            element={
              <Protected>
                <Opd />
              </Protected>
            }
          />
          <Route
            exact
            path="/newopdstart"
            element={
              <Protected>
                <StartNewOpd />
              </Protected>
            }
          />
          <Route
            exact
            path="/appointment"
            element={
              <Protected>
                <Appointment />
              </Protected>
            }
          />
          <Route
            exact
            path="/treatment"
            element={
              <Protected>
                <Treatmentpage />
              </Protected>
            }
          />
          <Route
            exact
            path="/paymentP"
            element={
              <Protected>
                <Payment />
              </Protected>
            }
          />
          <Route
            exact
            path="/chatPage"
            element={
              <Protected>
                <ChatPage />
              </Protected>
            }
          />
          <Route
            exact
            path="/startOpd"
            element={
              <Protected>
                <StartRx />
              </Protected>
            }
          />
          <Route
            exact
            path="/chiefComplaint"
            element={
              <Protected>
                <Cheifc />
              </Protected>
            }
          />
          <Route
            exact
            path="/onExam"
            element={
              <Protected>
                <Onexam />
              </Protected>
            }
          />
          <Route
            exact
            path="/medication"
            element={
              <Protected>
                <Medication />
              </Protected>
            }
          />
          <Route
            exact
            path="/investigatoin"
            element={
              <Protected>
                <Investigatoin />
              </Protected>
            }
          />
          <Route
            exact
            path="/treatment-details"
            element={
              <Protected>
                <TreatmentEvent />
              </Protected>
            }
          />
          <Route
            exact
            path="/updateAppointment"
            element={
              <Protected>
                <Reschedule />
              </Protected>
            }
          />
          <Route
            exact
            path="/preview"
            element={
              <Protected>
                <Preview />
              </Protected>
            }
          />
        </Routes>
      </div>
    </div>
  );
}

export default App;
