// StartRx.js

import React, { useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import PostAddIcon from "@mui/icons-material/PostAdd";
import PreviewIcon from "@mui/icons-material/Preview";
import BiotechIcon from "@mui/icons-material/Biotech";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import MedicationOutlinedIcon from "@mui/icons-material/MedicationOutlined";
import Cheifc from "./eventCreate/Cheifc";
import Onexam from "./eventCreate/Onexam";
import Preview from "./eventCreate/Preview";
import Treatmentadvice from "./eventCreate/Treatmentadvice";
import Medication from "./eventCreate/Medication";
import { useLocation, useNavigate } from "react-router-dom";

import "./startRx.css";
import Investigatoin from "./eventCreate/Investigatoin";
import { Print, SearchOffOutlined, SearchOutlined } from "@mui/icons-material";
function StartRx() {
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.state ? location.state.id : null;
  const name = location.state ? location.state.name : null;
  const age = location.state ? location.state.age : null;
  const gender = location.state ? location.state.gender : null;
  const phoneNumber = location.state ? location.state.phoneNumber : null;

  const [currentPage, setCurrentPage] = useState("Cheifc");

  const [allData, setAllData] = useState([]);
  const [data, setData] = useState([]);

  const [tId, setTid] = useState([]);

  const handleComponentChange = (componentName) => {
    setCurrentPage(componentName);
  };

  const handleNextPage = (data) => {
    setAllData([...allData, data]);
    const componentOrder = [
      "Cheifc",
      "Onexam","Investigatoin",
      
      "Treatmentadvice",
      "Medication",
      "Preview"

      
    ];
    // Find the index of the current component
    const currentIndex = componentOrder.indexOf(currentPage);
    // Get the next component name
    const nextIndex =
      currentIndex === componentOrder.length - 1 ? 0 : currentIndex + 1;
    const nextComponent = componentOrder[nextIndex];
    setCurrentPage(nextComponent);
  };

  return (
    <div>
      <div className="top" style={{ display: "flex" }}>
        <div
          className="left"
          style={{
            float: "left",
            marginLeft: "15px",
            borderRight: "1px solid #f7f9fc",
            height: "90vh",
          }}
        >
          <div onClick={() => navigate(-1)} style={{}}>
            <ArrowBackIosIcon style={{ fontSize: "14px", margin: "20px" }} />
          </div>
          <div>
            <button
              style={{
                border: " 0px",
                borderRadius: "5px",
                margin: "5px",
                backgroundColor:
                  currentPage === "Cheifc" ? "lightblue" : "inherit",
              }}
              onClick={() => handleComponentChange("Cheifc")}
            >
              {" "}
              <PostAddIcon />
            </button>
          </div>
          <div>
            <button
              style={{
                border: " 0px",
                borderRadius: "5px",
                margin: "5px",
                backgroundColor:
                  currentPage === "Onexam" ? "lightblue" : "inherit",
              }}
              onClick={() => handleComponentChange("Onexam")}
            >
              {" "}
              <PreviewIcon />
            </button>
          </div>{" "}
          <div>
            <button
              style={{
                border: " 0px",
                borderRadius: "5px",
                margin: "5px",
                backgroundColor:
                  currentPage === "Investigatoin" ? "lightblue" : "inherit",
              }}
              onClick={() => handleComponentChange("Investigatoin")}
            >
              {" "}
              <SearchOutlined></SearchOutlined>{" "}
            </button>{" "}
          </div>
     
          <div>
            <button
              style={{
                border: " 0px",
                borderRadius: "5px",
                margin: "5px",
                backgroundColor:
                  currentPage === "Treatmentadvice" ? "lightblue" : "inherit",
              }}
              onClick={() => handleComponentChange("Treatmentadvice")}
            >
              {" "}
              <AutoStoriesIcon />{" "}
            </button>
          </div>
          <div>
            <button
              style={{
                border: " 0px",
                borderRadius: "5px",
                margin: "5px",
                backgroundColor:
                  currentPage === "Medication" ? "lightblue" : "inherit",
              }}
              onClick={() => handleComponentChange("Medication")}
            >
              {" "}
              <MedicationOutlinedIcon />{" "}
            </button>{" "}
          </div>
          <div>
            <button
              style={{
                border: " 0px",
                borderRadius: "5px",
                margin: "5px",
                backgroundColor:
                  currentPage === "Preview" ? "lightblue" : "inherit",
              }}
              onClick={() => handleComponentChange("Preview")}
            >
              {" "}
              <Print/>{" "}
            </button>{" "}
          </div>
    
        </div>
        <div className="right" style={{ width: "100%", margin: "5PX" }}>
          {currentPage === "Cheifc" && (
            <Cheifc onNext={handleNextPage} setData={setData} id={id} />
          )}
          {currentPage === "Onexam" && (
            <Onexam
              onNext={handleNextPage}
              data={data}
              id={id}
              setTid={setTid}
              tId={tId}
            />
          )}
          {currentPage === "Investigatoin" && (
            <Investigatoin onNext={handleNextPage} tId={tId} />
          )}
      
          {currentPage === "Treatmentadvice" && (
            <Treatmentadvice onNext={handleNextPage} tId={tId} />
          )}
          {currentPage === "Medication" && (
            <Medication onNext={handleNextPage}tId={tId} />
          )}
          {currentPage === "Preview" && (
            <Preview onNext={handleNextPage}tId={tId} name={name} age={age} gender={gender} phoneNumber={phoneNumber} />
          )}
        </div>
      </div>

      <div className="investigation-button" style={{width:'100%'}}>
        {currentPage !== "Medication" &&  "Preview" && (
          <button
            style={{
              width: "95%",
              borderRadius: "20px",
              padding: "10px ",
              fontSize: "14px",
              fontWeight: "bold",
              backgroundColor: "#3a7a91",
              margin: "10px",
              color: "white",
              border: "none",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={() => handleNextPage(null)}
          >
            Next Page
          </button>
        )}
        {currentPage === "Medication" && (
          <button
            style={{
              width: "95%",
              borderRadius: "20px",
              padding: "10px ",
              fontSize: "14px",
              fontWeight: "bold",
              backgroundColor: "#3a7a91",
              margin: "10px",
              color: "white",
              border: "none",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={() => handleNextPage(null)}
          >
            Preview Prescription
          </button>
        )}
       
      </div>


    </div>
  );
}

export default StartRx;
